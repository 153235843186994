import React, { useEffect, useRef, useContext } from "react";
import patiensService from "../../services/patients";
import { getLogedPatient } from "../../services/api"
import { Carousel } from "react-responsive-carousel";
import { useHistory } from 'react-router-dom';
import imgLogo from "../../assets/images/redblu.png";
import imgIconWifi from "../../assets/images/introduccion/iconWifi.svg";
import imgIconBateria from "../../assets/images/introduccion/iconBateria.svg";
import imgIconAudio from "../../assets/images/introduccion/iconAudio.svg";
import imgIconAudifono from "../../assets/images/introduccion/iconAudifono.svg";
import imgIconDispositivos from "../../assets/images/introduccion/iconDispositivos.svg";
import imgIconAlerta from "../../assets/images/introduccion/iconAlerta.svg";
import imgIconNoGrabacion from "../../assets/images/introduccion/iconNoGrabacion.svg";
import imgIconPrivacidad from "../../assets/images/introduccion/iconPrivacidad.svg";
import imgIconSoporte from "../../assets/images/introduccion/iconSoporte.svg";
import imgIconAyuda from "../../assets/images/introduccion/iconAyuda.svg";
import videoLoading from "./../../teleconsulta/assets/video/loading.mp4";
import ReactGA from 'react-ga';
import { useRouteMatch } from "react-router-dom";

import { NavigateNext } from "@material-ui/icons";
import { UserContext } from "../../context/UserContext";
// import { getSocketClient, isSocketEnable } from '../../services/socket';

var timeOutRequestMedicStatus = 0;

const WaitingRoom = ({ handleSalirEspera }) => {
   // por defecto cuando este se llama el componente, efectivamente está montado
   const isMounted = useRef(true);
   const history = useHistory();
   const urlRedBLU = process.env.REACT_APP_URL_REDBLU || '';
   const { user } = useContext(UserContext)


   const handleCancelarEspera = (e) => {
      e.preventDefault();
      patiensService.setLogActivityUser(user.rut, 'Salió de la sala de espera').then(response => { });
      handleSalirEspera();
      ReactGA.event({ category: "Sala Espera", action: "Cancelar", label: e.target.name });
   };

   const goToRecursos = () => {
      history.push('/paciente/recursos');
   }

   useEffect(() => {
      // cuando se desmonte
      return () => {
         isMounted.current = false;
      }
   }, []);


   useEffect(() => {
      if (isMounted.current) {
         patiensService
            .setLogActivityUser(user.rut, 'Está en sala de espera')
            .then(response => { });
      }
      // console.log(urlRedBLU);
      return () => {
         isMounted.current = false;
      }
      
      // const medicResponseStatus = ({ rutMedico, estadoMedico, channel }) => {
      //    console.log('medic-response-status :>> ', { rutMedico, estadoMedico, channel });
      // }

      // const socket = getSocketClient();
      // if (isSocketEnable && socket) {

      //    // TODO: obtener el rut del medico desde la cita que corresponde segun la hora que es
      //    const rutMedico = '12345678-5';
      //    socket.emit('join-medic-status', { rutMedico: rutMedico }, () => { });
      //    socket.on("medic-response-status", medicResponseStatus);

      //    const requestMedicStatus = () => {
      //       socket.emit('patient-request-medic-status', { rutMedico: rutMedico }, (error) => {
      //          console.log('error :>> ', error);
      //       });
      //       timeOutRequestMedicStatus = setTimeout(requestMedicStatus, 2000);
      //    }

      //    requestMedicStatus()

      // }

      // return () => {
      //    clearTimeout(timeOutRequestMedicStatus)
      //    if (isSocketEnable && socket) {
      //       const socket = getSocketClient();
      //       socket.off("medic-response-status", medicResponseStatus);
      //    }
      // };

   }, [urlRedBLU]);

   const match = useRouteMatch();

   ReactGA.pageview(match.path);
   ReactGA.event({ category: "Sala Espera", action: "Espera" });
   
   return (
      <div className="hoy-component fullwidth middday-bg animated fadeIn slow">
         <div className="header-redgesam col-12">
            <div className="row pb-2">
               <div className="col-12 logo-corporativo text-center">
                  <img
                     src={imgLogo}
                     width="60%"
                     className="img-fluid"
                     alt="Redblu Logo"
                  />
               </div>
            </div>
         </div>
         <div className="home-waiting text-center">
            <h1>...esperando al profesional</h1>
            <div className="">
               <video
                  className="pt-2"
                  width="80"
                  height="80"
                  autoPlay
                  loop
                  muted
                  playsInline
               >
                  <source src={videoLoading} type="video/mp4" />
                  <source src={videoLoading} type="video/ogg" />
               </video>
            </div>


            <div className="row align-items-center">
               <div className="col-sm-12 col-12 pb-3 pb-sm-0 mb-3">
                  <h1><em>Mientras esperas visita nuestros recursos de salud!!!</em> cuando el profesional esté listo <em>te conectaremos automáticamente</em> con él</h1>
               </div>
               <div className="col-sm-12 col-12 pl-sm-0 pl-3 mb-4">
                  <button
                     className="btn-submit btn-rounded"
                     onClick={goToRecursos}>
                     Explorar recursos mientras espero mi atención
                     <NavigateNext />
                  </button>
               </div>
            </div>

            <span className="info-warning p-2">
               Ten paciencia el profesional puede demorarse unos minutos debido
               a la gran demanda de teleconsultas.{" "}
               <strong>Es necesario que tengas esta página abierta para recibir el aviso.</strong>
            </span>
            <div className="p-3">
               <button className="btn-submit border-radius" onClick={handleCancelarEspera}>
                  Salir de la sala de espera
               </button>
            </div>
         </div>
         <div className="footerPush"></div>
      </div>
   );
};

export default WaitingRoom;
