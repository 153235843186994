import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Wizzard from './../../../Pages/TroubleShooting/VerificacionDispositivos/Wizzard';

const TestDevice = () => {

  return(
    <section>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-10 col-lg-8 col-sm-12 col-12 mt-4">
            <Wizzard 
              onFinishContent={
                <div className="text-center">
                  <Link to="/profesional" className="btn btn-submit px-4 pt-2">
                    Volver a la agenda
                    <NavigateNext />
                  </Link>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestDevice;