import React, { useEffect, useState } from 'react';
// FORMIK
import { Formik, Form } from 'formik';
// COMPONENTS
import { InputFileComponent, TextAreaComponent, TitleComponent } from '../components';
// MATERIAL UI
import { Button, FormControl, TextField, Select, Divider, Chip, Box, Typography, LinearProgress, MenuItem } from '@material-ui/core';
// SERVICES
import ProfesionalService from '../../../services/ProfesionalService';
// OTHERS
import Modal from 'react-modal';
import customModalStyles from '../hooks/customModalStyles';
import { IdAttributes } from '../hooks/IdAttributes';


const InfoProfesional = ({
  user,
  dataProfesional,
  profesionalImagen,
  descripcion,
  minutosAtencion,
  especialidadesDestacadas,
  aniosExperiencia,
  tituloProfesional,
  edadesAtencion,
  numeroSuperintendencia,
  otrasEspecialidades,
  casaEstudio,
  tecnicaTerapia,
  selectAniosExperiencia,
  selectTituloProfesional,
  selectCasaEstudio,
  selectEspecialidadesDestacadas,
  selectEdadesAtencion,
  selectOtrasEspecialidades,
  selectTecnicaTerapia,
  errors,
  isLoading,
  setDataProfesionaTemp,
  dataProfesionalTemp,
}) => {

  const [guardando, setGuardando] = useState(false);

  // ========
  // Options
  // ========
  const [optLocalEspDest, setOptLocalEspDest] = useState(especialidadesDestacadas.options);
  const [optLocalOtrasEsp, setOptLocalOtrasEsp] = useState(otrasEspecialidades.options);
  const [optLocalEdadesAtencion, setOptLocalEdadesAtencion] = useState(edadesAtencion.options);
  const [optlocalTecnicasTerapia, setOptlocalTecnicasTerapia] = useState(tecnicaTerapia.options);
  const [cargandoSelect, setCargandoSelect] = useState(true);

  // ========
  // Modals
  // ========

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");

  // ========
  // Others
  // ========
  const [categoriaTituloState, setCategoriaTituloState] = useState(false);


  useEffect(() => {
    return () => { }
  }, [optLocalEdadesAtencion,
    optLocalEspDest,
    optLocalOtrasEsp,
    optlocalTecnicasTerapia,
    categoriaTituloState])


  //  Especialidades Destacadas
  const handleDelEspDest = (name: string) => {
    if (optLocalEspDest.includes(name)) {
      let res = optLocalEspDest.filter(n => n !== name)
      setOptLocalEspDest(res);
    }
  };

  //  Otras Especialidades
  const handleDelOtrasEsp = (name: string) => {
    if (optLocalOtrasEsp.includes(name)) {
      let res = optLocalOtrasEsp.filter(n => n !== name)
      setOptLocalOtrasEsp(res);
    }
  };

  //  Edades de atención
  const handleDelEdadesAtencion = (name: string) => {
    if (optLocalEdadesAtencion.includes(name)) {
      let res = optLocalEdadesAtencion.filter(n => n !== name)
      setOptLocalEdadesAtencion(res);
    }
  };
  //  Tecnicas usadas para terapia
  const handleDelTecnicasTerapia = (name: string) => {
    if (optlocalTecnicasTerapia.includes(name)) {
      let res = optlocalTecnicasTerapia.filter(n => n !== name)
      setOptlocalTecnicasTerapia(res);
    }
  };


  const cargandoInfo = () => {
    return <>
      <Box position="relative" display="flex">
        <Typography variant="caption" component="div" color="primary">
          Cargando información...
          <LinearProgress />
          {/* <Skeleton variant="rect" width={210} height={30} /> */}
        </Typography>
      </Box>
    </>
  }

  useEffect(() => {
    const cargaSelectores =
      selectAniosExperiencia[0].loading ||
      selectTituloProfesional[0].loading ||
      selectCasaEstudio[0].loading ||
      selectEspecialidadesDestacadas[0].loading ||
      selectOtrasEspecialidades[0].loading ||
      selectEdadesAtencion[0].loading ||
      selectTecnicaTerapia[0].loading

    setCargandoSelect(cargaSelectores);

  }, [selectAniosExperiencia[0].loading,
  selectTituloProfesional[0].loading,
  selectCasaEstudio[0].loading,
  selectEspecialidadesDestacadas[0].loading,
  selectOtrasEspecialidades[0].loading,
  selectEdadesAtencion[0].loading,
  selectTecnicaTerapia[0].loading
  ])

  // Id atributos
  const { edadesAtencionId,
    casaEstudioId,
    especialidadDestacadaId,
    tecnicaUsadaTerapiaId,
    numeroSuperIntendenciaId,
    tituloProfesionalId,
    OtrasEspecialidadesId,
    AniosExpericiaId } = IdAttributes();

  return (

    <div className='info  mt-4'>

      <TitleComponent
        title='Información Profesional'
        subtitle='Actualiza en esta sección la información que es desplegada en tu perfil de redblu y que además entrega información a los usuarios para comprender tu perfil y especialidad'

      />

      <Formik
        // Valores Iniciales
        initialValues={
          {
            idProducto: dataProfesional.id,
            profesionalImagen: profesionalImagen,
            descripcionValue: descripcion,
            precioValue: dataProfesional.price,
            minutosAtencionValue: minutosAtencion,

            aniosExperienciaValue: aniosExperiencia,
            tituloProfesionalValue: tituloProfesional,
            casaEstudioValue: casaEstudio,

            especialidadesDestacadasValue: especialidadesDestacadas,
            otrasEspecialidadesValue: otrasEspecialidades,
            edadesAtencionValue: edadesAtencion,
            tecnicaTerapiaValue: tecnicaTerapia,

            numeroSuperintendenciaValue: numeroSuperintendencia,

            user: user,
            categories: dataProfesional.categories.map(c => c),
          }}
        onSubmit={(values: any, { setSubmitting, resetForm }) => {

        }}
      >
        {
          ({
            values,
            errors,
            touched,
            handleChange,
            handleReset,
            handleSubmit,
            setFieldValue,
            handleBlur,
            setFieldError,
            setFieldTouched,
            isSubmitting,
            validateForm,
          }) => (

            <Form
              onChange={(event: (React.FormEvent<HTMLFormElement>)) => {
                // console.log('values.otrasEspecialidadesValue : ', values.otrasEspecialidadesValue);
              }}
            >

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
                  <div className="form-group mt-3">

                    {/* Imagen Profesional */}
                    <InputFileComponent
                      title='Imagen de perfil'
                      name="profesionalImagen"
                      altImg={values.profesionalImagen.alt}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                      idProducto={values.idProducto}
                      // errors={errors}
                      touched={touched ? touched.imgProfesional : false}
                      value={values.profesionalImagen.src}
                      setDataProfesionaTemp={setDataProfesionaTemp}
                      dataProfesionalTemp={dataProfesionalTemp}
                    />

                    {/* <ErrorMessage name="dataProfesional" component="div" /> */}
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12">
                  <div className="form-group mt-3">
                    {/* Descripción */}
                    <TextAreaComponent
                      title='Descripción'
                      name="descripcionValue"
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched ? touched.descripcionValue : false}
                      value={values.descripcionValue}
                    />
                  </div>

                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row mb-3">

                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12">
                      {/* TODO: llevar a componente */}
                      <span className='text-muted font-weight-bold'>Precio</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3 p-0"}>
                        <TextField
                          name="precioValue"
                          id="precioValue"
                          label="$"
                          variant="outlined"
                          value={values.precioValue}
                          className={'p-1'}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </FormControl>
                    </div>

                    <div className="col-xl-3 col-lg-4 col-md-5 col-sm-12 col-12">
                      {/* TODO: llevar a componente */}
                      <span className='text-muted font-weight-bold'>Minutos de Atención</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>
                        <TextField
                          name="minutosAtencionValue"
                          id="minutosAtencionValue"
                          label=""
                          variant="outlined"
                          value={values.minutosAtencionValue}
                          className={'p-1'}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                      </FormControl>
                    </div>
                  </div>
                </div>

                <Divider light />

                {/* Selectores */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                  <div className="row mt-3">

                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
                      {/* TODO: llevar a componente */}
                      <span className='text-muted font-weight-bold'>Años de experiencia</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>

                        {selectAniosExperiencia[0].loading ?
                          (
                            cargandoInfo()
                          )
                          :
                          <Select
                            native
                            name="aniosExperienciaValue"
                            // value={values.aniosExperienciaValue}
                            value={aniosExperiencia ? values.aniosExperienciaValue.options : []}
                            multiple={false}
                            disabled={Array.isArray(aniosExperiencia) && aniosExperiencia.length === 0}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          >

                            {
                              Array.isArray(aniosExperiencia) && aniosExperiencia.length === 0
                                ? <option key={-1} value={'Atributo no disponible'}>Atributo no disponible</option>
                                : selectAniosExperiencia.map(({ term_id, name }) => {
                                  return (
                                    <option key={term_id} value={name}>{name}</option>
                                  )
                                })
                            }
                          </Select>
                        }
                      </FormControl>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
                      {/* TODO: llevar a componente */}
                      <span className='text-muted font-weight-bold'>Titulo Profesional</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>

                        {selectTituloProfesional[0].loading ?
                          (
                            cargandoInfo()
                          )
                          :
                          <Select
                            native
                            name="tituloProfesionalValue"
                            value={tituloProfesional ? values.tituloProfesionalValue.options : []}
                            disabled={Array.isArray(tituloProfesional) && tituloProfesional.length === 0}
                            onClick={(event) => {
                              !categoriaTituloState && setCategoriaTituloState(true)
                            }}
                            onChange={(event) => {
                              categoriaTituloState && handleChange(event);
                            }}
                          >
                            {
                              Array.isArray(tituloProfesional) && tituloProfesional.length === 0
                                ? <option key={-1} value={'Atributo no disponible'}>Atributo no disponible</option>
                                :
                                <>
                                  {categoriaTituloState &&
                                    <option value="" key={'none'} hidden>
                                      Seleccione opción
                                    </option>
                                  }
                                  {
                                    selectTituloProfesional.map(({ term_id, name }) => {

                                      return (
                                        <>
                                          {
                                            <option key={term_id}
                                              value={[term_id, name]}
                                            >
                                              {name}
                                            </option>
                                          }

                                        </>
                                      )
                                    })}
                                </>
                            }
                          </Select>
                        }
                      </FormControl>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
                      {/* TODO:llevar a componente */}
                      <span className='text-muted font-weight-bold'>Casa de estudio</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>

                        {selectCasaEstudio[0].loading ?
                          (
                            cargandoInfo()
                          )
                          :
                          <Select
                            native
                            name="casaEstudioValue"
                            value={casaEstudio ? values.casaEstudioValue.options : []}
                            disabled={Array.isArray(casaEstudio) && casaEstudio.length === 0}
                            multiple={false}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          >
                            {
                              Array.isArray(casaEstudio) && casaEstudio.length === 0
                                ? <option key={-1} value={'Atributo no disponible'}>Atributo no disponible</option>
                                : selectCasaEstudio.map(({ term_id, name }) => {
                                  return (
                                    <option key={term_id} value={name}>{name}</option>
                                  )
                                })
                            }

                          </Select>
                        }
                      </FormControl>
                    </div>

                  </div>
                </div>

                {/* Selectores con opciones (Chip) */}
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* Especialidades */}
                  <div className="row mt-3">
                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                      {/* TODO:llevar a componente */}
                      <span className='text-muted font-weight-bold'>Especialidad Destacada</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>
                        {selectEspecialidadesDestacadas[0].loading ?
                          (
                            cargandoInfo()
                          )
                          :
                          <Select
                            native
                            name="especialidadesDestacadasValue"
                            value={values.especialidadesDestacadasValue}
                            onChange={(event) => {
                              let seleccionada = event.target.value;

                              if (optLocalEspDest.includes(seleccionada)) {
                                return;
                              } else {
                                optLocalEspDest.unshift(seleccionada);
                              }

                              handleChange(event);
                            }}
                          >
                            <option key={-1} value="Seleccionar" disabled>Seleccionar especialidades</option>
                            {
                              (
                                selectEspecialidadesDestacadas.map(({ term_id, name }, index) => {

                                  return (
                                    <option key={index} value={name}>{name}</option>
                                  )
                                })
                              )
                            }
                          </Select>
                        }
                      </FormControl>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12 col-12">
                      <div className='mt-4'>
                        {optLocalEspDest.map((especialidad) => {
                          return (
                            <Chip
                              key={especialidad}
                              size="small"

                              // icon={<FaceIcon />}
                              label={especialidad}
                              onClick={(event) => {
                                handleDelEspDest(especialidad)
                                handleChange(event)
                              }
                              }
                              onDelete={(event) => {
                                handleDelEspDest(especialidad)
                                handleChange(event)
                              }}
                              color="default"
                              className='mr-2 mt-2 pt-3 pb-3 pl-1 pr-1 bg-editar-perfil'
                            />
                          )
                        }
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                      <span className='text-muted font-weight-bold'>Otras Especialidades</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>
                        {selectOtrasEspecialidades[0].loading ?
                          (
                            cargandoInfo()
                          )
                          :
                          <Select
                            native
                            name="otrasEspecialidadesValue"
                            value={values.otrasEspecialidadesValue}
                            onChange={(event) => {
                              let seleccionada = event.target.value;
                              if (optLocalOtrasEsp.includes(seleccionada)) {
                                return;
                              } else {
                                optLocalOtrasEsp.unshift(seleccionada);
                              }

                              handleChange(event);
                            }}
                          >
                            <option key={-1} value="Seleccionar" disabled >Seleccionar especialidades</option>
                            {
                              (
                                selectOtrasEspecialidades.map(({ term_id, name }, index) => {
                                  return (
                                    <option key={index} value={name}>{name}</option>
                                  )
                                })
                              )
                            }
                          </Select>
                        }
                      </FormControl>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-7 col-sm-12 col-12">
                      <div className='mt-4'>
                        {optLocalOtrasEsp && optLocalOtrasEsp.map((especialidad) => {
                          return (
                            <Chip
                              key={especialidad}
                              size="small"
                              // icon={<FaceIcon />}
                              label={especialidad}
                              onClick={(event) => handleDelOtrasEsp(especialidad)}
                              onDelete={(event) => handleDelOtrasEsp(especialidad)}
                              color="default"
                              className='mr-2 mt-2 pt-3 pb-3 pl-1 pr-1 bg-editar-perfil'
                            />
                          )
                        }
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                      <span className='text-muted font-weight-bold'>Edades de Atención</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>
                        {selectEdadesAtencion[0].loading ?
                          (
                            cargandoInfo()
                          )
                          :
                          <Select
                            native
                            name="edadesAtencionValue"
                            value={values.edadesAtencionValue}
                            onChange={(event) => {
                              let seleccionada = event.target.value;
                              if (optLocalEdadesAtencion.includes(seleccionada)) {
                                return;
                              } else {
                                optLocalEdadesAtencion.unshift(seleccionada);
                              }

                              handleChange(event);
                            }}
                          >
                            <option key={-1} value="Seleccionar" disabled>Seleccionar opciones</option>
                            {selectEdadesAtencion.map(({ term_id, name }, index) => {
                              return (
                                <option key={index} value={name}>{name}</option>
                              )
                            }
                            )}
                          </Select>
                        }
                      </FormControl>

                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                      <div className='mt-4'>
                        {optLocalEdadesAtencion && optLocalEdadesAtencion.map((edad) => {
                          return (
                            <Chip
                              key={edad}
                              size="small"
                              // icon={<FaceIcon />}
                              label={edad}
                              onClick={(event) => handleDelEdadesAtencion(edad)}
                              onDelete={(event) => handleDelEdadesAtencion(edad)}
                              color="default"
                              className='mr-2 mt-2 pt-3 pb-3 pl-1 pr-1 bg-editar-perfil'
                            />
                          )
                        }
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="row mt-3">
                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                      <span className='text-muted font-weight-bold'>Tecnicas usadas para la Terapia</span>
                      <FormControl variant="outlined" className={"form-control mt-2 mb-3"}>
                        {selectTecnicaTerapia[0].loading ?
                          (
                            cargandoInfo()
                          )
                          :
                          <Select
                            native
                            name="tecnicaTerapiaValue"
                            value={values.tecnicaTerapiaValue}
                            onChange={(event) => {
                              let seleccionada = event.target.value;
                              if (optlocalTecnicasTerapia.includes(seleccionada)) {
                                return;
                              } else {
                                optlocalTecnicasTerapia.unshift(seleccionada);
                              }

                              handleChange(event);
                            }}
                          >
                            <option key={-1} value="Seleccionar" disabled>Seleccionar opciones</option>
                            {selectTecnicaTerapia.map(({ term_id, name }) => {
                              return (
                                <option key={term_id} value={name}>{name}</option>
                              )
                            }
                            )}
                          </Select>
                        }
                      </FormControl>


                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                      <div className='mt-4'>



                        {optlocalTecnicasTerapia && optlocalTecnicasTerapia.map((tecnica) => {
                          return (
                            <Chip
                              key={tecnica}
                              size="small"
                              id="tecnicaTerapiaValue"
                              // icon={<FaceIcon />}
                              label={tecnica}
                              onClick={(event) => {
                                handleDelTecnicasTerapia(tecnica)
                                handleChange(event)
                              }
                              }
                              onDelete={(event) => {
                                handleDelTecnicasTerapia(tecnica)
                                handleChange(event)
                              }}
                              color="default"
                              className='mr-2 mt-2 pt-3 pb-3 pl-1 pr-1 bg-editar-perfil'
                            />
                          )
                        }
                        )}

                      </div>
                    </div>

                  </div>

                  {/* Validación en caso que tenga el atributo o no */}
                  {numeroSuperintendencia &&
                    <div className="row mt-3">
                      <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                        <span className='text-muted font-weight-bold'>N° registro Superintendencia de salud</span>
                        <TextField
                          id="numeroSuperintendenciaValue"
                          name="numeroSuperintendenciaValue"
                          label=""
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={handleChange}
                          variant="outlined"
                          className={"form-control mt-3 mb-3"}
                          value={values.numeroSuperintendenciaValue.options}
                        />
                      </div>
                    </div>
                  }

                </div>

              </div>
              <Divider light className="mt-3" />

              {/* Guardar cambios */}
              <Button
                variant="contained"
                className="mt-4"
                color="primary"
                size="large"
                type="submit"
                disabled={guardando || cargandoSelect}
                onClick={() => {

                  // Categoria profesional
                  const categoriaTitulo = typeof (values.tituloProfesionalValue) !== 'object' && values.tituloProfesionalValue.split(",", 2)
                  //Formato Request
                  // FORMATEADO
                  const respRquest = {
                    // RUT profesional
                    professionalRut: user.rut,

                    // Imagen
                    professionalAttributes: {

                      // Descripción corta
                      description: values.descripcionValue.toString(),

                      // Precio
                      regular_price: values.precioValue.toString(),

                      // Atributos

                      attributes: [
                        {
                          // Id Año de experiencia
                          "id": AniosExpericiaId,
                          options: typeof (values.aniosExperienciaValue) !== 'object'
                            ? [values.aniosExperienciaValue]
                            : values.aniosExperienciaValue.options ? values.aniosExperienciaValue.options : [''],
                        },
                        {
                          // Id Titulo profesional
                          "id": tituloProfesionalId,
                          options: typeof (values.tituloProfesionalValue) !== 'object'
                            ? [values.tituloProfesionalValue]
                            : values.tituloProfesionalValue.options ? values.tituloProfesionalValue.options : [''],
                        },
                        {
                          // Id casa de estudio
                          "id": casaEstudioId,
                          options: typeof (values.casaEstudioValue) !== 'object'
                            ? [values.casaEstudioValue]
                            : values.casaEstudioValue.options ? values.casaEstudioValue.options : [''],
                        },


                        {
                          // Id especialidades destacadas
                          "id": especialidadDestacadaId,
                          options: optLocalEspDest
                        },

                        {
                          // Id otras especialidades 
                          "id": OtrasEspecialidadesId,
                          options: optLocalOtrasEsp
                        },

                        {
                          // Id edades de atención
                          "id": edadesAtencionId,
                          options: optLocalEdadesAtencion
                        },
                        {
                          // Id tecnicas de terapia
                          "id": tecnicaUsadaTerapiaId,
                          options: optlocalTecnicasTerapia

                        },

                        {
                          id: numeroSuperIntendenciaId,
                          options:
                            // Options Numero super intendencia
                            typeof (values.numeroSuperintendenciaValue) !== 'object'
                              ? [values.numeroSuperintendenciaValue.toString()]
                              : values.numeroSuperintendenciaValue.options

                        }
                      ]
                    },
                    // Edición minutos de atención
                    updateAttentionMinute: {
                      attentionMinutes: values.minutosAtencionValue.toString()
                    },

                    // No se modifica

                    updateProfessionalTitleCategory: {
                      categoryId: typeof (values.tituloProfesionalValue) !== 'object' ? parseInt(categoriaTitulo[0]) : values.categories[0].id,
                      categoryTitle: typeof (values.tituloProfesionalValue) !== 'object' ? categoriaTitulo[1] : values.categories[0].name
                    }


                  }

                  console.log('respRquest ::> ', respRquest);

                  const updateDataProfesional = async () => {
                    try {
                      setGuardando(true);
                      const res = await ProfesionalService.actualizaProfesionalCompleto(values.idProducto, respRquest)
                        .then(response => {
                          setAlertModalText("Datos actualizados.");
                          setAlertModalIsOpen(true);
                          // Recarga data profesional
                          setDataProfesionaTemp({
                            nombre: dataProfesionalTemp.nombre,
                            titulo: typeof (values.tituloProfesionalValue) !== 'object' ? categoriaTitulo[1] : values.categories[0].name,
                            imagen: dataProfesionalTemp.imagen,
                          })
                          setGuardando(false);
                        }).catch(error => {
                          console.log(error);
                          setAlertModalIsOpen(false);
                          setGuardando(false);
                        })
                    } catch (error: any) {
                      console.log(error);
                      setAlertModalIsOpen(false);
                      setGuardando(false);
                    }

                  }
                   updateDataProfesional();
                }
                }

              >
                Guardar Cambios
              </Button>
            </Form>
          )
        }
      </Formik>
      < Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
        <div className="alertModal">
          <h1>{alertModalText}</h1>
          <div className="text-center">
            <button type="button" className="btn btn-submit btn-lg" onClick={() => { setAlertModalIsOpen(false) }}>Aceptar</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default InfoProfesional;