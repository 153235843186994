import React, { useState } from 'react';
import { 
  Search 
} from '@material-ui/icons';
import { 
  InputAdornment,
  TextField } from '@material-ui/core';
import './chatSearch.css';

const ChatSearch = ({ filteringChat }) => {

  const [inputValue, setInputValue] = useState('');

  const handlerChange = (ev) => {
    ev.preventDefault();
    filteringChat(ev.target.value);
    setInputValue(ev.target.value);
  }

  return(
    <>
      <div className="chat-search">
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Buscar..."
          variant="filled"
          fullWidth
          value={inputValue} 
          onChange={handlerChange}  
          style={{backgroundColor:'inherit'}}
        />
      </div>
    </>
  )
}

export default ChatSearch;