import React, { useState } from 'react';
import Star from './../../assets/images/iconos/icon-star.svg';

const StarRating = (props) => {
  const [hover, setHover] = useState(null);
  const rating = props.value;

  return (
    <div className='row'>
      <div className="col-12 text-center star-rating">
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1;
          return (
            <span key={i}>
              <img
                className="star"
                src={Star}
                alt="Estrella"
                onClick={() => (
                  props.onSetRating(ratingValue))
                }
                style={ratingValue <= (hover || rating) ? yellowStarStyles : blackStarStyles}
                onMouseEnter={() => setHover(ratingValue)}
                onMouseLeave={() => setHover(null)} />
            </span>
          );
        })}
      </div>
    </div>
  );
}

const yellowStarStyles = {
  filter: `invert(73%) sepia(40%) saturate(918%) hue-rotate(13deg) brightness(112%) contrast(93%)`,
  width: `40px`,
  height: `40px`,
};

const blackStarStyles = {
  filter: `invert(0%) sepia(100%) saturate(7500%) hue-rotate(59deg) brightness(117%) contrast(116%)`,
  width: `40px`,
  height: `40px`,
}

export default StarRating;