import React, { useEffect, useState, useCallback } from 'react';
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useRouteMatch,
   useHistory,
   Link,
} from 'react-router-dom';
import {
   Typography
} from '@material-ui/core';
import {
   QueueMusic
} from '@material-ui/icons';
import Slider from 'react-slick';
import ResourceServices from '../../services/resources';
import Loading from '../../components/Loading';

// Recursos Captative
import Iframe from 'react-iframe';
import Modal from "react-modal";
import { customModalStyles } from "../../Profesional/utils/Modal";
import { optionsTypeLink } from "../../Profesional/helpers/OptionSelect";
const settingsSlider = {
   dots: false,
   infinite: false,
   focusOnSelect: false,
   speed: 500,
   slidesToShow: 3,
   slidesToScroll: 3,
   adaptiveHeight: false,
   className: "center",
   arrows: true,
   draggable: true,
   responsive: [
      {
         breakpoint: 1024,
         settings: {
            slidesToShow: 3,
            slidesToScroll: 3
         }
      },
      {
         breakpoint: 600,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
         }
      },
      {
         breakpoint: 480,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 1
         }
      }
   ]
};
function ItemNavRecurso({ label, to }) {
   let match = useRouteMatch({
      path: to,
      exact: false,
   });
   return (
      <Link to={to} className="no-pointer">
         <button disabled className={match ? "p-0 no-pointer" : ""}>{label}</button>
      </Link>
   );
}

const Explorar = (props) => {

   const match = useRouteMatch();
   const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
   const [numRecurso, setNumRecurso] = useState("");
   const [startDate, setStartDate] = useState('');

   const history = useHistory();
   const [recursoDestacado, setRecursoDestacado] = useState({
      _id: '',
      bgImage: '',
      name: '',
      shortDescription: ''
   });
   const [recursos, setRecursos] = useState([]);
   const [dragging, setDragging] = useState(false);
   const [isLoading, setIsLoading] = useState(true);

   const handleBeforeChange = useCallback(() => {
      setDragging(true)
   }, [setDragging]);

   const handleAfterChange = useCallback(() => {
      setDragging(false)
   }, [setDragging]);

   const handleOnItemClick = useCallback(
      e => {
         if (!dragging) {
            history.push('/paciente/recursos/' + e);
         }
      },
      [dragging]
   );


   const goToRecurso = (id) => {
      history.push('/paciente/recursos/' + id);
   };

   const fechData = async () => {
      const reponseDestacado = await ResourceServices.getRandomResource();
      const responseRecursosCategoria = await ResourceServices.getResourceByCategory();

      if (reponseDestacado) {
         setRecursoDestacado(reponseDestacado.data);
      }

      if (responseRecursosCategoria) {
         setRecursos(responseRecursosCategoria.data);
      }

      setIsLoading(false);
   }

   useEffect(() => {
      fechData();
   }, []);

   useEffect(() => {
      fechData();
   }, []);
   if (isLoading) {
      return <div className="fullh movile-app"><Loading /></div>
   } else {
      return (
         <div className="fullh recursos_v2">
            <div className="home-top-nav container-fluid animated fadeInDown fast pl-0 pr-0">
               <div className="header-nav-spacing pb-0">
                  <div className="row px-2 px-sm-0">
                     <div className="page-name col-sm-6 col-12">
                        <Typography variant="h1">
                           <QueueMusic /> Recursos
                        </Typography>
                     </div>
                     <div className="col-sm-6 col-12 text-left text-sm-center pt-2 pt-sm-0 pl-5 pl-sm-0">
                        <p className="mb-0">Actividades y material para tu bienestar recomendados por profesionales de la salud</p>
                     </div>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-12 animated fadeInUp">
                  <div
                     className="recurso-destacado"
                     style={{
                        backgroundImage: 'url(' + recursoDestacado.bgImage + ')'
                     }}>
                     <div className="caption-destacado">
                        <div className="row align-items-center">
                           <div className="col-12 col-sm-8">
                              <h2 className="mb-3 mb-sm-2">{recursoDestacado.name}</h2>
                              <p className="mb-4 mb-sm-0">{recursoDestacado.shortDescription}</p>
                           </div>
                           <div className="col-12 col-sm-4">
                              <button
                                 className="btn-submit full-width"
                                 onClick={() => goToRecurso(recursoDestacado._id)}>
                                 Comenzar
                                 <QueueMusic />
                              </button>
                           </div>
                        </div>
                     </div>
                     <span className="image-overlay bg-shadow-inverted"></span>
                  </div>
               </div>
            </div>


            {recursos.map((data, i) =>
               <div className="show-case-category animated fadeInUp delay-0-4s" key={i}>
                  <div className="section-sub-title">
                     <div className="row littleTitle mt-4 pl-3 pl-sm-0">
                        <div className="col-12 text-left">
                           <h4>{data.categoria}</h4>
                        </div>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-12 p-0">
                        <Slider
                           beforeChange={handleBeforeChange}
                           afterChange={handleAfterChange}
                           className="recursos p-3" {...settingsSlider}>

                           {data.recursos.map((recurso =>
                              <RecursoCard
                                 key={recurso._id}
                                 handleOnItemClick={handleOnItemClick}
                                 recurso={recurso} />
                           ))}

                        </Slider>
                     </div>
                  </div>
               </div>
            )}
            <div className="footerPush"></div>

            {/* TODO: iterar acorde a la información */}
            {optionsTypeLink.map((opt, idx) => {
               return (
                  <div className="card mb-3" key={idx} >
                     {
                        idx === 0 ? null : (
                           <div className="row g-0">
                              <div className="col-md-4">
                                 <img src={opt.img} alt="..." style={{ maxWidth: "200px" }} className="img-thumbnail pl-1" />
                              </div>
                              <div className="col-md-8">
                                 <div className="card-body">
                                    <h5 className="card-title">Test {idx}</h5>
                                    <p className="card-text">{opt.value}</p>
                                    <button className="btn btn-outline-primary btn-block" onClick={() => {
                                       setNumRecurso(idx)
                                       setAlertModalIsOpen(true)
                                    }}  >
                                       Ver Recurso
                                    </button>
                                    {
                                       startDate !== '' ? <p className="card-text"><small className="text-muted">Visto por ultima vez a las {startDate} </small></p> : null
                                    }

                                 </div>
                              </div>
                           </div>
                        )
                     }

                  </div>
               );
            })}



            <Modal
               isOpen={alertModalIsOpen}
               style={customModalStyles}
               onRequestClose={() => {
                  setAlertModalIsOpen(false)
               }}
            >

               <div className="alertModal">
                  <h3>Recursos Captivate</h3>
                  <div className="text-center">

                     <Iframe url={`https://app.redblu.cl/recursos/test${numRecurso}`}
                        width="500"
                        height="600"
                        id="myId"
                        scrolling="true"
                        className="myClassname"
                        display="initial"
                     />
                     <button
                        type="button"
                        className="btn btn-danger btn-sm pl-2 d-flex"
                        onClick={() => {
                           setAlertModalIsOpen(false)
                        }}
                     >
                        Cerrar
                     </button>
                  </div>
               </div>
            </Modal>



         </div>

      );
   }
};

export default Explorar;

const RecursoCard = ({ recurso, handleOnItemClick }) => {
   let styles = {
      backgroundImage: `url(${recurso.image})`,
   };

   return (
      <div className="col-12 animated fadeInUp delay-0-4s recursoCard">
         <div className="row recurso-item">
            <button
               onClickCapture={() => handleOnItemClick(recurso._id)}
               value={recurso._id}>

               <div className="col-xs-12 col-sm-12 p-0">
                  <div className="rounded-image"
                     style={styles}>
                     <span className="image-overlay bg-shadow-inverted"></span>
                  </div>
               </div>
               <div className="col-sm-12 col-xs-12 p-2 px-3 nombreRecurso">
                  <h2>{recurso.name}</h2>
               </div>

            </button>
         </div>
      </div>
   )
}