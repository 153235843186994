import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import { ErrrorMessages } from './components/Errors/ErrrorMessages';

// Components
import Loading from '../../../components/Loading';
import { InfoCardProfesional } from './components';
import InfoAcceso from './sections/InfoAcceso';
import InfoProfesional from './sections/InfoProfesional';

// Services
import ProfesionalService from '../../services/ProfesionalService';

// hooks
import { useStatesSelects } from './hooks/useStatesSelects';
import { useStatesAttributes } from './hooks/useStatesAttributes';

// Refactoring Selectores
import {
  getSelectAniosExperiencia,
  getSelectCasaEstudio,
  getSelectEdadesAtencion,
  getSelectEspecialidadesDestacadas,
  getSelectOtrasEspecialidades,
  getSelectTecnicaTerapia,
  getSelectTituloProfesional
} from './services/GetSelects';

import './css/editarPerfil.css';

export const EditarPerfil = ({
  user,
  setDataProfesionaTemp,
  dataProfesionalTemp,
}) => {


  // Servicio Editar Perfil
  const [isLoadingEdit, setIsLoadingEdit] = useState(true);

  // State Data Profesional
  const [dataProfesional, setDataProfesional] = useState({
    name: "",
    id: 0,
    categories: [],
    price: 0,
  });

  const [profesionalImagen, setProfesionalImagen] = useState({
    alt: "",
    src: "",
    name: "",
    id: 0,
  });

  const [descripcion, setDescripcion] = useState("");

  const [minutosAtencion, setMinutosAtencion] = useState([]);

  const [errors, setErrors] = useState({});

  // State Atributos
  const stateAttribute = [{ id: null, options: [], }];
  const { aniosExperiencia, setAniosExperiencia } = useStatesAttributes(stateAttribute);
  const { tituloProfesional, setTituloProfesional } = useStatesAttributes(stateAttribute);
  const { casaEstudio, setCasaEstudio } = useStatesAttributes(stateAttribute);
  const { especialidadesDestacadas, setEspecialidadesDestacadas } = useStatesAttributes(stateAttribute);
  const { edadesAtencion, setEdadesAtencion } = useStatesAttributes(stateAttribute);
  const { otrasEspecialidades, setOtrasEspecialidades } = useStatesAttributes(stateAttribute);
  const { tecnicaTerapia, setTecnicaTerapia } = useStatesAttributes(stateAttribute);
  const { numSuperintendencia, setNumSuperintendencia } = useStatesAttributes(stateAttribute);

  // State Select
  const stateStelect = [{ term_id: 0, name: '', loading: true }];
  const { selectAniosExperiencia, setSelectAniosExperiencia } = useStatesSelects(stateStelect);
  const { selectTituloProfesional, setSelectTituloProfesional } = useStatesSelects(stateStelect);
  const { selectCasaEstudio, setSelectCasaEstudio } = useStatesSelects(stateStelect);
  const { selectEspecialidadesDestacadas, setSelectEspecialidadesDestacadas } = useStatesSelects(stateStelect);
  const { selectEdadesAtencion, setSelectEdadesAtencion } = useStatesSelects(stateStelect);
  const { selectOtrasEspecialidades, setSelectOtrasEspecialidades } = useStatesSelects(stateStelect);
  const { selectTecnicaTerapia, setSelectTecnicaTerapia } = useStatesSelects(stateStelect);



  useEffect(() => {

    let mounted = true;

    const getDataProfesional = async () => {
      try {

        const res = await ProfesionalService.getDatosProfesional(user.woocommerceId);

        if (res && mounted) {

          setDataProfesional({
            name: res.name,
            id: res.id,
            categories: res.categories,
            price: res.price,
          });

          setDescripcion(res.description);

          setMinutosAtencion(res.duration);

          setProfesionalImagen({
            alt: res.images[0].alt,
            src: res.images[0].src,
            name: res.images[0].name,
            id: res.images[0].id
          });


          setAniosExperiencia(res.optionAniosExperiencia);

          setTituloProfesional(res.optionTituloProfesional);

          setCasaEstudio(res.optionCasaEstudio);

          setEspecialidadesDestacadas(res.optionEspecialidadesDestacadas);

          setOtrasEspecialidades(res.optionOtrasEspecialidades);

          setEdadesAtencion(res.optionEdadesAtencion);

          setTecnicaTerapia(res.optionTecnicaUsadaTerapia);

          setNumSuperintendencia(res.numeroSuperintendencia);



          // Selectores
          getSelectAniosExperiencia(setSelectAniosExperiencia, setErrors);

          getSelectTituloProfesional(setSelectTituloProfesional, setErrors);

          getSelectCasaEstudio(setSelectCasaEstudio, setErrors);

          getSelectEspecialidadesDestacadas(setSelectEspecialidadesDestacadas, setErrors);

          getSelectOtrasEspecialidades(setSelectOtrasEspecialidades, setErrors);

          getSelectEdadesAtencion(setSelectEdadesAtencion, setErrors);

          getSelectTecnicaTerapia(setSelectTecnicaTerapia, setErrors);

          setIsLoadingEdit(false);

        }
      } catch (error) {
        console.log('error : ', error)
        setIsLoadingEdit(false);
      }
    }

     mounted && getDataProfesional();


    return () => {
      mounted = false; // cleanup function

    };

  }, []);

  return (
    <>
      <div className="container-fluid bg-editar-perfil">
        {isLoadingEdit ? <Loading /> : (
          <Grid container>

            {/* Mi Cuenta */}
            <Grid item lg={2} xl={2} className="oculta-responsivo" >
              <InfoCardProfesional
                dataProfesional={dataProfesional}
                profesionalImagen={profesionalImagen}
              />
            </Grid>

            {/* Información */}
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} >
              <div className='mt-4'>

                <div className='info-section shadow-sm p-4'>

                  <InfoProfesional
                    user={user}
                    dataProfesional={dataProfesional}
                    profesionalImagen={profesionalImagen}
                    descripcion={descripcion}
                    minutosAtencion={minutosAtencion}
                    especialidadesDestacadas={especialidadesDestacadas}
                    aniosExperiencia={aniosExperiencia}
                    tituloProfesional={tituloProfesional}
                    edadesAtencion={edadesAtencion}
                    numeroSuperintendencia={numSuperintendencia}
                    otrasEspecialidades={otrasEspecialidades}
                    casaEstudio={casaEstudio}
                    tecnicaTerapia={tecnicaTerapia}
                    selectAniosExperiencia={selectAniosExperiencia}
                    selectTituloProfesional={selectTituloProfesional}
                    selectCasaEstudio={selectCasaEstudio}
                    selectEspecialidadesDestacadas={selectEspecialidadesDestacadas}
                    selectEdadesAtencion={selectEdadesAtencion}
                    selectOtrasEspecialidades={selectOtrasEspecialidades}
                    selectTecnicaTerapia={selectTecnicaTerapia}
                    errors={errors}
                    isLoading={isLoadingEdit}
                    setDataProfesionaTemp={setDataProfesionaTemp}
                    dataProfesionalTemp={dataProfesionalTemp}
                  />

                </div> 
                <InfoAcceso
                  user={user}
                />

              </div>
            </Grid>
          </Grid>
        )}
        {errors && <ErrrorMessages errors={errors} />}
      </div>
    </>
  )
}

export default EditarPerfil;
