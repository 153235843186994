import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";
import imgListo from './../../assets/images/guia/listoVideoAtencion.svg';

const SuccessMessage = () => {
  const history = useHistory();

  useEffect(() => {
      setTimeout( () => {
        history.push("/paciente");
    },3000 )
  });
  
  return ( 
    <div className="content-container animated fadeIn slow fullh">
      <div className="row align-items-center fullh">
        <div className="col">
          <div className="animated jackInTheBox text-center">
            <img 
              src={imgListo} alt="Cuenta lista" 
              className="img-fluid animated pulse infinite slow mb-3" />
            <h1>¡Muy bien, tu cuenta está lista!</h1>
          </div>
        </div>
      </div>
    </div>
   );
}

export default SuccessMessage;