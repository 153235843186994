import React, { useState, useEffect } from "react";
import { inicializaSocket, getSocketById } from '../../../services/socket';
import Messages from '../Messages/Messages';
import Input from '../Input/Input';
import './ChatRoom.css';
import moment from "moment-timezone";
import {
   IconButton,
   Container,
   Grid,
   ListItem,
   ListItemText,
   ListItemIcon,
   Typography,
 } from '@material-ui/core';
 import {
   ArrowBack,
   Close,
   AccountCircle
 } from '@material-ui/icons';


const ChatRoom = ({ 
   rutUser,
   nameUser, 
   roomData, 
   tipoUsuario, 
   isChatSelected,
   chatRoomSelected,
   handleDisplayMode,
   handleToggle,
   handleUpdateMessage
}) => {
   
   const[lastMessage, setLastMessage] = useState({
      user:'',
      message:''
    });
   const [users, setUsers] = useState([]);
   const [message, setMessage] = useState('');
   const [messages, setMessages] = useState(roomData.messages);
   const [userConnected, setUserConnected] = useState({
      connected:false,
      initial:false,
      date: null
   });
   const { name, room, rut } = { name: nameUser, room: roomData._id, rut: rutUser };
   const [socket, setSocket] = useState(null);

   useEffect(() => {
      //var socket = null;

      if (name && room && rut) {   
         let socketCreated = getSocketById(roomData._id);

         if(socketCreated){
            setSocket(socketCreated.socket);
         }else{
            setSocket(inicializaSocket({room:room, rut:rut, name:name}));
         }  
      }
      
      return function(){
         if(socket){
            socket.removeAllListeners();
         }
      }

   }, [name, room, rut, roomData]);


   useEffect(() => {
      setLastMessage(roomData.messages.at(-1));
   },[]);


  useEffect(() => {
    if(socket){
      socket.emit('join', { rut, name, room }, (error) => {});

      socket.removeAllListeners("message");
      socket.on('message', message => {
        setMessages(messages => [...messages, message]);
        handleUpdateMessage(roomData, message);
        setLastMessage(message);
      });

      socket.removeAllListeners("roomData");
      socket.on("roomData", ({ users }) => {
        if(users.length > 1){
            setUserConnected({
              connected:true,
              initial:true,
              date:moment.utc()
            });
        }

        setUsers(users);
      });

      socket.removeAllListeners("disconnect");
      socket.on('disconnect', data => {

        let testDateUtc = moment.utc(data.date);
        let localDate = moment(testDateUtc).local();

        setUserConnected({
            connected:false,
            initial:false,
            date:localDate
        });
      });
    }

  }, [socket]);


    const sendMessage = (event) => {
      event.preventDefault();
      
      if (message) {
        const userToNotify = (tipoUsuario === 'PROFESIONAL' ? roomData.patientRut : roomData.medicRut);
        socket.emit('sendMessage', message, () => setMessage(''));
      //   console.log(userToNotify, message);
        socket.emit('notifyNewMessage', {
          userToNotify: userToNotify, 
          message: message, 
          userFrom: rut
        });
      }
    }

    const [open, setOpen] = React.useState(false);
   

    const changeDisplayMode = (newDisplayMode) => {
      handleDisplayMode(roomData, newDisplayMode);
    }

    const isSameUser = () => {
      if(name && lastMessage && lastMessage.user){
          let trimmedName = name.trim().toLowerCase();
          let trimmedUser = lastMessage.user.trim().toLowerCase();
          
          return (trimmedUser === trimmedName);
      }
    };

   return (
      <>
         {isChatSelected && roomData.displayMode === 'extend' ?

            <>
               <div className="sectitle admin-submenu">
                  <Container maxWidth="lg">
                     <Grid
                        container
                        direction="row"
                        alignItems="center"
                     >
                        <Grid item lg={12} md={12} sm={12} xs={12} className="admin-menu">
                           <div className="header-chat-bar">
                              <Typography variant="h5" className="">
                                 <IconButton aria-label="delete" onClick={() => changeDisplayMode("compact")}>
                                 <ArrowBack className="colorWhite" />
                                 </IconButton>
                                 <div className="chat-user-status row align-items-center pl-3 pr-2">
                                 <strong>{tipoUsuario === 'PROFESIONAL' ? roomData.patientName : roomData.medicName}</strong>
                                    {userConnected.connected === true ?
                                    <small><span>En línea</span></small>
                                    :
                                    <>
                                       {!userConnected.initial && userConnected.date &&
                                          <small><span>Ult. vez activo a las {userConnected.date.format("LT")}</span></small>
                                       }
                                    </>
                                 }
                                 </div>
                                 <IconButton aria-label="delete" onClick={() => handleToggle()} className="close-button">
                                    <Close className="colorWhite" />
                                 </IconButton>
                              </Typography>
                           </div>
                        </Grid>
                     </Grid>
                  </Container>
               </div>

               <Container className="h-100" maxWidth="lg" style={{padding:0}}>            
                  <Grid
                     container
                     spacing={0}
                     direction="row"
                     justifyContent="space-between"
                     alignItems="stretch"
                     className="fullChatWrapper h-100"
                  >
                     <Grid
                        className="chatWrapper h-100">
                           <div className="chatPaciente animated backInUp">
                              <div className="container">
                                 {userConnected.connected === false &&
                                    <span className="messageNoConnected">El usuario <b>no está conectado</b> en este momento pero <b>podrá ver tus mensajes cuando vuelva a conectarse</b></span>
                                  }
                                 <Messages messages={messages} name={nameUser} />
                                 <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
                              </div>
                           </div>
                     </Grid>
                  </Grid>
               </Container>
            </>
         : null }

         {!isChatSelected && roomData.displayMode === 'compact' ?
            <>
               <ListItem
                     button
                     onClick={() => changeDisplayMode("extend")}
                  >
                  <ListItemIcon>
                     
                     <div className={userConnected && userConnected.connected === true ? "connected-indicator connected-true" : "connected-indicator connected-false"}>
                        <AccountCircle />
                     </div>
                     
                  </ListItemIcon>
                  <ListItemText
                     primary={tipoUsuario === 'PROFESIONAL' ? roomData.patientName : roomData.medicName}
                     secondary={
                     <>
                        {lastMessage && lastMessage.user &&
                           <span className="lastmessage-recieved">
                              {
                                 isSameUser() ? 
                                 <>
                                    <Typography
                                       sx={{ display: 'inline' }}
                                       component="span"
                                       variant="body2">  
                                       {lastMessage.user ? lastMessage.user : ''}
                                    </Typography>
                                    {lastMessage.message ? ' - '+lastMessage.message : ''}
                                 </>
                                 : 
                                 <>
                                    <strong>
                                       <Typography
                                          sx={{ display: 'inline' }}
                                          component="span"
                                          style={{fontWeight:'bold'}}
                                          variant="body2">  
                                          {lastMessage.user ? lastMessage.user : ''}
                                       </Typography>
                                       {lastMessage.message ? ' - '+lastMessage.message : ''}
                                    </strong>
                                 </>
                              }
                           </span>
                        }
                     </>
                     }
                  />
               </ListItem>
            </>
            : null
         }
      </>
   );
}

export default ChatRoom;