import React, { useEffect, useState } from "react";
import {
   BrowserRouter as Router,
   useRouteMatch,
   Link,
} from "react-router-dom";

import icono_next from "../../assets/images/iconos/icono-white-next.svg";
import Loading from "../../components/Loading";
import ReactGA from 'react-ga';

const CategoriaRecursos = ({ recursos, categoriaName }) => {
   const match = useRouteMatch();
   const [recursosFiltrados, setRecursosFiltrados] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      let filtrados = recursos.filter(
         (recurso) =>
            recurso.category.filter(
               (categoria) =>
                  categoria.nameCategory.toLowerCase() ===
                  categoriaName.toLowerCase()
            ).length
      );

      let lista = filtrados;
      lista = lista.sort(function () { return Math.random() - 0.5 });

      setRecursosFiltrados(lista);
      setLoading(false);
      ReactGA.pageview(match.path);
   }, []);

   if (loading) {
      return (
         <div className="fullh movile-app">
            <Loading />
         </div>
      )
   } else {
      return (
         <div>
            <div className="home-top-nav container-fluid">
               <div className="header-nav-spacing animated fadeIn fast">
                  <div className="row">
                     <div className="col-12 page-name">
                        <h1>{categoriaName}</h1>
                     </div>
                  </div>
               </div>
            </div>

            <div className="recursos-page resource-list">
               <div className="container-fluid">
                  <div className="row align-items-center resource-item higiene-resource-item">
                     {recursosFiltrados.map((recurso) => (
                        <ResumenRecurso key={recurso._id} recurso={recurso} />
                     ))}
                  </div>
               </div>
            </div>
         </div>
      );
   }
};

export default CategoriaRecursos;

const ResumenRecurso = ({ recurso }) => {
   let styles = {
      backgroundImage: `url(${recurso.image})`,
   };

   let shortDescription = recurso.shortDescription.substr(0, 115);

   return (
      <div className="col-12 animated fadeInUp delay-0-4s">
         <div className="row recurso-item">
            <div className="col-xs-12 col-sm-4 p-0">
               <div className="rounded-image">
                  <Link
                     to={"/paciente/recursos/" + recurso._id}
                     style={styles}
                  >
                     <span className="duracion">
                        {recurso.extraDetail.duration}
                     </span>
                     <span className="image-overlay bg-shadow-inverted"></span>
                  </Link>
               </div>
            </div>
            <div className="col-sm-8 col-xs-12 p-4">
               <h2>{recurso.name}</h2>
               <p>{shortDescription}</p>
               <span className="next-button">
                  <Link to={"/paciente/recursos/" + recurso._id}>
                     VER MÁS <img src={icono_next} alt="VER" />
                  </Link>
               </span>
            </div>
         </div>
      </div>
   );
};
