import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';


export const PrivateRoute = ({
    // enviaremos property

    isAuthenticated,
    component: Component,
    ...rest
}) => {

    // Guardamos en localstorage
    // localStorage.setItem('lastPath', rest.location.pathname);
    const lastPath = localStorage.getItem('lastPath') || '/';
    return (
        // Devuelvo una ruta
        <Route {...rest}
            component={(props) => (
                // regreso estio de manera condicional
                (isAuthenticated)
                    ? (<Component {...props} />) // si está autenticado
                    : (<Redirect to="/login" replace />)
            )}
        />
    )
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}



/**
 * Este funcional componente tendrá un comportamiento especial
 */