import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import { useRouteMatch } from "react-router-dom";
import ReactGA from "react-ga";
import patientApi from "../../services/patients";
// import AssignmentServices from "../../../../services/assignment";
import moment from "moment-timezone";
import ActividadesDiarias from "./ActividadesDiarias";
import Actividad from "./Actividad";
import Loading from "../../components/Loading";
import { getSocketClient, isSocketEnable } from '../../services/socket';
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { UseReturnDates } from "../hooks/UseReturnDates";
// Fechas de Inicio Diaria y Semanal



const ActividadesRouter = ({ patient }) => {

  const returnDates = UseReturnDates();
  useEffect(() => {

  }, [returnDates]);

  let fechaActual = moment(returnDates.fechaActual)
    .hour(0)
    .minute(0)
    .seconds(0);

  let fechaIniWeek = moment(returnDates.fechaActual)
    .subtract(7, "days")
    .hour(0)
    .minute(0)
    .seconds(0);

  // Fechas de Actual Diaria y Semanal
  let fechaFin = moment(returnDates.fechaFinDia);

  let fechaFinWeek = moment(returnDates.fechaFinDia);
  // console.log(patient);
  const { user } = useContext(UserContext);

  const [patientId, setPatientId] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notificationsWeek, setNotificationsWeek] = useState([]);
  const [selectedReminder, setSelectedReminder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(fechaActual.toDate());
  const [endDate, setEndDate] = useState(fechaFin.toDate());
  const [startDateWeek, setStartDateWeek] = useState(fechaIniWeek.toDate());
  const [endDateWeek, setEndDateWeek] = useState(fechaFinWeek.toDate());
  const [messagesCount, setMessagesCount] = useState([]);

  const isMounted = useRef(true);
  //  console.log(patient);
  const match = useRouteMatch();
  let count = [];
  useEffect(() => {
    if (isSocketEnable) {
      const listenSocket = () => {
        let socket = getSocketClient();

        if (socket) {
          socket.on("notification", ({ type, text }) => {
            setMessagesCount(count.push(socket.ids + 1));
          });
        }
      }
      listenSocket();
      // console.log(count);
    }
    return () => {
      isMounted.current = false;
    }
  }, [count])
  
  useEffect(() => {
    // cuando se desmonte
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {

    localStorage.removeItem("notificaciones");
    //TODO: try catch del fetch
    let startOf = startDate;
    let endOf = endDate;
    let startOfWeek = startDateWeek;
    let endOfOfWeek = endDateWeek;

    const params = {
      startOfPeriod: moment(startOf).format("YYYY-MM-DD"),
      endOfPeriod: moment(endOf).format("YYYY-MM-DD"),
    };
    // Se agregan variables paralas notificaciones de una semana
    const paramsWeeek = {
      startOfPeriod: moment(startOfWeek).format("YYYY-MM-DD"),
      endOfPeriod: moment(endOfOfWeek).format("YYYY-MM-DD"),
    };

    let notificationsForToday = [];
    let notificationsForWeek = [];
    const fetchNotifications = async () => {
      try {
        await patientApi
          .getResources(user._id, params)
          .then(async ({ data }) => {
            if (Array.isArray(data) && isMounted.current) {
              data.map(
                (
                  { _id, assignmentDate, resource, medicUser, days, data },
                  i
                ) => {
                  let reminderObj = {};

                  reminderObj = {
                    assignmentDate,
                    reminderId: _id,
                    medicUser: medicUser,
                    notificationCategory: data.typeLink,
                    resource: resource,
                    data: data,
                    dayId: days[0]._id,
                    dateTime: moment.utc(days[0].dateTime),
                    completed: days[0].completed,
                  };

                  return notificationsForToday.push(reminderObj);
                }
              );
            }
            //  console.log(data);
          })
          .catch((err) => {
            notificationsForToday.push([]);
            console.log("error : ", err);
          });
      } catch (error) {
        console.log(error);
      }
      try {
        await patientApi
          .getResources(user._id, paramsWeeek)
          .then(async ({ data }) => {
            if (Array.isArray(data) && isMounted.current) {
              data.map(
                ({
                  _id,
                  assignmentDate,
                  days,
                  data,
                  patient,
                  isActive,
                  resource,
                  updatedAt,
                  startOfPeriod,
                  timesInTheDay,
                }) => {
                  let reminderObj = {};
                  days.map((day, i) => {

                    reminderObj = {
                      reminderId: _id,
                      assignmentDate,
                      notificationCategory: data.typeLink,
                      resource: resource,
                      data: data,
                      dayId: days[i]._id,
                      dateTime: moment.utc(days[i].dateTime),
                      completed: days[i].completed,
                      patient,
                      isActive,
                      updatedAt,
                      startOfPeriod,
                      timesInTheDay,
                    };
                    return notificationsForWeek.push(reminderObj);
                  })
                  return reminderObj;
                }
              );
            }
          })

          .catch((err) => {
            notificationsForWeek.push([]);
            console.log("error : ", err);
          });
      } catch (error) {
        console.log(error);
      }

      // Orden de horas en forma ascendente de actividades diarias
      notificationsForToday.sort(
        (a, b) => new Date(b.assignmentDate) - new Date(a.assignmentDate)
      );
      setNotifications(notificationsForToday);

      // Orden de fechas desde las mas actual a las más antigua
      notificationsForWeek.sort(
        (a, b) => new Date(b.assignmentDate) - new Date(a.assignmentDate)
      );
      //  console.log(notificationsForWeek);
      setNotificationsWeek(notificationsForWeek);

      //Antes de hacer el render verifica si ya se cargó el id del usuario
      if (user._id) {
        setIsLoading(false);
        setPatientId(user._id);
        getSocketClient();
      }
      setSelectedReminder(null);
    };

    fetchNotifications();
    ReactGA.pageview(match.path);
    // Limpieza
    return () => {
      isMounted.current = false;
    };
  }, [endDate, endDateWeek, match.path, user._id, startDate, startDateWeek, messagesCount]);

  const onChangeNotification = (data) => {
    // Actividades diarias
    var newNotific = [...notifications];
    for (let i = 0; i < newNotific.length; i++) {
      const notif = newNotific[i];
      if (notif._id === data._id) {
        notif.tareaRealizada = data.completed;
        break;
      }
    }
    setNotifications(newNotific);

    //Actividades semanales
    var newNotificWeek = [...notificationsWeek];
    for (let i = 0; i < newNotificWeek.length; i++) {
      const notif = newNotificWeek[i];
      if (notif._id === data._id) {
        notif.tareaRealizada = data.completed;
        break;
      }
    }
    setNotificationsWeek(newNotificWeek);
  };

  const onChangeReminder = (dayId) => {
    let reminderSelected = notifications.filter(
      (reminder) => reminder.dayId === dayId
    );
    setSelectedReminder(reminderSelected[0]);
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const handleClickSi = async (recordatorioId, dayId) => {
    // console.log("recordatorioID: > " + recordatorioId + " dayID: > " + dayId);
    try {
      patientApi.setNotificationCompleted(recordatorioId, {
        dayId: dayId,
        completed: true,
      });
    } catch (error) {
      console.log(error);
    }

    //Actividades diarias
    let reminderCopy = notifications;
    let reminderUpdated = reminderCopy.find(
      (reminder) => reminder.dayId === dayId
    );
    reminderUpdated.completed = true;
    setNotifications(reminderCopy);

    //Actividades semanales
    let reminderCopyWeek = notificationsWeek;

    let reminderUpdatedWeek = reminderCopyWeek.find(
      (r) => r.reminderId === recordatorioId
    );

    reminderUpdatedWeek.completed = true;
    setNotificationsWeek(reminderCopyWeek);

    await delay(1500);
    setSelectedReminder(null);
  };

  if (isLoading) {
    return (
      <div className="fullh movile-app">
        <Loading />
      </div>
    )
  } else {
    return (
      <div className="fullh">
        {selectedReminder ? (
          <Actividad
            categoria={selectedReminder.notificationCategory}
            nombre={selectedReminder.data.activityName}
            objetivo={selectedReminder.data.objective}
            instrucciones={selectedReminder.data.pacientInstructions}
            url={selectedReminder.data.externalLink}
            urlDescripcion={selectedReminder.data.externalLinkDescription}
            setSelectedReminder={setSelectedReminder}
            horaAsignada={selectedReminder.dateTime}
            esCompletada={selectedReminder.completed}
            handleClickSi={handleClickSi}
            recordatorioId={selectedReminder.reminderId}
            dayId={selectedReminder.dayId}
            recordatorio={selectedReminder}
            patientId={user._id}
          />
        ) : (
          <>
            <ActividadesDiarias
              notifications={notifications}
              notificationsWeek={notificationsWeek}
              fechaIniWeek={fechaIniWeek}
              fechaFinWeek={fechaFinWeek}
              onChangeNotification={onChangeNotification}
              onChangeReminder={onChangeReminder}
              patientId={user._id}
              messagesCount={messagesCount}
            />
          </>
        )}
      </div>
    );
  }
};

export default ActividadesRouter;
