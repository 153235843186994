import React from 'react';
import configEnv from '../../../../configEnv';

export const IdAttributes = () => {
  

  let AniosExpericiaId;
  let OtrasEspecialidadesId;
  let casaEstudioId;
  let edadesAtencionId;
  let especialidadDestacadaId;
  let numeroSuperIntendenciaId;
  let tecnicaUsadaTerapiaId;
  let tituloProfesionalId;

  if (configEnv.ENV_APP === 'qa' || configEnv.ENV_APP === 'local') {
    // IDs -QA
    AniosExpericiaId          = 35;
    OtrasEspecialidadesId     = 29;
    casaEstudioId             = 14;
    edadesAtencionId          = 21;
    especialidadDestacadaId   = 32;
    numeroSuperIntendenciaId  = 34;
    tecnicaUsadaTerapiaId     = 31;
    tituloProfesionalId       = 33;

  } else if (configEnv.ENV_APP === 'production') {
    // IDs -PROD
    AniosExpericiaId          = 35;
    OtrasEspecialidadesId     = 38;
    casaEstudioId             = 14;
    edadesAtencionId          = 36;
    especialidadDestacadaId   = 32;
    numeroSuperIntendenciaId  = 34;
    tecnicaUsadaTerapiaId     = 31;
    tituloProfesionalId       = 33;
  }


  return {
    edadesAtencionId,
    casaEstudioId,
    especialidadDestacadaId,
    tecnicaUsadaTerapiaId,
    numeroSuperIntendenciaId,
    tituloProfesionalId,
    OtrasEspecialidadesId,
    AniosExpericiaId,
  }

}
