import { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import ChatMain from "./ChatMain/ChatMain";

const ChatProvider = () => {

  const { user } = useContext(UserContext);
  
  return(
    <>
      {user && user.rut !== '' ?
        <ChatMain
          user={user}
          type={user.role}
        />
      : null }
    </>
  )
};

export default ChatProvider;