import React from 'react'

export const ErrrorMessages = (props) => {
  const { errors } = props;

  

  return (
    <div className='text-center'>

    </div>
  )
}
