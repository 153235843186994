import React, { useState, useContext } from "react";
import Modal from "react-modal";
import ReactGA from "react-ga";
import patiensService from "../../services/patients";
import authentication from "../../services/authentication";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  setNewToken,
  setLogedPatient,
  setLogedPsicologo,
} from "../../services/api";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";
import imgLogo from "./../../assets/images/redblu.png";
import { UserContext } from "../../context/UserContext";
import Dictionary from "../../Profesional/utils/Dictionary";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const customModalStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-15%",
    transform: "translate(-50%, -50%)",
  },
};
const Login = (props) => {

  const { setLogged, setUser, setAccessToken } = useContext(UserContext);
  const lastPath = localStorage.getItem('lastPath') || '/';

  const history = useHistory();
  const [inputValues, setInputValues] = useState({ rut: "", });
  const [entrando, setEntrando] = useState(false);
  const [isRutValid, setIsRutValid] = useState(true);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");
  const [medicModalIsOpen, setMedicModalIsOpen] = useState(false);

  const handleInputChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
    ReactGA.event({
      category: "Login",
      action: "handleInputChange",
      label: event.target.name,
      value: event.target.value + "",
    });
  };

  const handleRutChange = (event) => {
    let inputValue = event.target.value.toLowerCase();
    let formatValue = inputValue;
    const isValid = validateRut(inputValue);

    if (isValid) {
      formatValue = formatRut(inputValue, RutFormat.DASH);
    }
    setIsRutValid(isValid);

    setInputValues({
      ...inputValues,
      [event.target.name]: formatValue,
    });
    ReactGA.event({
      category: "Login",
      action: "handleRutChange",
      label: event.target.name,
      value: formatValue + "",
    });
  };

  const onSubmitRut = () => {
    let formatValue = inputValues.rut;
    const isValid = validateRut(inputValues.rut);

    if (isValid) {
      formatValue = formatRut(inputValues.rut, RutFormat.DASH);
    }
    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      rut: formatValue,
    });
    ReactGA.event({ category: "Login", action: "onSubmitRut", value: isValid });
  };

  const onSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    ReactGA.event({
      category: "Login",
      action: "onSubmit",
      label: event.target.name,
      value: event.target.value + "",
    });
    onSubmitRut();
    setEntrando(true);

    const loginData = {
      username: inputValues.rut,
      password: inputValues.password
    }

    try {
      const { data } = await authentication.roleVerify(loginData);

      if (data.medic && data.patient) {
        setMedicModalIsOpen(true);
        return;
      }

      if (data.medic) {
        handleProfesionalLogin(null);
      }

      if (data.patient) {
        handlePatientLogin(null);
      }

    } catch (err) {
      console.log("err :>> ", err);
      setAlertModalText("Usuario o contraseña incorrectos.");
      setAlertModalIsOpen(true);
      setEntrando(false);
      ReactGA.modalview("Login_Incorrect");
    }
  };

  const handlePatientLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const loginData = {
      username: inputValues.rut,
      password: inputValues.password,
      role: Dictionary.ROLE.PATIENT
    }

    const { data } = await authentication.login(loginData);

    if (data) {
      setUser({
        ...data.patient,
        role: loginData.role
      });
      setAccessToken(data.accessToken);
      setLogged(true);
      setNewToken(data.accessToken);

      const p = setLogedPatient(data.patient);
      localStorage.removeItem("enSalaEspera");
      const parsed = queryString.parse(window.location.search);

      if (parsed.redirect === "teleconsultaPaciente") {
        history.replace("/paciente/teleconsulta");
        localStorage.setItem('lastPath', '/paciente/teleconsulta');
      } else {
        history.replace("/paciente");
        localStorage.setItem('lastPath', '/paciente');
      }
      patiensService
        .setLogActivityUser(p.rut, "Inicio Sesión")
        .then((response) => { });
    }
  }

  const handleProfesionalLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }

    const loginData = {
      username: inputValues.rut,
      password: inputValues.password,
      role: Dictionary.ROLE.PROFESIONAL
    }

    const { data } = await authentication.login(loginData);

    if (data) {
      setUser({
        ...data.medic,
        role: loginData.role
      });
      setAccessToken(data.accessToken);
      setLogged(true);
      setNewToken(data.accessToken);

      const p = setLogedPsicologo(data.medic);
      localStorage.removeItem("enSalaEspera");
      history.replace("/profesional");
      localStorage.setItem('lastPath', '/profesional');

    }
  }

  return (
    <div className="content-container animated fadeIn slow fullh">
      <div className="middle-center-content pt-3">
        <div className="row pb-2">
          <div className="col-12 text-center">
            <img
              src={imgLogo}
              width="20%"
              className="img-fluid"
              alt="RedBlu Logo"
            />
          </div>
        </div>
        <div className="heading mt-4">
          <h1 className="paged-title">Iniciar Sesión</h1>
        </div>
        <form onSubmit={onSubmit}>
          <div className="bodycontent">
            <div className="row">
              <div className="col-12 mb-3">
                <label htmlFor="rut">Rut</label>
                <div className="input-group">
                  <input
                    type="text"
                    className={isRutValid ? "" : "is-invalid"}
                    id="rut"
                    name="rut"
                    required
                    onChange={handleInputChange}
                    onBlur={handleRutChange}
                    placeholder="11120411-k"
                    value={inputValues.rut}
                  />
                  <div className="invalid-feedback">Rut invalido.</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-1">
                <label>Contraseña</label>
                <input
                  name="password"
                  onChange={handleInputChange}
                  required
                  type="password"
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12 text-left create-account">
                <a href="/olvido-contrasena" className="forgot-password">
                  ¿Olvidó su contraseña?
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-6 text-left create-account">
                <a href="/registro"> Crear una cuenta </a>
              </div>
              <div className="col-6 text-right">
                <input
                  type="submit"
                  value={entrando ? "CARGANDO" : "INGRESAR"}
                  disabled={entrando}
                />
              </div>
            </div>
          </div>
        </form>
      </div>

      {alertModalIsOpen &&
        <Modal
          isOpen={alertModalIsOpen}
          style={customModalStyles}
          onRequestClose={() => setAlertModalIsOpen(false)}
        >
          <div className="alertModal">
            <h1>{alertModalText}</h1>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-submit btn-lg"
                onClick={() => setAlertModalIsOpen(false)}
              >
                Aceptar
              </button>
            </div>
          </div>
        </Modal>
      }

      {medicModalIsOpen &&
        <Modal
          isOpen={medicModalIsOpen}
          style={customModalStyles2}
          onRequestClose={() => {
            setEntrando(false);
            setMedicModalIsOpen(false);
          }}
        >
          <div className="alertModal">
            <h1 className="font-weight-bold text-center">
              <img
                src={imgLogo}
                width="10%"
                className="img-fluid"
                alt="RedBlu Logo"
              />
              Que tipo de usuario desea utilizar
            </h1>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-submit btn-lg mr-1"
                onClick={handleProfesionalLogin}
              >
                Profesional
              </button>
              <button
                type="button"
                className="btn btn-submit btn-lg ml-3"
                onClick={handlePatientLogin}
              >
                Paciente
              </button>
            </div>
          </div>
        </Modal>
      }
    </div>
  );
};

export default Login;
