import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

const VideoActividad = ({ url, urlDescripcion }) => {
   const [urlVideo, setUrlVideo] = useState(null);
   const [tipo, setTipo] = useState(null);

   useEffect(() => {
      if (url.includes("youtu")) {
         let videoId = url.split("v=")[1];
         setUrlVideo(videoId);
         setTipo('Youtube');
      }
    
   },[url])
  
   // console.log(videoId);
   return (
      <div className="actividadFondo">
         {
            tipo === 'Youtube' ? 
               <iframe
                  id={"ytplayer"}
                  type={"text/html"}
                  width={"100%"}
                  height={"315"}
                  src={
                     "https://www.youtube-nocookie.com/embed/" +
                     urlVideo +
                     "?enablejsapi=1&html5=1&origin=" +
                     window.location.origin +
                     ""
                  }
                  frameBorder="0"
                  allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
                  allowFullScreen
                  title={"video-youtube"}
               ></iframe>
               : <div className={url ? 'p-0' : 'text-center p-4'}>
               {url ?  
                     <iframe 
                     width={"100%"}
                     height={"315"}
                     src={url}
                     title={"video"}
                     >                     
                      </iframe>
                     : 
                     <a
                        href={url}
                        className={url ? "" : "btn btn-secondary disabled "}
                     >
                        {
                         'VIDEO NO DISPONIBLE'
                        }
                        <br />
                        <small>no se ha proporcionado el enlace del recurso</small>
                     </a>
                  }    
               </div>
         }
       
      </div>
   );
};

export default VideoActividad;
