import React from 'react';
import { IconButton, Grid, Typography, Container } from '@material-ui/core';
import { Close, Chat } from '@material-ui/icons';
import './ChatHeader.css';

const ChatHeader = ( 
  {
    handleToggle,
    isChatSelected
  }) => {

    return (
      <>
        {!isChatSelected &&
          <div className="sectitle admin-submenu">
            <Container maxWidth="lg">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item lg={12} md={12} sm={12} xs={12} className="admin-menu">
                      <Typography variant="h5" className="colorWhite">
                        <IconButton aria-label="delete" className="colorWhite">
                            <Chat />
                        </IconButton>
                        <strong>Chat</strong>
                        <IconButton aria-label="delete" onClick={handleToggle} className="close-button">
                            <Close className="colorWhite" />
                            </IconButton>
                      </Typography>
                  </Grid>
                </Grid>
            </Container>
          </div>
        }
      </>
    );
};

export default ChatHeader;
