import React from 'react';
import Accordion from '../../../components/Accordion';
import imgGuiaWindows1 from "./../../../assets/images/help/guiaWindows1.png";
import imgGuiaWindows2 from "./../../../assets/images/help/guiaWindows2.png";
import imgGuiaWindows3 from "./../../../assets/images/help/guiaWindows3.png";
import imgGuiaWindows4 from "./../../../assets/images/help/guiaWindows4.png";

const GuiaWindows = () => {
    
    return(
        <Accordion
            id={"pcWindows"}
            titulo={"PC Windows / Mac"}
            descripcion={"Presiona para ver las indicaciones"}
        >
            <div className="guiastep">
                <div className="number">1</div>
                <div className="guiastep-title">
                    <h3>Presiona el candado</h3>
                </div>
                <div className="guiastep-content">
                    <p>Presiona el botón indicado en la imagen.</p>
                    <img src={imgGuiaWindows1} width="100%" className="border-image mb-2" />
                    <p>Al hacerlo desplegarás un cuadro en donde aparecerán las opciones de Cámara y Micrófono.</p>
                </div>
            </div>

            <div className="guiastep mt-2">
                <div className="number">2</div>
                <div className="guiastep-title">
                    <h3>Permite el uso</h3>
                </div>
                <div className="guiastep-content">
                    <p>Cambian dentro de los cuadros seleccionables la opción que aparece dejando marcado tanto para la Cámara como para el Micrófono la opción de "Permitir".</p>
                    <img src={imgGuiaWindows2} width="100%" className="border-image" />
                </div>
            </div>

            <div className="guiastep mt-2">
                <div className="number">3</div>
                <div className="guiastep-title">
                    <h3>Recargar la página</h3>
                </div>
                <div className="guiastep-content">
                    <p>Al realizar los cambios recarga la página para ver los cambios efectuados.</p>
                    <img src={imgGuiaWindows3} width="100%" className="border-image" />
                </div>
            </div>

            <div className="guiastep mt-2">
                <div className="guiastep-title">
                    <h3>Microsoft Edge</h3>
                </div>
                <div className="guiastep-content">
                    <p>Si estás utilizando otro navegador como Edge, la interfáz cambia un poco pero el proceso es muy similar. Primero presionando el candado en la parte superior para luego desplegar las opciones que te permitirán activar la cámara y micrófono.</p>
                    <img src={imgGuiaWindows4} width="100%" className="border-image" />
                </div>
            </div>
        </Accordion>    
    )
}

export default GuiaWindows;