import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { formatRut, RutFormat, validateRut } from '@fdograph/rut-utilities';
import { IconButton, } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { setNewToken } from "../../services/api";
import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import authentication from '../../services/authentication';
import imgLogo from "../../assets/images/redblu.png";
import patiensService from '../../services/patients';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

var onAceptModalFunction = () => { };

const EditAccount = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [isRutValid, setIsRutValid] = useState(true);
  const [guardando, setGuardando] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");
  const [inputValues, setInputValues] = useState({
    nombre: '',
    apellido: '',
    telefono: '',
    rut: '',
    email: '',
    emailConfirm: '',
  });

  useEffect(() => {}, [inputValues]);

  const handleInputChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value
    })
  }

  useEffect(() => {

    if (user) {
      setInputValues({
        nombre: user.firstname,
        apellido: user.lastname,
        telefono: user.phone,
        rut: user.rut,
        email: user.email,
        emailConfirm: user.email,
      })
    }

  }, [user]);

  const handleChangeInputText= e => {
    const { name, value } = e.target;
    let regex = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*$");
    
    if (regex.test(value)) {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.value
      })    
    } else {
      setAlertModalText("Ingresar solo letras.");
      setAlertModalIsOpen(true);
    }
  }

  const handlePhoneNumber = e => {
    const { name, value } = e.target;
    let regex = new RegExp("^[0-9]{0,9}$");

    if (regex.test(value)) {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.value
      })    
    }
  };

  const onSubmitRut = () => {
    let formatValue = inputValues.rut;
    const isValid = validateRut(inputValues.rut);

    if (isValid) {
      formatValue = formatRut(inputValues.rut, RutFormat.DASH);
    }

    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      'rut': formatValue
    })
  }

  const onSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
   
    onSubmitRut();

    if (!inputValues.nombre.trim()) {
      setAlertModalText("Nombre inválido.");
      setAlertModalIsOpen(true);
      return;
    }

    if (!isRutValid) {
      setAlertModalText("Rut inválido.");
      setAlertModalIsOpen(true);
      return;
    }

    if (inputValues.email !== inputValues.emailConfirm) {
      setAlertModalText("Los emails no coinciden.");
      setAlertModalIsOpen(true);
      return;
    }

    if (inputValues.password_old) {
      if (!inputValues.password1 || inputValues.password1.length < 5) {
        setAlertModalText("La contraseña debe tener al menos 5 caracteres.");
        setAlertModalIsOpen(true);
        return;
      }

      if (inputValues.password1 !== inputValues.password2) {
        setAlertModalText("Las contraseñas no coinciden.");
        setAlertModalIsOpen(true);
        return;
      }
    }

    if (inputValues.password1 || inputValues.password2) {
      if (!inputValues.password_old) {
        setAlertModalText("Debe ingresar la contraseña actual");
        setAlertModalIsOpen(true);
        return;

      } else {

        try {
          setGuardando(true);
          const passeditresp = await authentication.editarPassword({
            rutPaciente: user.rut,
            newPassword: inputValues.password1,
            oldPassword: inputValues.password_old,
          })
        } catch (error) {
          if (error.response) {
            if (error.response.status === 403) {
              setAlertModalText("La contraseña actual es incorrecta");
              setAlertModalIsOpen(true);
              setGuardando(false);
              return;
            }
          } else {
            throw error;
          }
        }
      }
    }

    setGuardando(true);

    patiensService.patch(user._id, {
      firstname: inputValues.nombre,
      lastname: inputValues.apellido,
      rut: inputValues.rut,
      phone: inputValues.telefono,
      email: inputValues.email
    }).then(response => {

      onAceptModalFunction = () => {
        // window.location.pathname = '/paciente';
        onAceptModalFunction = () => { };
      }
      setAlertModalText("Datos actualizados.");
      setAlertModalIsOpen(true);
      setGuardando(false);

    }).catch(error => {
      onAceptModalFunction = () => { }
      setAlertModalText(error.toString());
      setAlertModalIsOpen(true);
      setGuardando(false);
    })
  }

  const handleLogout = (e) => {
    e.preventDefault();
    authentication
      .logout()
      .then((response) => {
        setNewToken(null);
        window.location.pathname = "/paciente";
      })
      .catch((error) => {
        setNewToken(null);
        window.location.pathname = "/paciente";
      });
  };

  return (
    <div className="fullh">
      <div className="hoy-component fullwidth middday-bg animated fadeIn slow">
        <div className="header-redgesam col-12">
          <div className="row pb-2 align-items-center">
            <div className="col-3 pr-0 col-sm-4 logo-corporativo">
              <img
                src={imgLogo}
                width="90%"
                className="img-fluid"
                alt="RedBlu Logo"
              />
            </div>
            <div className="col-9 col-sm-8 text-right pacienteCuenta">
              <div className="accountDetails">
                <span className="rutAccount">{user.rut}</span>
                <IconButton aria-label="delete" onClick={handleLogout}>
                  <ExitToApp className="color-danger" />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <div className="enter-wait-room mb-2 p-3">
          <div className="py-2 text-center">
            <h1>Actualiza tus <strong>datos</strong></h1>
          </div>
          <div className="content-container">
            <div className="middle-center-content">
              <form onSubmit={onSubmit}>
                <div className="bodycontent">
                  <div className="accountform">
                    <div className="row">
                      <div className="col-sm-6 col-12 mb-3">
                        <label>Nombre</label>
                        <input name="nombre" value={inputValues.nombre} onChange={handleChangeInputText} required type="text" />
                      </div>
                      <div className="col-12 col-sm-6 mb-3">
                        <label>Apellido</label>
                        <input name="apellido" value={inputValues.apellido} onChange={handleChangeInputText} required type="text" />
                      </div>
                      <div className="col-12 col-sm-6 mb-3">
                        <label htmlFor="rut">Rut</label>
                        <div className="input-group">
                          <input
                            type="text"
                            style={{ backgroundColor: 'initial' }}
                            className={isRutValid ? "" : "is-invalid"}
                            id="rut"
                            name="rut"
                            disabled
                            value={inputValues.rut}
                          />
                          <div className="invalid-feedback">
                            Rut inválido.
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 mb-3">
                        <label>Teléfono <small>(ej: 9 dígitos)</small></label>
                        <input name="telefono" value={inputValues.telefono || ''} onChange={handlePhoneNumber} required type="tel" />
                      </div>

                      <div className="col-12 mb-3">
                        <label>Email</label>
                        <input name="email" value={inputValues.email} onChange={handleInputChange} required type="email" placeholder="tucorreo@gmail.com" />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Repetir email</label>
                        <input name="emailConfirm" value={inputValues.emailConfirm} onChange={handleInputChange} required type="email" placeholder="tucorreo@gmail.com" />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="heading">
                          <div className="py-2 text-center">
                            <p>Si necesitas actualizar tu contraseña completa los siguientes campos de lo contrario déjalos en blanco.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-3">
                        <label>Contraseña actual</label>
                        <input name="password_old" onChange={handleInputChange} type="password" />
                      </div>
                      <div className="col-12 mb-3">
                        <label>Nueva contraseña</label>
                        <input name="password1" onChange={handleInputChange} type="password" />
                      </div>
                      <div className="col-12 mb-5">
                        <label>Repetir Nueva contraseña</label>
                        <input name="password2" onChange={handleInputChange} type="password" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="actioncontent">
                  <div className="row mt-3">
                    <div className="col-6 col-sm-8 text-left">
                      <input type="submit" className="full-width" value={guardando ? "Guardando" : "Guardar"} disabled={guardando} />
                    </div>
                    <div className="col-6 col-sm-4 text-right">
                      <input type="button" value="Volver" onClick={() => { history.goBack() }} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
              <div className="alertModal">
                <h1>{alertModalText}</h1>
                <div className="text-center">
                  <button type="button" className="btn btn-submit btn-lg" onClick={() => { setAlertModalIsOpen(false); onAceptModalFunction() }}>Aceptar</button>
                </div>
              </div>
            </Modal>
          </div >
        </div>
        <div className="footerPush"></div>
      </div>
    </div>
  );
}

export default EditAccount;