import chromeLogo from './images/chrome.svg';
import edgeLogo from './images/edge.svg';
import firefoxLogo from './images/firefox.svg';
import safariLogo from './images/safari.svg';
import samsungLogo from './images/samsung-internet.svg';

const NavegadorIncompatible = () => {
  return (
    <div>
      <div className="title mb-4">
        <span className="pb-2">Guía de configuración</span>
        <h1>Navegador Incompatible</h1>
      </div>

      <h2>Descripción</h2>
      <div className="section border-top pt-3">
        <p>
          El navegador con el cúal esta accediendo no es compatible con las
          necesidades que requiere la plataforma para su correcto
          funcionamiento.
        </p>
      </div>

      <h2>Solución:</h2>
      <div className="section border-top pt-3">
        <p className="mb-4">
          Ingresa a la plataforma mediante uno de los siguientes navegadores
          soportados
        </p>

        <div className="mb-4">
          <h2>Computador</h2>
          <ComputadorGuide />
        </div>
        
        <div className="mb-4">
          <h2>Android / Tablet</h2>
          <AndroidGuide />
        </div>

        <div>
          <h2>iPhone / iPad</h2>
          <IPhoneGuide />
        </div>
      </div>
    </div>
  );
};

export default NavegadorIncompatible;

const ComputadorGuide = () => {
  return (
    <table width="100%">
      <thead>
        <tr>
          <th align="left" width="30%">Sistema</th>
          <th align="left" width="20%">Navegador</th>
          <th align="left" width="50%">Descripción</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left" rowspan={4}>
            <h4>macOS</h4>
            <small>desde MacOS 14<br/>en adelante</small>
          </td>
          <td align="center">
            <img src={chromeLogo} alt="Chrome" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Google Chrome</h3>
            <p>Descarga Chrome desde <a href="https://www.google.com/intl/en/chrome/" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
        <tr>
          <td align="center">
            <img src={firefoxLogo} alt="Firefox" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Firefox</h3>
            <p>Descarga Firefox desde <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
        <tr>
          <td align="center">
            <img src={safariLogo} alt="Safari" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Safari</h3>
            <p>Descarga Safari desde <a href="https://support.apple.com/en-hk/HT201541" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
        <tr className="doble-border">
          <td align="center">
            <img src={edgeLogo} alt="Edge" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Edge</h3>
            <p>Descarga Edge desde <a href="https://microsoft.com/edge" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>

        <tr>
          <td align="left" rowspan={3}>
            <h4>Windows</h4>
            <small>desde Windows 7<br/>en adelante</small>
          </td>
          <td align="center">
            <img src={chromeLogo} alt="Chrome" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Google Chrome</h3>
            <p>Descarga Chrome desde <a href="https://www.google.com/intl/en/chrome/" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
        <tr>
          <td align="center">
            <img src={firefoxLogo} alt="Firefox" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Firefox</h3>
            <p>Descarga Firefox desde <a href="https://www.mozilla.org/firefox/new/" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
        <tr className="doble-border">
          <td align="center">
            <img src={edgeLogo} alt="Edge" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Edge</h3>
            <p>Descarga Edge desde <a href="https://microsoft.com/edge" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>

        <tr className="doble-border">
          <td align="left">
            <h4>ChromeOS</h4>
          </td>
          <td align="center">
            <img src={chromeLogo} alt="Chrome" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Google Chrome</h3>
            <p>Descarga Chrome desde <a href="https://www.google.com/intl/en/chrome/" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const AndroidGuide = () => {
  return (
    <table width="100%">
      <thead>
        <tr>
          <th align="left" width="30%">Sistema</th>
          <th align="left" width="20%">Navegador</th>
          <th align="left" width="50%">Descripción</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left" rowspan={3}>
            <h4>Android</h4>
            <small>Equipos móviles con sistema android</small>
          </td>
          <td align="center">
            <img src={chromeLogo} alt="Chrome" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Google Chrome</h3>
            <p>Descarga Chrome desde <a href="https://play.google.com/store/apps/details?id=com.android.chrome&hl=es_CL&gl=US" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
        <tr>
          <td align="center">
            <img src={firefoxLogo} alt="Firefox" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Firefox</h3>
            <p>Descarga Firefox desde <a href="https://play.google.com/store/apps/details?id=org.mozilla.firefox&hl=es_CL&gl=US" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
        <tr className="doble-border">
          <td align="center">
            <img src={samsungLogo} alt="Samsung" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Samsung</h3>
            <p>Descarga Samsung desde <a href="https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser&hl=es_CL&gl=US" target="_blank" rel="noreferrer">aquí</a></p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const IPhoneGuide = () => {
  return (
    <table width="100%">
      <thead>
        <tr>
          <th align="left" width="30%">Sistema</th>
          <th align="left" width="20%">Navegador</th>
          <th align="left" width="50%">Descripción</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align="left" rowspan={1}>
            <h4>iPhone / iPad</h4>
            <small>Equipos móviles con sistema iOS</small>
          </td>
          <td align="center">
            <img src={safariLogo} alt="Safari" width="40" height="40" />
          </td>
          <td align="left">
            <h3>Safari</h3>
            <p>No requiere descarga ya que viene instalado en el equipo.</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}