import React ,{ useState } from "react";
import { Link } from "react-router-dom";
import ItemListaActividad from "./ItemListaActividad";
import ProgresoHoy from "./ProgresoHoy";

import moment from "moment-timezone";
import "moment/locale/es";
import { ResumenSemanal } from "./ResumenSemanal";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Modal from "react-modal";

import imgEmptyList from "./../../assets/images/iconos/taskEmpty.svg";
import sadImage from "./../../assets/images/icon5.svg";

var hoy = moment().tz("America/Santiago");
const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "-1%",
    bottom: "auto",
    marginRight: "0%",
    transform: "translate(-50%, -50%)",
  },
};
const ActividadesDiarias = ({
  notifications,
  notificationsWeek,
  patientId,
  onChangeNotification,
  onChangeReminder,
  fechaIniWeek,
  fechaFinWeek,
  messagesCount
}) => {
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const handleModal = (e) => {
    e.preventDefault();
    setAlertModalIsOpen(true);
  };
  const closeModal = (e) => {
    e.preventDefault();
    setAlertModalIsOpen(false);
  };
  return (
    <>
      <div className="home-top-nav container-fluid animated fadeIn fast ">
        <div className="header-nav-spacing mb-2">
          <div className="row row-today">
            <div className="col-12 page-name border-bottom pb-2">
              <h1>{hoy.format("LL")}</h1>
              <small className="">Mis actividades</small>
            </div>
          </div>
        </div>
        <div className="actividades-diarias ">
          {notifications.length > 0 && (
            <div className="col-12 pt-1 mb-3 progreso-diario">
              <ProgresoHoy
                totalActividades={notifications.length}
                totalCumplidas={notifications.reduce(
                  (total, noti) => (noti.completed ? ++total : total),
                  0
                )}
              />
            </div>
          )}
          <div className="col-12">
            <PerfectScrollbar style={{ maxHeight: "45vh" }}>
              {notifications.map((recordatorio, indice) => {
                return (
                  <ItemListaActividad
                    key={indice}
                    recordatorio={recordatorio}
                    patientId={patientId}
                    onChangeNotification={onChangeNotification}
                    //TODO acordar inputs con Pedro
                    nombreActividad={recordatorio.data.activityName}
                    horaAsignada={recordatorio.dateTime}
                    completada={recordatorio.completed}
                    categoria={recordatorio.categoria}
                    onChangeReminder={onChangeReminder}
                    dayId={recordatorio.dayId}
                  />
                );
              })}
            </PerfectScrollbar>
            {notifications.length === 0 && (
              <div className="pl-4 pr-4 pt-0 pb-2 text-center sin-actividades">
                <img
                  src={imgEmptyList}
                  alt="No hay actividades"
                  className="pb-2 full-width height40"
                />
                <h3>No hay actividades asignadas el día de hoy</h3>
                <p>
                  Es un buen momento para aprovechar de explorar los recursos
                </p>
                <div className="row">
                  <div className="col-12 text-center">
                    <Link to="/paciente/recursos">
                      <button className="btn btn-submit">
                        VISITAR RECURSOS
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <div className="alertModal text-center text-md-right mt-3">
              <button
                type="button"
                className="btn btn-submit"
                onClick={handleModal}
              >
                Resumen Semanal
              </button>
            </div>
            <Modal
              isOpen={alertModalIsOpen}
              style={customModalStyles}
              onRequestClose={() => setAlertModalIsOpen(false)}
            >
              <button
                className="btn btn-danger float-right "
                onClick={closeModal}
              >
                Cerrar
              </button>

            {notificationsWeek.length > 0 ? (
                <ResumenSemanal
                  notificationsWeek={notificationsWeek}
                  fechaIniWeek={fechaIniWeek}
                  fechaFinWeek={fechaFinWeek}
                  messagesCount={messagesCount}
                />
              ) : (
                  <div className="d-flex justify-content-center mx-auto  align-items-center" style={{ height: '180px' }}>
                    <h3 className="titulo-recurso">
                        No hay actividades disponibles para esta semana
                      <img src={sadImage} width="50" alt="No hay actividades" />
                    </h3>
                </div>
                  
              )}
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActividadesDiarias;
