import configEnv from "./configEnv";

const URLS = {
  baseURL: `${configEnv.ENV_APP_SOCKET_URL}api`,
  baseSocketURL: `${ configEnv.ENV_APP_SOCKET_URL }`,
  baseURLGateway: `${configEnv.ENV_GATEWAY_SOCKET_URL}`,
  authorizationApi: `${configEnv.ENV_API_GATEWAY_USER}`,
  resources: '/resources',
  patients: '/patients',
  login: '/login',
  agoraDynamicKey: '/agoraDynamicKey',
  subscriptions: '/subscriptions',
  vattentions: '/vattentions',
  tokenredgesam: '/tokenredgesam',
  password: '/password',
  termsAndConditions: '/custom',
  polls: '/polls',
  activity: '/activity',
  completed: '/completed',
  dates: '/dates',
  all: '/all',
  commentaries: '/commentaries',
  monitorUser: '/monitorUser',
  wpaccount: '/wpaccount',
  medics: '/medics',
  chatRoom: '/chatRoom',
  wordpress: '/wp',
  profesional: '/profesional',
  woocommerce:'/woocommerce',
  APIgateWay: process.env.REACT_APP_GATEWAY_SOCKET_URL
};

export default URLS;
