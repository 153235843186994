import React,{useState} from 'react';
import {ExpandMore, ExpandLess} from '@material-ui/icons';

const Accordion = (props) => {
    const[active, setActive] = useState(false);

    const activeAccordeon = () => {
        setActive(!active);
    };

    return(
        <div className="card ayuda-permisos mb-2">
            <div className="card-header" id={'heading'+props.id}>
                <button onClick={activeAccordeon} class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={"#"+props.id} aria-expanded="false" aria-controls={props.id}>
                {props.titulo}
                {props.descripcion &&
                    <small>{props.descripcion}</small>
                }
                {active? <ExpandLess /> : <ExpandMore /> } 
                </button>
            </div>
            <div id={props.id} class="collapse" aria-labelledby={'heading'+props.id} data-parent="#accordionExample">
                <div className="card-body">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Accordion;
