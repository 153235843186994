import React, { useState } from 'react';
import { Box, Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { getBase64 } from '../../helpers/GetBase64Helper';
import ProfesionalService from '../../../../services/ProfesionalService';

import Modal from 'react-modal';
import customModalStyles from '../../hooks/customModalStyles';
import { Skeleton } from '@material-ui/lab';

interface Props {
  title?: string;
  name: string;
  placeholder?: string;
  altImg?: string;
  onChange: (event: any) => void;
  setFieldValue: (name: string, value: any) => void;
  setFieldError: (name: string, value?: any) => void,
  setFieldTouched: (name: string, isTouched?: boolean, shouldValidate?: boolean) => void,
  touched?: any;
  error?: string;
  value?: string;
  idProducto?: number;
  [x: string]: any; // puede resibir cualquier cosa
}



export const InputFileComponent = ({
  title,
  altImg,
  type,
  placeholder,
  value,
  idProducto,
  onChange,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  name,
  dataProfesionalTemp,
  setDataProfesionaTemp,
  ...props
}: Props) => {
  const [guardando, setGuardando] = useState(false);
  // ======================================
  // Modals
  // ======================================
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");

  const [base64, setBase64] = useState('');
  const handleChangeFile = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    getBase64(file)
      .then(base64 => {
        setBase64(base64);
        setFieldValue(
          name,
          { name: file.name, url: base64.split(',')[1], alt: file.name}
        );
        setFieldError(name);
        const getCambiaImagen = async () => {
          try {
            setGuardando(true);
            const res = await ProfesionalService.actualizaImagen(idProducto, {
              file: {
                fileName: file.name.split('.')[0],
                fileExtension: file.type.split('/')[1],
                fileData: base64.split(',')[1],
              }
            });
            setDataProfesionaTemp({
              nombre: dataProfesionalTemp.nombre,
              titulo: dataProfesionalTemp.titulo,
              imagen: res.data.imageUploadResponse.url,
            })
            setAlertModalText("Imagen actualizada correctamente.");
            setAlertModalIsOpen(true);
            setGuardando(false);
          } catch (error) {
            setAlertModalIsOpen(false);
            console.log('error : ', error)
            setGuardando(false);
          }
        }

        getCambiaImagen();


      })
      .catch(error => {
        setFieldError(
          name,
          { fileContent: "No se pudo convertir el archivo seleccionado." }
        );
      });

  };



  return (
    <>
      <label htmlFor="dataProfesional"
        className="text-label-profesional">{title ? title : ''}
      </label>
   
        {
          guardando
          ? <Grid container className="mt-2 mb-3 text-center abs-center-10"  >
              <Skeleton variant="rect" width={120} height={100} className="img-profesional mt-1" />
              <Typography variant="caption" component="div" color="primary">
                Guardando información...
                <LinearProgress />
              </Typography>
            </Grid>
          : <div className="mt-3 mb-3 position-relative text-center"><img
              src={base64 ? base64 : value}
              alt={altImg}
              className="  img-profesional"
            />
          </div>
        }
  

      <div className="input-group mb-3 rounded p-0">
          <input
              accept="image/*"
              className={"d-none"}
              id="contained-button-file"
              multiple
              type="file"
              name={name}
              onChange={handleChangeFile}
            />
      
        <label htmlFor="contained-button-file"> 
          <Button
            variant="contained"
            color="primary"
            component="span"
            className="input-group w-100"
           
          >
            <input className="form-control w-100 pointer-events-none cursor-not-allowed " />
            <span className=" pl-3 pt-2 pb-2 pr-3 ">Cargar</span>

          </Button>
         </label>
      </div>
      < Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
        <div className="alertModal">
          <h1>{alertModalText}</h1>
          <div className="text-center">
            <button type="button" className="btn btn-submit btn-lg" onClick={() => { setAlertModalIsOpen(false) }}>Aceptar</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default InputFileComponent;