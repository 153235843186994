import API from "./api";
import URLS from '../URLS';

const chatServices = {
  /**
     * @function getChatRooms Obtiene las citas que el paciente o profesional tienen en el día.
     * @param tipoUsuario paciente o profesional
     * @param usuarioId ID del usuario
     * @returns Listado de citas para el día
  */
  getChatRooms: function (tipo, user) {
    return API.axios({
      method: 'get',
      url: URLS.chatRoom,
      // headers: { 'Authorization': 'Bearer ' + store.loguedUser.token },
      headers: { 'Authorization': 'Bearer ' },
      params: {
        tipo,
        user
      },
    });
  },

  /**
     * @function getChatRoom Obtiene las citas que el paciente o profesional tienen en el día.
     * @param chatRoomID object _id chatroom
     * @returns chatroom
  */
  getChatRoom: function (id) {
    return API.axios({
      method: 'get',
      url: URLS.chatRoom + `/${id}`,
    });
  },


  /**
     * @function updateChatRoom Guarda un nuevo mensaje en la sala indicada
     * @param chatRoomID object _id chatroom
     * @param user rut del usuario que ingresa el mensaje
     * @param message mensaje enviado por el usuario
     * @returns chatroom actualizado
  */

  updateChatRoom: function (id, data) {
    return API.axios({
      method: 'put',
      url: URLS.chatRoom + `/${id}`,
      data: data
    });
  },

  /**
     * @function newChatRoom Permite la creación de una nueva sala de chat para un profesional.
     * @param patientRut rut del paciente ha crear la sala
     * @param medicId ID del médico asociado que está intentando crear la sala
     * @returns chatroom ya creado o genera una nueva sala.
   */
  newChatRoom: function (data) {
    return API.axios({
      method: 'post',
      url: URLS.chatRoom,
      // headers: { 'Authorization': 'Bearer ' + store.loguedUser.token },
      headers: { 'Authorization': 'Bearer ' },
      data: data
    });
  },



};

export default chatServices;