import React, { useEffect, useState, useRef } from "react";
import ReactGA from 'react-ga';
let totalTime=0;
let diff;
const AudioActividad = ({ url, urlDescripcion }) => {
   let playTime;
   let stopTime;
   const [audioSrc, setAudioSrc] = useState("");
   const [tipo, setTipo] = useState("");
   const [audioStatus, setAudioStatus] = useState(-1);
   const [opacity, setOpacity] = useState(1);
   // console.log(url);
   const player = useRef();
   let audio_Id;
   useEffect(() => {
  

      //SpotiFy
      if (url.includes("open.spotify") && url.includes("track")) {
         setTipo("Spotify");
         let track = url.split('/track/') || url.split('/playlist/') ;
         setAudioSrc("https://open.spotify.com/embed/track/" + track[1]);
      } 

   }, [url]);

   return (
      <div className="actividadFondo">
         {
            tipo === "Spotify" ?
               <iframe
                  src={audioSrc}
                  width="100%"
                  height="380"
                  frameBorder="0"
                  allowtransparency="true"
                  allow="encrypted-media"
                  title={"audio-spotify"}
               ></iframe>
               : <iframe 
                     src={url}
                     width="100%"
                     height="380"
                     frameBorder="0"
                     allowtransparency="true"
                     allow="encrypted-media"
                     title={"audio"}
                  >

               </iframe>
         }
        
      </div>
   );
};

export default AudioActividad;
