import React from 'react';

const PermisoSinLectura = () => {
    return(
        <div>
            <div className="page-name mb-4">
                <h1>Oups!</h1>
                <p>No se puede acceder a la cámara o el micrófono</p>
            </div>
            <div className="homeRecomendations">
                <div className="row">
                <div className="col-12">
                    <div className="guiastep mt-4">
                        <div className="guiastep-title">
                            <h3>La cámara o el micrófono presenta una falla.</h3>
                        </div>
                        <div className="guiastep-content">
                            <p>No hemos podido acceder a la cámara y micrófono pareciera que una de ellas no está funcionando correctamente o algo nos ha bloqueado para poder utilizarlas.</p>
                            <p>Verifica en primer lugar que tanto la cámara como el micrófono están funcionando correctamente.</p>
                            <p>Si estás a la espera de una atención en los próximos minutos, te recomendamos que utilices <em>otro dispositivo (Celular o tablet)</em> ya que corregir este tipo de problemas te puede llevar varios minutos.</p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default PermisoSinLectura;