import browser from 'platform-detect/browser.mjs';
import system from 'platform-detect/os.mjs';

//Windows
import imgAccesoCamaraWindowsOne from './images/acceso-camara-windows-1.svg';
import imgAccesoCamaraWindowsTwo from './images/acceso-camara-windows-2.svg';
import imgAccesoCamaraWindowsThree from './images/acceso-camara-windows-3.svg';
import imgAccesoCamaraWindowsFour from './images/acceso-camara-windows-4.svg';

//Mac
import imgAccesoCamaraMacOne from './images/acceso-camara-mac-1.svg';
import imgAccesoCamaraMacTwo from './images/acceso-camara-mac-2.svg';
import imgAccesoCamaraMacThree from './images/acceso-camara-mac-3.svg';
import imgAccesoCamaraMacFour from './images/acceso-camara-mac-4.svg';
import imgAccesoCamaraMacFive from './images/acceso-camara-mac-5.svg';

const AccesoSistema = () => {

  return (
    <div>
      <div className="title mb-4">
        <span className="pb-2">Guía de configuración</span>
        <h1>El sistema ha bloqueado el acceso</h1>
      </div>

      <h2>Descripción</h2>
      <div className="section border-top pt-3">
        <p>Este problema ha ocurrido porque el sistema dentro de su configuración no tiene permitido el acceso a la cámara o micrófono y deberás conceder este permiso en la configuración de tu equipo.</p>
      </div>

      <h2>Solución:</h2>
      <div className="section border-top pt-3">
        <p className="mb-4">Sigue estas indicaciones para corregir el problema:</p>

        {system.windows &&
          <WindowsGuide />
        }

        {system.macos &&
          <MacOsGuide />
        }
      </div>
    </div>
  )
};

export default AccesoSistema;

const WindowsGuide = () => {
  return (
    <ol>
      <li>
        <p>Ve a la parte inferior y presiona el icono de <b>"Windows"</b> para desplegar el menú en donde deberás presionar la opción <b>"Configuración"</b></p>
        <img src={imgAccesoCamaraWindowsOne} alt="" />
      </li>

      <li>
        <p>Se desplegará un cuadro en donde deberás buscar la opción <b>"Privacidad"</b></p>
        <img src={imgAccesoCamaraWindowsTwo} alt="" />
      </li>

      <li>
        <p>Luego en el menú de la parte izquierda presiona la opción <b>"Cámara</b>. Dentro del contenido que aparecerá busca la sección que diga <b>"Permitir que las aplicaciones accedan a la cámara"</b> y presiona el botón <b>"Desactivado"</b> para que quede activado.</p>
        <img src={imgAccesoCamaraWindowsThree} alt="" />
      </li>

      <li>
        <p>En esa misma parte pero más abajo en la sección <b>"Permitir que las aplicaciones de escritorio accedan a la cámara"</b> verifica que el botón también diga <b>"activado"</b>.</p>
        <img src={imgAccesoCamaraWindowsFour} alt="" />
      </li>

      <li>
        <p>Repite el mismo proceso anterior pero para el micrófono.</p>
      </li>

      <li>
        <p>Finalmente recarga la página de teleconsulta para que los cambios se vean reflejados.</p>
      </li>
    </ol>
  )
};

const MacOsGuide = () => {
  return (
    <ol>
      <li>
        <p>Presiona el icono de manzana y luego la opción <b>Preferencias del sistema</b></p>
        <img src={imgAccesoCamaraMacOne} alt="" />
      </li>

      <li>
        <p>En el cuadro de díalogo que aparecerá presionar la opción <b>"Seguridad y privacidad"</b></p>
        <img src={imgAccesoCamaraMacTwo} alt="" />
      </li>

      <li>
        <p>Presionar la pestaña <b>"Privacidad"</b> y luego en la parte izquierda busca dentro de las opciones <b>"Cámara"</b> y <b>"Micrófono"</b></p>
        <img src={imgAccesoCamaraMacThree} alt="" />
      </li>

      <li>
        <p>Marca la casilla del navegador que quieras autorizar a que tenga acceso a la Cámara o Micrófono.</p>
        <img src={imgAccesoCamaraMacFour} alt="" />
      </li>

      <li>
        <p>Luego de que hayas realizado los ajustes recarga la página en el navegador para que los cambios tomen efecto.</p>
        <img src={imgAccesoCamaraMacFive} alt="" />
      </li>
    </ol>
  )
};