
import { gatewayService, gatewayServiceAuth } from "../../services/gateway";
import URLS from "../../URLS";
import { IdAttributes } from "../pages/EditarPerfil/hooks/IdAttributes";


export const ProfesionalService = {


  /**
   * 
   *  Servicio (GET) Datos Profesional 
   * @url          : {url_base}/api/v1/woocommerce/profesional/:id/data
   * @method       : 'GET'
   * @params       : id del profesional
   * @description  : obtiene información completa del profesional
   * 
  **/

  getDatosProfesional: function (profesionalId) {
    // Id atributos
    const { edadesAtencionId,
            casaEstudioId,
            especialidadDestacadaId,
            tecnicaUsadaTerapiaId,
            numeroSuperIntendenciaId,
            tituloProfesionalId,
            OtrasEspecialidadesId,
            AniosExpericiaId } = IdAttributes();

    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.woocommerce}${URLS.profesional}/${profesionalId}/data`,
      })
        .then((result) => {

          const d = result.data;

          const attributes = d.attributes;

          // Arreglos atributos
          let arrAniosExperiencia = [];
          let arrTituloProfesional = [];
          let arrCasaEstudio = [];

          let arrEspecialidadesDestacadas = [];
          let arrOtrasEspecialidades = [];
          let arrEdadesAtencion = [];
          let arrTecnicaUsadaTerapia = [];

          let arrNumSuperIntendencia = [];


          // eslint-disable-next-line array-callback-return
          attributes.map(({ id, name, options }) => {

            if (id === AniosExpericiaId) return arrAniosExperiencia = { id, name, options };
            if (id === tituloProfesionalId) return arrTituloProfesional = { id, name, options };
            if (id === casaEstudioId) return arrCasaEstudio = { id, name, options };

            if (id === especialidadDestacadaId) return arrEspecialidadesDestacadas = { id, name, options };
            if (id === edadesAtencionId) return arrEdadesAtencion = { id, name, options };
            if (id === OtrasEspecialidadesId) return arrOtrasEspecialidades = { id, name, options };
            if (id === tecnicaUsadaTerapiaId) return arrTecnicaUsadaTerapia = { id, name, options };

            if (id === numeroSuperIntendenciaId) return arrNumSuperIntendencia = { id, name, options };

          });

          const images = d.images;

          resolve({
            name: d.name,
            id: d.id,
            price: d.price,
            description: d.description,
            short_description: d.short_description,
            duration: d.duration,
            images: images.map(({ id, alt, src, name }) => { return { id, alt, src, name } }),
            categories: d.categories,
            // Selectores
            optionAniosExperiencia: arrAniosExperiencia,
            optionTituloProfesional: arrTituloProfesional,
            optionCasaEstudio: arrCasaEstudio,
            optionEspecialidadesDestacadas: arrEspecialidadesDestacadas,
            optionOtrasEspecialidades: arrOtrasEspecialidades,
            optionEdadesAtencion: arrEdadesAtencion,
            optionTecnicaUsadaTerapia: arrTecnicaUsadaTerapia,

            numeroSuperintendencia: arrNumSuperIntendencia,
          }
          );


        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *  Servicio (GET) titulo profesional + Imagen
   * @url          : {url_base}/api/v1/woocommerce/profesional/:id/nameTitleImage
   * @method       : 'GET'
   * @params       : id del profesional
   * @description  : obtiene el nombre, titulo e imagen del profesional desde wordpress
   * 
  **/

  getTituloImagenProfesional: function (profesionalId) {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.woocommerce}${URLS.profesional}/${profesionalId}/nameTitleImage`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *  Servicio (GET) casa de estudio
   * @url          : {url_base}/api/v1/woocommerce/profesional/casa-de-estudio
   * @method       : 'GET'
   * @params       : no recibe parametros
   * @description  : obtiene listado de casa de estudio del profesional
   * 
  **/

  getCasaEstudio: function () {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.baseURLGateway}${URLS.woocommerce}/casa-de-estudio`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *  Servicio (GET) otras especialidades
   * @url          : {url_base}/api/v1/woocommerce/profesional/otras-especialidades
   * @method       : 'GET'
   * @params       : no recibe parametros
   * @description  : obtiene listado de otras especialidades del perfil del profesional
   * 
  **/

  getOtrasEspecialidades: function () {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.baseURLGateway}${URLS.woocommerce}/otras-especialidades`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },


  /**
   *  Servicio (GET) especialidad destacada
   * @url          : {url_base}/api/v1/woocommerce/profesional/especialidad-destacada
   * @method       : 'GET'
   * @params       : no recibe parametros
   * @description  : obtiene listado de especialidades destacadas del perfil del profesional
   * 
  **/

  getEspecialidadesDestacadas: function () {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.baseURLGateway}${URLS.woocommerce}/especialidad-destacada`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *  Servicio (GET) edades de atención
   * @url          : {url_base}/api/v1/woocommerce/profesional/edades-atencion
   * @method       : 'GET'
   * @params       : no recibe parametros
   * @description  : obtiene listado de edades de atención del perfil del profesional
  **/


  getEdadesAtencion: function () {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.baseURLGateway}${URLS.woocommerce}/edades-atencion`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },



  /**
   *  Servicio (GET) titulo profesional
   * @url          : {url_base}/api/v1/woocommerce/profesional/titulo-profesional
   * @method       : 'GET'
   * @params       : no recibe parametros
   * @description  : obtiene listado de titulo profesional del perfil del profesional
  **/


  getTituloProfesional: function () {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.baseURLGateway}${URLS.woocommerce}/titulo-profesional`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },





  /**
   *  Servicio (GET) tecnicas de terapia
   * @url          : {url_base}/api/v1/woocommerce/profesional/tecnicas-terapia
   * @method       : 'GET'
   * @params       : no recibe parametros
   * @description  : obtiene listado de tecnicas-terapia del perfil del profesional
   * 
   * 
  **/
  getTecnicaTerapia: function () {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.baseURLGateway}${URLS.woocommerce}/tecnicas-terapia`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *  Servicio (GET) Años de erxperiencia
   * @url          : {url_base}/api/v1/woocommerce/profesional/anios-experiencia
   * @method       : 'GET'
   * @params       : no recibe parametros
   * @description  : obtiene listado de anios-experiencia del perfil del profesional
   * 
   * 
  **/
  getAniosExperiencia: function () {
    return new Promise((resolve, reject) => {
      gatewayService.axios({
        method: 'get',
        url: `${URLS.baseURLGateway}${URLS.woocommerce}/anios-experiencia`,
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },




  /**
   *  Servicio (PUT) Actualiza minutos + categoria
   * @url          : {url_base}/api/v1/woocommerce/profesional/:idProducto/updateProfessionalAttributes
   * @method       : 'PUT'
   * @params       : no recibe parametros
   * @description  : actualiza minutos y categoria
  **/
  actualizaMinutosCategoria: function (idProducto) {
    return gatewayServiceAuth.axios({
      method: "put",
      url: `${URLS.woocommerce}${URLS.profesional}/${idProducto}/updateProfessionalAttributes`,
    });
  },


  /**
   *  Servicio (PUT) Actualiza Imagen
   * @url          : {url_base}/api/v1/woocommerce/profesional/7143/updateProfessionalImage
   * @method       : 'PUT'
   * @params       : recibe id de producto y data
   * @description  : actualiza imagen del profesional
  **/
  actualizaImagen: function (idProducto, data) {
    return gatewayServiceAuth.axios({
      method: "put",
      url: `${URLS.woocommerce}${URLS.profesional}/${idProducto}/updateProfessionalImage`,
      data: data,
    });
  },



  /**
   *  Servicio (PUT) Actualiza profesional completo
   * @url          : {url_base}/api/v1/woocommerce/profesional/7143/updateProfessionalAttributes
   * @method       : 'PUT'
   * @params       : recibe id de producto y data
   * @description  : actualiza profesional completo
  **/

  actualizaProfesionalCompleto: function (idProducto, data) {
    return gatewayServiceAuth.axios({
      method: "put",
      url: `${URLS.woocommerce}${URLS.profesional}/${idProducto}/updateProfessionalAttributes`,
      data: data
    });
  },




};

export default ProfesionalService;
