import imgOmitirPermiso from './images/omitir-permiso-microfono-windows.svg';

const PermisoMicrofonoOmitido = () => {

  return(
    <div>
      <div className="title mb-4">
        <span className="pb-2">Guía de configuración</span>
        <h1>Permiso del Micrófono ha sido Omitido</h1>
      </div>
      
      <h2>Descripción</h2>
      <div className="section border-top pt-3">
        <p>Este problema ha ocurrido porque no has presionado el botón de "permitir" al momento en que se ha realizado la solicitud para acceder al micrófono. La plataforma requiere poder acceder tanto a la cámara y micrófono para hacer uso de ella al momento de la teleconsulta.</p>
      </div>
      
      <h2>Solución:</h2>
      <div className="section border-top pt-3">
        <p className="mb-4">Sigue estas indicaciones para corregir el problema:</p>
        <ComponentGuide />
      </div>
    </div>
  )
};

export default PermisoMicrofonoOmitido;

const ComponentGuide = () => {
  return(
    <ol>
      <li>
        <p>Cada vez que aparezca este cuadro debes presionar <b>"permitir"</b></p>
        <img src={imgOmitirPermiso} alt="" />
      </li>
      <li>
        <p>Presiona el botón <b>"Volver"</b> y recarga la página para que el cuadro vuelva a aparecer.</p>
      </li>
    </ol>
  )
};