export const UserReducer = (state, action) => {

  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload
      }
    }
    case 'SET_DATA': {
      return {
        ...action.payload
      }
    }
    case 'SET_LOGGED': {
      return {
        ...state,
        isLogged: action.payload
      }
    }
    case 'SET_ACCESS_TOKEN': {
      return {
        ...state,
        accessToken: action.payload
      }
    }
    case 'SET_WAITING_ROOM': {
      return {
        ...state,
        isWaitingRoom: action.payload
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}