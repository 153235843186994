import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import AssignmentServices from "../../services/assignment";
import moment from 'moment-timezone';

// Componentes
// import { ResumenSemanal } from "../../Patient/Assignments/ResumenSemanal";
import { ResumenDiarioRecursos } from "../components/informacion/ResumenDiarioRecursos";
import { HistoricoActividades } from "../components/historico/HistoricoActividades";
import 'react-datepicker/dist/react-datepicker.css';

// Images
// import imgEmptyList from '../../mobil/assets/images/iconos/taskEmpty.svg'
import { customModalStyles } from "../utils/Modal";
import { getSocketClient } from "../../services/socket";
// import Loading from "../../components/Loading";

export const FetchgetRemindersByDates = ({
  idPaciente,
  fechaIniWeek,
  fechaFinWeek,
  tipo,
  renderSaved,
  render,
  closeModal,
  filtroFechas,
  state,
  setState,
}) => {

  const [notificationsWeek, setNotificationsWeek] = useState([]);
  const [notificationsResumen, setNotificationsResumen] = useState([]);
  const [id, setId] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState(null);
  const [alertSuccess, setAlertSuccess] = useState(false);
  // const [saved, setSaved] = useState(false);

  let arregloNotificaciones = [];
  let arregloNotificacionesResumen = [];


  useEffect(() => {

    const fetchNotifications = async () => {

      try {

        const paramsWeeek = {
          startOfPeriod: moment(fechaIniWeek.toDate()).subtract(0, 'days').hour(0).minute(0).seconds(0).format("YYYY-MM-DD"),
          endOfPeriod: moment(fechaFinWeek.toDate()).add(7, 'days').hour(23).minute(59).seconds(59).format("YYYY-MM-DD"),
        };

        // Llamada asíncrona del servicio
        await AssignmentServices.getRemindersByDates(
          idPaciente,
          paramsWeeek.startOfPeriod,
          paramsWeeek.endOfPeriod
        )

          .then(async ({ data }) => {

            if (Array.isArray(data)) {
              data.map(
                ({
                  _id,
                  assignmentDate,
                  days,
                  data,
                  patient,
                  isActive,
                  resource,
                  updatedAt,
                  startOfPeriod,
                  timesInTheDay,
                  endOfPeriod
                }) => {

                  let reminderObj = {};
                  let reminderObjResumen = {};
                  // console.log(moment.utc(assignmentDate))
                  // objeto utilizado en resumen semanal
                  days.map((day, i) => {

                    reminderObj = {
                      reminderId: _id,
                      assignmentDate: moment(assignmentDate).utc(),
                      notificationCategory: data.typeLink,
                      resource: resource,
                      data: data,
                      dayId: days[i]._id,
                      dateTime: moment.utc(days[i].dateTime),
                      completed: days[i].completed,
                      patient,
                      isActive,
                      updatedAt: moment(updatedAt).utc(),
                      startOfPeriod: moment(startOfPeriod).utc(),
                      endOfPeriod: moment(endOfPeriod).utc(),
                      timesInTheDay,
                    };
                    return arregloNotificaciones.push(reminderObj);
                  });
                  // Objeto para resumen de recursos diarios
                  reminderObjResumen = {
                    reminderId: _id,
                    activityName: <span className="wordBreak">{data.activityName}</span>,
                    assignmentDate: <span>{moment(assignmentDate).utc().format("L")}</span>,
                    endOfPeriod: <span>{moment(endOfPeriod).utc().format("L")}</span>,
                    startOfPeriod: <span>{moment(startOfPeriod).utc().format("L")}</span>,
                    pacientInstructions: !!data.pacientInstructions ? <span className="wordBreak p-0">{data.pacientInstructions.substring(0, 80)}</span> : "",
                    actions: <button
                      className='btn-rounded btn-sm small  p-1 wordBreak'
                      onClick={() => setId(_id)}
                    >
                      Eliminar
                    </button>
                  };

                  return arregloNotificacionesResumen.push(reminderObjResumen);

                }
              );
            }


          })
          .catch((err) => {
            arregloNotificaciones.push([]);
            console.log("error : ", err);
          });

      } catch (error) {
        console.log(error)
      }

      // Orden descendentes de las actividades
      arregloNotificaciones.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      setNotificationsWeek(arregloNotificaciones);

      arregloNotificacionesResumen.sort(function (a, b) {
        return new Date(b.assignmentDate) - new Date(a.assignmentDate);
      });
      setNotificationsResumen(arregloNotificacionesResumen);

    };

    fetchNotifications();


  }, [fechaIniWeek, fechaFinWeek, renderSaved]);


  useEffect(() => {

    // FUNCIÓN ELIMINAR
    const handleEliminar = async (id) => {
      try {
        if (id) {
          setAlertModalText(`Registro Eliminado con éxito`);
          setAlertModalIsOpen(true);
          setAlertSuccess(true);

          await AssignmentServices.deleteRerminderByPatientID(id);
          console.log('eliminado con éxito')


          // console.log(notificationsResumen)
          const arrayFiltrado = notificationsResumen.filter(item => { return item.reminderId !== id });
          setNotificationsResumen(arrayFiltrado);
          setUpdated(true);
          render(new Date());
          try {
            let socket = getSocketClient();
            // console.log(socket);
            socket.emit('assignment', { type: 'assignment', value: arrayFiltrado.length }, () => console.log('mensajeEmit'));
          } catch (error) {
            console.log(error);
          }
        }

      } catch (error) {
        console.log(error);
      }
    }
    handleEliminar(id);

  }, [id])




  return (
    <>
      { (tipo === "Historico" ) ? (
          <HistoricoActividades
            notificationsWeek={notificationsWeek}
            fechaIniWeek={fechaIniWeek}
            fechaFinWeek={fechaFinWeek}
            renderSaved={renderSaved}
            updated={updated}
            closeModal={closeModal}
            filtroFechas={filtroFechas}
            state={state}
            setState={setState}
          />
      ) : 
      null
      }

      {tipo === "ResumenDiarioRecursos" && notificationsResumen.length > 0 && (
        <ResumenDiarioRecursos
          notificationsWeek={notificationsResumen}
          reminderId={id}
          renderSaved={renderSaved}
          updated={updated}
        />
      )}

      <Modal
        isOpen={alertModalIsOpen}
        style={customModalStyles}
        onRequestClose={() => setAlertModalIsOpen(false)}
      >
        <div className="alertModal">
          <h1 className={alertSuccess ? "text-tele" : "text-dark"}>
            {alertModalText}
          </h1>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-submit btn-lg"
              name="aceptar"
              onClick={() => setAlertModalIsOpen(false)}
            >
              Aceptar
              </button>

          </div>
        </div>
      </Modal>
    </>
  );
};
