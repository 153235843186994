import { ILocalVideoTrack, IRemoteVideoTrack, ILocalAudioTrack, IRemoteAudioTrack } from "agora-rtc-sdk-ng";
import React, { useRef, useEffect, useState } from "react";
import { VideocamOff , Videocam, MicOff, Mic } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import './MediaPlayer/MediaPlayer.css';
import alertaState from './Alertas/alertaState';
import LinearProgress from '@material-ui/core/LinearProgress';
import SpeakerIcon from '@material-ui/icons/Speaker';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  isRemoteTrack: Boolean | undefined;
  rol: string | undefined;
  join: Boolean | undefined;
  audioLevel:number| undefined;
}

const MediaPlayer = (props: VideoPlayerProps) => {
  const container = useRef<HTMLDivElement>(null);
  const [ isMicMuted, setIsMicMuted ] = useState(true);
  const [isVidMuted, setIsVidMuted] = useState(true);
  const [audioLvl, setAudioLvl] = useState(0);
  
  
  useEffect(() => {
    props.audioTrack && props.audioTrack.play();
    if(props.audioTrack){
      setIsMicMuted(false);
      // if(props.isRemoteTrack){
      //   alertaState.alerta.setAlerta({
      //     mensaje: `El ${props.rol === 'paciente' ? 'profesional' : 'paciente'} ha activado el micrófono`,
      //     tipo: 'success'
      //   });
      // }
    }else{
      setIsMicMuted(true);
      if(props.isRemoteTrack){
        alertaState.alerta.setAlerta({
          mensaje: `El ${props.rol === 'paciente' ? 'profesional' : 'paciente'} ha desactivado el micrófono`,
          tipo: 'info'
        });
      }
    }

    return () => {
      props.audioTrack && props.audioTrack.stop();
      if(props.audioTrack){
        setIsMicMuted(true);
      }else{
        setIsMicMuted(false);
      }
    };
  }, [props.audioTrack, props.isRemoteTrack, props.rol]);

  useEffect(() => {
    if (!container.current) return;
    props.videoTrack && props.videoTrack.play(container.current);
    if(props.videoTrack){
      setIsVidMuted(false);
      // if(props.isRemoteTrack){
      //   alertaState.alerta.setAlerta({
      //     mensaje: `El ${props.rol === 'paciente' ? 'profesional' : 'paciente'} ha activado la cámara`,
      //     tipo: 'success'
      //   });
      // }
    }else{
      setIsVidMuted(true);
      if(props.isRemoteTrack){
        alertaState.alerta.setAlerta({
          mensaje: `El ${props.rol === 'paciente' ? 'profesional' : 'paciente'} ha desactivado la cámara`,
          tipo: 'info'
        });
      }
    }

    return () => {
      props.videoTrack && props.videoTrack.stop();
      if(props.videoTrack){
        setIsVidMuted(true);
      }else{
        setIsVidMuted(false);
      }
    };
  }, [container, props.isRemoteTrack, props.rol, props.videoTrack]);


  useEffect(() => {
    if (props.audioLevel) {
      // let nivelAudio = Math.trunc(props.audioLevel * 2);
      let nivelAudio = (parseInt(dosDecimales(props.audioLevel)) / 10);
      // console.log('nivelAudio', nivelAudio);

      if (nivelAudio <= 2) {
        setAudioLvl(nivelAudio);
      } else {
        setAudioLvl(1);
      }
    } else {
      setAudioLvl(0);
    }
  }, [props.audioLevel])

  function dosDecimales(n) {
    let t = n.toString();
    let regex = /(\d*.\d{0,2})/;
    return t.match(regex)[0];
  }


    return (
      <>
        {props.isRemoteTrack ?
          (
            <>
              <div ref={container}  className="video-player">
                {isVidMuted ?
                  <span className="no-signal"><VideocamOff/> Sin video</span>
                  :
                  null
                }          
              </div>

              <div className="remote-aside-status">
                {isMicMuted ?
                  <Tooltip title="El micrófono no está habilitado">
                    <>
                      <span className="remote-status no-live"><MicOff /></span>
                      {/* <LinearProgress variant="determinate" value={audioLvl} /> */}
                     
                    </>
                  </Tooltip>
                  
                  :
                  <Tooltip title="El micrófono está activo">
                    <>
                      <span className="remote-status live"><Mic /></span>
                      {/* <LinearProgress variant="determinate" valueBuffer={audioLvl} value={audioLvl} /> */}
                    </>
                  </Tooltip>
                }  

                {isVidMuted ?
                  <Tooltip title="La cámara no está habilitada">
                    <span className="remote-status no-live"><VideocamOff/></span>
                  </Tooltip>
                  :
                  <Tooltip title="La cámara está activa">
                    <span className="remote-status live"><Videocam/></span>
                  </Tooltip>
                }
                
               { !isMicMuted ? 
                  <Tooltip title="El micrófono no está habilitado">
                    <>
                      <span
                        className="remote-status audio-user "
                      >
                        <RecordVoiceOverIcon style={{ opacity: audioLvl}} />
                      </span>
                    </>
                </Tooltip>
                  : null
                  
                } 
              </div>
            </>
          )
          :(<div ref={container}  className="video-player">
              {isVidMuted ?
                <Tooltip title="Tu cámara no se ha iniciado o está desactivada. Intenta cambiando la cámara en el botón Ajustes">
                  <span className="no-signal">
                    <VideocamOff/> Sin video
                  </span>
                </Tooltip>
                :
                null
              }  
          </div>)
        }
      </>
    );  
}

export default MediaPlayer;