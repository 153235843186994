import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../../components/Loading";
import { Check } from "@material-ui/icons";
import { Select, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import "./VerificarCamara.css";
import AgoraRTC from "agora-rtc-sdk-ng";
import ErrorList from "../Error/ErrorList";

const VerificarCamara = ({ setStep, onSelectedDevice }) => {
  const container = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoDevices, setVideoDevices] = useState(false);
  const [videoSourceSelected, setVideoSourceSelected] = useState();
  const [localStream, setLocalStream] = useState(null);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    getCameras();
  }, []);

  useEffect(() => {
    videoTest();
  }, [videoSourceSelected]);

  const getCameras = async () => {
    setErrors([]);

    try {
      const availableCameras = await AgoraRTC.getCameras();
      setVideoDevices(availableCameras);
      if (availableCameras.length > 0) {
        setVideoSourceSelected(availableCameras[0]);
      }
    } catch (error) {
      setIsLoading(false);

      if (error.message.includes("dismissed")) {
        setErrors((error) => [...error, "PERM_CAM_PERMISSION_DISMISSED"]);
      } else if (error.message.includes("denied")) {
        setErrors((error) => [...error, "PERM_CAM_PERMISSION_DENIED"]);
      } else if (error.message.includes("readable")) {
        setErrors((error) => [...error, "CAM_ACCESS_READABLE"]);
      } else {
        setErrors((error) => [...error, "CAM_ACCESS_GENERAL"]);
      }
    }
  };

  const videoTest = async () => {
    setIsLoading(true);
    setErrors([]);
    if (!videoSourceSelected) return;

    try {
      let config = {
        facingMode: "user",
        cameraId: videoSourceSelected.deviceId,
      };

      let stream = await AgoraRTC.createCameraVideoTrack(config);
      setLocalStream(stream);

    } catch (error) {
      if (error.message.includes("dismissed")) {
        setErrors((error) => [...error, "PERM_CAM_PERMISSION_DISMISSED"]);
      } else if (error.message.includes("denied")) {
        setErrors((error) => [...error, "PERM_CAM_PERMISSION_DENIED"]);
      } else if (error.message.includes("readable")) {
        setErrors((error) => [...error, "CAM_ACCESS_READABLE"]);
      } else {
        setErrors((error) => [...error, "CAM_ACCESS_GENERAL"]);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!container.current) return;
    localStream && localStream.play(container.current);

    return () => {
      closeVideo();
    };
  }, [container, localStream, isLoading]);

  const handleChangeDeviceId = (e) => {
    e.preventDefault();
    setVideoSourceSelected(
      videoDevices.find((camera) => camera.deviceId === e.target.value)
    );
  };

  const videoWorking = async () => {
    onSelectedDevice("camera", videoSourceSelected.label);
    await closeVideo();
    timeOut(2000);
    setStep(currentStep => currentStep + 1);
  };

  const closeVideo = async () => {
    await localStream.stop();
    await localStream.close();
    await setLocalStream(false);
    await timeOut(2000);
  };

  const timeOut = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return (
    <>
      <div className="wizzard-heading">
        <span className="step-number">03</span>
        <h2 className="step-title">Cámara</h2>
      </div>

      {isLoading && (
        <div className="wizzard wizzard-audio-container mb-4">
          <div className="row">
            <div className="col-12 mb-3">
              <div className="" style={{ minHeight: "400px" }}>
                <Loading />
              </div>
            </div>
          </div>
        </div>
      )}

      {localStream && !isLoading && (
        <div className="wizzard wizzard-audio-container mb-4">
          <div className="row">
            <div className="col-12 mb-3">
              <div
                ref={container}
                className="video-player"
                style={{ minHeight: "400px" }}
              ></div>
            </div>
            <div className="col-12 mb-4">
              <div className="row">
                <div className="col-12 mb-4">
                  <p>
                    Se ha encendido la cámara <b>{videoSourceSelected.label}</b>
                  </p>
                </div>
                <div className="col-7">
                  <h4>¿No es la cámara correcta?</h4>
                  <p>Puedes cambiarla por otrá a tu elección</p>
                </div>
                <div className="col-5">
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="change-camera-label">Seleccionar cámara</InputLabel>
                    <Select
                      labelId="change-camera-label"
                      id="change-camera"
                      value={videoSourceSelected.deviceId}
                      onChange={handleChangeDeviceId}
                      label="Seleccionar cámara"
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        <em>Seleccionar cámara</em>
                      </MenuItem>
                      {videoDevices.map((device, index) => (
                        <MenuItem
                          key={"cameraInput" + device.deviceId}
                          value={device.deviceId}
                        >
                          {device.label
                            ? device.label
                            : "Cámara " + (index + 1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="col-12 border-top pt-3">
              <h4>¿Te ves en el cuadro?</h4>
            </div>
            <div className="col-sm-8 col-12 mt-2">
              <button className="btn btn-success " onClick={videoWorking}>
                <Check /> <b>Me veo y la cámara es correcta</b>
              </button>
            </div>
          </div>
        </div>
      )}

      <ErrorList errors={errors} />
    </>
  );
};

export default VerificarCamara;
