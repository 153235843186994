import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { Carousel } from "react-responsive-carousel";
// import { frasesMotivadoras } from "../../helpers/frasesMotivadoras";
// import Loading from "../../../components/Loading";
import FilterDates from "./FilterDates";
import { UseReturnDates } from "../../../Patient/hooks/UseReturnDates";

export const HistoricoActividades = ({
  notificationsWeek,
  fechaIniWeek,
  fechaFinWeek,
  updated,
  renderSaved,
  closeModal,
  filtroFechas,
  state,
  setState,
}) => {
//   const [arregloDias, setArregloDias] = useState([]);
  const [diaIni, setDiaIni] = useState("");
  const [diaFin, setDiaFin] = useState("");
  const [totalAct, setTotalAct] = useState(0);
  const [totalCompletadas, setTotalCompletadas] = useState(0);
  const [porceCompletado, setPorceCompletado] = useState(0);
  const [actividades, setActividades] = useState([]);
//   const [estado, setEstado] = useState("");
  let cantidadCompletadas = 0;
  const returnDates = UseReturnDates();


  useEffect(() => {
    // console.log(notificationsWeek);
    if (notificationsWeek) {

      const setObj = new Set();
      const unicos = notificationsWeek.reduce((acc, actividad) => {
        if (!setObj.has(actividad.reminderId)) {
          setObj.add(actividad.reminderId, actividad)
          acc.push(actividad)
        }
        return acc;
      }, []);

      setActividades(unicos);
    }
    
  }, [notificationsWeek, returnDates]);

  useEffect(() => {
    const cargaActividades = async () => {
      setDiaFin(fechaFinWeek);
      setDiaIni(fechaIniWeek);

      await actividades.map((data) => {
        if (data.completed) {
          cantidadCompletadas++;
          // console.log(cantidadCompletadas);
        }
        return null;
      });
      setTotalAct(actividades.length);

      let porcentaje = 0;
      if (actividades.length <= 0) {
        porcentaje = 0;
      } else {
        porcentaje = (cantidadCompletadas * 100) / actividades.length;
      }
      setPorceCompletado(porcentaje.toFixed());
    };
    
    cargaActividades();
    // Solución porcentaje en historico
    setTotalCompletadas(cantidadCompletadas);

 
  }, [fechaIniWeek, fechaFinWeek, renderSaved, notificationsWeek, cantidadCompletadas, totalAct, totalCompletadas, actividades]);

  return (
    <>
      
        
    <div className="animated fadeIn pt-3 mb-3">
      <div className="text-center p-0 mt-0">
       
        <h2 className="recurso-title">Listado de Actividades asignadas</h2>
        <small className="">
          Actividades completadas en el periodo{" "}
          <span className="text-success"> {porceCompletado}%</span>
        </small>
      </div>

      <div
        className="table-responsive"
        style={{ pointerEvents: "none" }}
      >
        <table className="table table-borderer p-0 mb-0">
          <thead className="">
            <tr className="bg-tele text-light text-center small p-2 ">
              <th className="align-middle w-25">Fecha de asignación</th>
              <th className="align-middle w-25">Nombre</th>
              <th className="align-middle w-25">Estado</th>
              <th className="align-middle w-25">Fecha de Realización</th>
            </tr>
          </thead>
        </table>
      </div>
      <PerfectScrollbar>
        <table className="table table-bordered table-striped ">
          <tbody className="text-center align-middle small ">
            {actividades.length > 0 &&
              
              
              actividades.map((d, i) => {

                // Se agrega validación de estado de asignaciones segun fecha
                let today = moment(returnDates.fechaActual).utc();

                let estado = "";
                if (!d.completed && !today.isAfter(moment(d.dateTime),'d')) {
                  estado = "PENDIENTE";
                } else if (!d.completed) {
                  estado = "INCOMPLETA";
                } else {
                  estado = "COMPLETADA";
                }
                return (
                  <tr key={i + d.reminderId} className=" text-center p-2">
                    <td className="align-middle w-25">
                      {moment(d.assignmentDate).format("L - LT")}
                      <br />
                      <sub>
                        Periodo {moment.utc(d.startOfPeriod).format("L")} -{" "}
                        {moment(d.endOfPeriod).format("L")}
                      </sub>
                    </td>
                    <td className="align-middle w-25">
                      {d.data.activityName}
                    </td>
                    <td
                      className={
                        estado === "COMPLETADA"
                          ? "text-success align-middle w-25 "
                          : estado === "INCOMPLETA"
                            ? "text-danger align-middle w-25"
                            : "text-info align-middle w-25  "
                      }
                    >
                      {estado}
                    </td>
                    <td className="align-middle w-25 ">
                      {d.completed ? moment.utc(d.updatedAt).format("LL - LT") : "-"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </PerfectScrollbar>

      <div className="text-center p-2">
        <label className="text-tele">
          Total de actividades asignadas: {totalAct}
          <label className="text-success">
            Total Completadas {totalCompletadas}
          </label>
        </label>
      </div>
      {filtroFechas && <FilterDates setState={setState} state={state} diaIniH={fechaIniWeek} diaFinH={fechaFinWeek} />}
    </div>
        
      
    </>
  );
};
