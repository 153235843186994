import React, { useState, useEffect } from "react";
import {
   BrowserRouter as Router,
   useParams,
} from "react-router-dom";
import ReactGA from 'react-ga';
import VideoLayout from './TiposRecursos/VideoLayout';
import AudioLayout from './TiposRecursos/AudioLayout';
import PDFLayout from './TiposRecursos/PDFLayout';
import ResourceNotFound from "./ResourceNotFound/ResourceNotFound";


const RecursoVista = ({ recursos }) => {

   let { id } = useParams();
   const [recurso, setRecurso] = useState();
   const [isLoading, setIsLoading] = useState(true);

   //Se separa en dos useEffect para diferenciar el renderizado
   useEffect(() => {
      let recurso = recursos.find((item) => item._id === id);
      if(recurso){
        ReactGA.event({ category: "Recurso ID", action: recurso.name });
        setRecurso(recurso);
      }
      setIsLoading(false);
   }, []);

   if (isLoading) {
      return <>Loading</>;
   } else {
      return (
         <>
            {!recurso &&
              <ResourceNotFound />
            }
            {recurso?.media.typeMedia === "YOUTUBE" &&
               <VideoLayout recurso={recurso} />
            }
            {recurso?.media.typeMedia === "AUDIO" &&
               <AudioLayout recurso={recurso} />
            }
            {recurso?.media.typeMedia === "PDF" &&
               <PDFLayout recurso={recurso} />
            }
         </>

      )
   }
};

export default RecursoVista;
