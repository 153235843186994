import { useContext } from 'react';
import './NotFound.css';
import { NavigateBefore  } from '@material-ui/icons';
import { UserContext } from '../../context/UserContext';

const NotFound = () => {

  const { user, isLogged } = useContext(UserContext);

  const handleRedirection = () => {
    if(!isLogged){
      window.location.pathname = '/login';
    } else {
      if(user.role === 'PATIENT'){
        window.location.pathname = '/paciente';
      }

      if(user.role === 'PROFESIONAL'){
        window.location.pathname = '/profesional';
      }
    }
  };


  return(
    <div className="page-404">
      <div className="page-404-wrapper m-auto">
        <div className="container h-100">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-md-8 text-center">
              <span className="page-404-title">404</span>
              <span className="page-404-subtitle">La página que estás buscando<br/><b>no se ha encontrado</b></span>
              <p>La dirección a la que estás accediendo puede que se haya movido, eliminado, renombrado o que nunca haya existido.</p>
              <button onClick={handleRedirection} className="btn-submit btn pl-4 pr-4 mt-4">
                <NavigateBefore /> Volver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound;