import React from "react";
import Modal from "react-modal";
import {
  ClickAwayListener,
  IconButton,
  Fade,
  Paper,
  Popper,
  PopperPlacementType,
} from "@material-ui/core";
import { Settings, Clear  } from "@material-ui/icons";
import DeviceSelector from './DeviceSelector';

Modal.setAppElement("#root");

const PlayerControllers = ({
    localCameras, 
    localMicrophones,
    handleCameraSelection,
    handleMicrophoneSelection,
    camaraSeleccionada,
    microfonoSeleccionado
    }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState<PopperPlacementType>();
    const handleClick = (newPlacement: PopperPlacementType) => (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleClickAway = (e) => {
        setOpen(false);
        
    };

    return(
        <>
            <Popper
                className="popup"
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway} mouseEvent={"onMouseUp"}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className="popup-container">
                                <IconButton
                                    aria-label="delete"
                                    className="close-popup"
                                    onClick={handleClickAway}
                                >
                                    <Clear className="color-rojo" />
                                </IconButton>
                                <p>
                                    Selecciona la cámara o micrófono que quieres
                                    utilizar. Si no los has cambiado se estarán
                                    utilizando los predeterminados.
                                </p>

                                <div className="mb-4">
                                    <DeviceSelector 
                                        deviceOptions={localCameras}
                                        handlerFunction={handleCameraSelection}
                                        tipo={"Cámara"}
                                        handleClickAway={handleClickAway}
                                        dispositivoSeleccionado={camaraSeleccionada}
                                    />
                                </div>
                                <div className="mb-4">
                                    <DeviceSelector 
                                        deviceOptions={localMicrophones}
                                        handlerFunction={handleMicrophoneSelection}
                                        handleClickAway={handleClickAway}
                                        tipo={"Micrófono"}
                                        dispositivoSeleccionado={microfonoSeleccionado}
                                    />
                                </div>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>

            <IconButton
                aria-label="delete"
                onClick={handleClick("top")}
                className="controllers-ajustes"
            >
                <Settings className="color-primario" /> Ajustes
            </IconButton>
        </>
    )
   
}

export default PlayerControllers;