import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';


const RevisionAgenda = ({
  psicologo,
  setUserId,
  setUser,
  profesionales,
  isLoading,
  setIsLoading,
  setIsLoadingCitas
}) => {
  //  MEDICS Profesionales
  const [profesional, setProfesional] = useState(false);

  const options = profesionales.map((option) => {
    const firstLetter = option.firstname[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  })

  useEffect(() => {
    setUserId(profesional && profesional._id);
  }, [psicologo, profesional, setUserId, setUser, isLoading, setIsLoading]);


  return (
    <Grid item>
      <Grid className="header-redblu-select">
        {profesionales.length > 0 &&
          <Autocomplete
            id="grouped-profesionales"
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.firstname + " " + option.lastname + " " + option.rut}
            sx={{ width: 300 }}
            value={profesional ? profesional : { firstname: "Seleccione", lastname: "Profesional", rut: '', _id: '' }}
            onChange={(event, newValue) => {
              setProfesional(newValue);
              setUser(newValue);
              setIsLoadingCitas(true)
            }}
            renderInput={(params) => (
              <TextField {...params} label="Profesionales" variant="standard" />
            )}
            autoComplete
            clearOnEscape
            disableClearable
            closeText="cerrar"
          />
        }
      </Grid>
    </Grid>
  )
};



export default RevisionAgenda;