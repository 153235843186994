import { useEffect, useState } from 'react';

export const useAudioLevel = (audioLevel, rutUsuario, tipo: string) => {

  const [audioLvl, setAudioLvl] = useState<number>(0);

  useEffect(() => {
    if (audioLevel && tipo === 'paciente') {

      let xReg = '-';
      let rut = rutUsuario.split(xReg);
      let audio = audioLevel['uid'].split('-');
      
      if (rut[0] === audio[0]) {
        setAudioLvl(audioLevel['level'])
      }
    } else {
      if (audioLevel && tipo === 'profesional') {
        let xReg = '-';
        let rut = rutUsuario.split(xReg);
        let audio = audioLevel['uid'].split('-');

        if (rut[0] !== audio[0]) {
          setAudioLvl(audioLevel['level'])
        }
      }
      
    }

   
  
  }, [audioLevel, audioLvl, rutUsuario, tipo])

  return {
    audioLvl
  };
}
  
