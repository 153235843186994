import React, { useEffect, useState } from 'react';
import EmptyRooms from '../EmptyRooms/EmptyRooms';
import ChatRoom from '../ChatRoom/ChatRoom';
import ChatSearch from './ChatSearch';

const ChatList = ({
  chatRooms, 
  tipoUsuario, 
  rut, 
  userName, 
  isChatSelected, 
  chatRoomSelected,
  handleDisplayMode,
  handleToggle,
  handleUpdateMessage,
}) => {

  const [filterChatRooms, setFilterChatRooms] = useState(chatRooms);

    const filteringChat = (searchingName) => {
      if(searchingName === ''){
        setFilterChatRooms(chatRooms);
        return;
      }

      if(tipoUsuario==='PROFESIONAL'){
        const result = chatRooms.filter( chat => chat.patientName.includes(searchingName));
        setFilterChatRooms(result);
      } else {
        const result = chatRooms.filter( chat => chat.medicName.includes(searchingName));
        setFilterChatRooms(result);
      }
    };

    useEffect(() => {

      if(isChatSelected){
        setFilterChatRooms(chatRooms);
      }

    }, [isChatSelected]);
    
    return(
      <>
        {(chatRooms && chatRooms.length > 0) ? (
          <>
            {!isChatSelected &&
              <ChatSearch 
                filteringChat={filteringChat}
              />
            }
            
            <div className={isChatSelected ? 'compact-mode' : 'list-mode'}>
              {filterChatRooms.map((room) =>
                
                <ChatRoom 
                  key={room._id}
                  roomData={room}
                  rutUser={rut}
                  nameUser={userName}
                  isChatSelected={isChatSelected}
                  chatRoomSelected={chatRoomSelected}
                  tipoUsuario={tipoUsuario}
                  handleDisplayMode={handleDisplayMode}
                  handleToggle={handleToggle}
                  handleUpdateMessage={handleUpdateMessage}
                />
    
              )}
            </div>
          </>
        ) : (
          <EmptyRooms tipoUsuario={tipoUsuario} />
        )}
      </>
    );
}

export default ChatList;