import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import datesService from '../../services/dates';

import {
  Divider,
  Grid,
  Button
} from '@material-ui/core'
import {
  AccessTime,
  Today,
  Pageview
} from '@material-ui/icons'

const env = process.env.REACT_APP_ENV || 'production';

const FRONT_END = process.env.REACT_APP_URL_REDBLU || 'https://redblu.cl';

const BACK_END = process.env.REACT_APP_SOCKET_URL || 'https://consultasegura-backend.herokuapp.com/';

// Estilo personalizado del modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    height:'auto'
  },
};

Modal.setAppElement('#root');

export const AgendaModal = (props) => {

  const { event, events, psicologo, setConsulta, setApikey, apikey, setSelectData, setFechaValida, setIdDate, setBeforeDate, setDateStart, setDateEnd, visible } = props;

  const [isOpen, setIsOpen] = useState(true);

  const [isOpenOne, setOpenOne] = useState(1);

  const [fechaActual, setfechaActual] = useState('');

  localStorage.setItem('data_citaPaciente', JSON.stringify({
    medicoId: psicologo._id,
    rutMedico: psicologo.rut,
    nombreMedico: psicologo.firstname,
    pacienteId: event.detail.patientId,
    rutPaciente: event.detail.patientRut,
    nombrePaciente: event.detail.nombrePaciente,
  }))

  useEffect(() => {
    if (event.id) {
      setIsOpen(true);
      getFechaFin();  
    }
  }, [event, event.id]);

  useEffect(() => {
    let fechaFinConsulta = moment(event.end).format('YYYY-MM-DD');
    // se establece que cuando la fecha de la consulta sea menor a la fecha actual, se deshabilita el boton de ver teleconsulta
    let fecha = moment(fechaFinConsulta).isSame(fechaActual); // isSameOrAfter
    if (visible){
      if (fecha) {
        setFechaValida(fecha)  
      } else {
        setFechaValida(false)  
      }
      setIdDate(event.id)
      setDateStart(event.start)
      setDateEnd(event.end)
      if (moment(fechaActual).isBefore(fechaFinConsulta)){
        setBeforeDate(true)
      } else if (moment(fechaActual).isAfter(fechaFinConsulta)){
        setBeforeDate(false)
      }
    }
  }, [event.end, setfechaActual, setFechaValida, setBeforeDate, setDateStart, setDateEnd, fechaActual]);


  useEffect(() => {
    console.log("UseEffect isOpen", isOpen, event);
  }, [isOpen, event]);

  // closeModal
  const closeModal = () => {
    console.log("ClosingModal");
    setSelectData(false);
    setIsOpen(false);
  };

  const getFechaFin = () => {
    datesService
      .getFechas()
      .then((response) => {
        //  console.log('response : ', response.data);
        setfechaActual(moment(response.data.fechaActual).format('YYYY-MM-DD'));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getApikey = (
    rutPaciente,
    nombrePaciente,
    rutMedico,
    nombreMedico,
    especialidadMedico,
    endPoint,
    RedblueBaseURL
  ) => {
    let getAPIKeyURL =
      endPoint +
      '?rutPaciente=' +
      rutPaciente +
      '&nombrePaciente=' +
      nombrePaciente +
      '&rutMedico=' +
      rutMedico +
      '&nombreMedico=' +
      nombreMedico +
      '&especialidad=' +
      especialidadMedico;

    fetch(getAPIKeyURL, {
      method: 'get',
    })
      .then(function (response) {
        if (response.status === 401) {
          throw response;
        }
        return response.json(); // pass the data as promise to next then block
      })
      .then(function (data) {
        if (data.error) {
          // setErrorMessage(data.error);
        } else {
          const key = data.apikey;
          if (key && setApikey) {
             setApikey(key) ;
          }
        }
      })
      .catch(function (error) {
        console.log('Request failed', error);
      });
  };

  const hanleRedireccion = (index) => {
    if (isOpen && isOpenOne === 1) {
      setOpenOne(2);
      let evento = events.filter((resp) => resp.id === index);
      let nombrePaciente = evento[0].title;
      let rutPaciente = evento[0].detail.patientRut;

      if (event.detail.patientId) {
        // console.log("datos paciente clickeado:>>>", evento[0].detail);
        switch (env) {
          case 'local':
            getApikey(
              rutPaciente,
              nombrePaciente,
              psicologo.rut,
              psicologo.firstname,
              'Psicólogo',
              BACK_END + 'api/tokenredgesam',
              FRONT_END + '/teleconsulta/'
            );
            break;
          case 'qa':
            getApikey(
              rutPaciente,
              nombrePaciente,
              psicologo.rut,
              psicologo.firstname,
              'Psicólogo',
              BACK_END + 'api/tokenredgesam',
              FRONT_END + '/teleconsulta/'
            );
            break;
          case 'production':
            getApikey(
              rutPaciente,
              nombrePaciente,
              psicologo.rut,
              psicologo.firstname,
              'Psicólogo',
              BACK_END + 'api/tokenredgesam',
              FRONT_END + '/teleconsulta/'
            );
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      closeTimeoutMS={500}
      contentLabel='Agenda modal'
      className='modal mx-auto modal-agenda-medico'
      overlayClassName='modal-fondo'
      onChange={event.id && hanleRedireccion(event.id)}
    >
      <div className="p-2">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item xs={12} sm={4}>
            <h3>Paciente</h3>
            <h2>{event.detail.nombrePaciente}</h2>
          </Grid>

          <Grid item xs={12} sm={5} className="appointment-date">
            <h3>Atención Nº: <b>{event.id}</b></h3>
            <h2>
              <span className="mr-3"><Today /> {moment(event.start).format('DD/MM/YYYY')}</span>
              <span><AccessTime /> {moment(event.start).format('HH:mm')} a {moment(event.end).format('HH:mm')}</span>
            </h2>
          </Grid>

          <Grid item xs={12} sm={3} className="text-right">
            {visible 
              ?
              !event.detail.patientId
                ? <p className='text-danger mb-0'> El paciente <b>no ha creado su<br /> cuenta</b> en la aplicación</p>
                : <Button
                  variant="contained"
                  color="primary"
                  disabled={!apikey}
                  onClick={() => setConsulta(true)}
                >
                  <Pageview className="mr-1" /> Ver ficha
                </Button>
            : null }
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <h4>Información del Paciente</h4>
          </Grid>

          <Grid item xs={6} sm={4}>
            <div>
              <span className="label">Rut</span>
            </div>
            <div>
              <span className="patient-data">{event.detail.patientRut}</span>
            </div>
          </Grid>

          <Grid item xs={6} sm={4}>
            <div>
              <span className="label">Ciudad</span>
            </div>
            <div>
              <span className="patient-data">{event.detail.ciudad}</span>
            </div>
          </Grid>

          <Grid item xs={6} sm={4}>
            <div>
              <span className="label">Región</span>
            </div>
            <div>
              <span className="patient-data">{event.detail.region}</span>
            </div>
          </Grid>

          <Grid item xs={6} sm={4}>
            <div>
              <span className="label">Calle</span>
            </div>
            <div>
              <span className="patient-data">{event.detail.calle}</span>
            </div>
          </Grid>

          <Grid item xs={6} sm={4}>
            <div>
              <span className="label">Teléfono</span>
            </div>
            <div>
              <span className="patient-data">{event.detail.phone}</span>
            </div>
          </Grid>

          <Grid item xs={6} sm={4}>
            <div>
              <span className="label">Email</span>
            </div>
            <div>
              <span className="patient-data">{event.detail.email}</span>
            </div>
          </Grid>

        </Grid>
      </div>
    </Modal>
  );
};
