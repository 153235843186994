import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { formatRut, RutFormat, validateRut } from "@fdograph/rut-utilities";
import ReactGA from 'react-ga';
import queryString from "query-string";
import authentication from "./services/authentication";
import Loading from "./components/Loading";
import imgLogo from "./assets/images/redblu.png";
import home_photo from "./assets/images/introduccion/1.svg";
import { useHistory } from "react-router-dom";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};



const ApikeyLogin = (props) => {
  const rutPaciente = props.match.params.rutpaciente;
  const nombrePaciente = props.match.params.nombrepaciente
  const env = process.env.REACT_APP_ENV || "production";

  const [inputValues, setInputValues] = useState({
    rut: "",
  });
  const [isRutValid, setIsRutValid] = useState(true);
  const [entrando, setEntrando] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");
  const [psicologoValidado, setPsicologoValidado] = useState(false);
  const [verificando, setVerificando] = useState(false);
  const [, setErrorMessage] = useState();


  const FRONT_END = process.env.REACT_APP_URL_REDBLU || 'https://redblu.cl';

  const BACK_END = process.env.REACT_APP_SOCKET_URL || 'https://consultasegura-backend.herokuapp.com/';


  /**
   * 
   * REDIRECCIONAMIENTO
   */
  const history = useHistory();
  useEffect(() => {

    const rutPaciente = props.match.params.rutpaciente;
    const nombrePaciente = props.match.params.nombrepaciente;

    history.push(
      {
        pathname: '/login',
        search: `?rutPaciente=${rutPaciente}&nombrePaciente=${nombrePaciente}`
      });

  })


  const handleInputChange = (event) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
    ReactGA.event({ category: "Login", action: "handleInputChange", label: event.target.name, value: event.target.value + '' });
  };

  const handleRutChange = (event) => {
    let inputValue = event.target.value.toLowerCase();
    let formatValue = inputValue;
    const isValid = validateRut(inputValue);

    if (isValid) {
      formatValue = formatRut(inputValue, RutFormat.DASH);
    }
    setIsRutValid(isValid);

    setInputValues({
      ...inputValues,
      [event.target.name]: formatValue,
    });
  };

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    ReactGA.event({ category: "Login", action: "onSubmit", label: event.target.name, value: event.target.value + '' });
    onSubmitRut();
     queryString.parse(window.location.search);

    setEntrando(true);

    authentication
      .login({
        username: inputValues.rut,
        password: inputValues.password,
        role: 'PROFESIONAL'
      })
      .then((response) => {
        // console.log("response.data: ", response.data);
        if (response.data.accessToken && response.data.psicologo) {
          setPsicologoValidado(true);
          setVerificando(true);
          switch (env) {
            case "local":
              getApikey(
                rutPaciente,
                nombrePaciente,
                response.data.psicologo.rut,
                response.data.psicologo.firstname,
                response.data.psicologo.specialty || "Psicólogo",
                BACK_END + "/api/tokenredgesam",
                FRONT_END + "/teleconsulta/"
              );
              break;
            case "qa":
              getApikey(
                rutPaciente,
                nombrePaciente,
                response.data.psicologo.rut,
                response.data.psicologo.firstname,
                response.data.psicologo.specialty || "Psicólogo",
                BACK_END + "/api/tokenredgesam",
                FRONT_END + '/teleconsulta/'
              );
              break
            case "production":
              getApikey(
                rutPaciente,
                nombrePaciente,
                response.data.psicologo.rut,
                response.data.psicologo.firstname,
                response.data.psicologo.specialty || "Psicólogo",
                BACK_END + "/api/tokenredgesam",
                FRONT_END + "/teleconsulta/"
              );
              break;
            default:
              break;
          }
          return;
        }

        setAlertModalText("Usuario o contraseña incorrectos.");
        setAlertModalIsOpen(true);
        setEntrando(false);
        ReactGA.modalview("Login_Incorrect");
      })
      .catch((error) => {
        setAlertModalText("Usuario o contraseña incorrectos.");
        setAlertModalIsOpen(true);
        setEntrando(false);
        ReactGA.modalview("Login_Incorrect");
      });
  }

  const onSubmitRut = () => {
    let formatValue = inputValues.rut;
    const isValid = validateRut(inputValues.rut);

    if (isValid) {
      formatValue = formatRut(inputValues.rut, RutFormat.DASH);
    }
    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      "rut": formatValue,
    });
    ReactGA.event({ category: "Login", action: "onSubmitRut", value: isValid });
  };

  const getApikey = (
    rutPaciente,
    nombrePaciente,
    rutMedico,
    nombreMedico,
    especialidadMedico,
    endPoint,
    RedblueBaseURL
  ) => {
    var getAPIKeyURL = endPoint + "?rutPaciente=" + rutPaciente + "&nombrePaciente=" + nombrePaciente + "&rutMedico=" + rutMedico + "&nombreMedico=" + nombreMedico + "&especialidad=" + especialidadMedico;

    fetch(getAPIKeyURL, {
      method: 'get',
    }).then(function (response) {
      if (response.status === 401) {
        throw response;
      }

      return response.json(); // pass the data as promise to next then block
    }).then(function (data) {

      if (data.error) {
        setErrorMessage(data.error);
      } else {
        const apikey = data.apikey;
        var getPacientRedBlueURL = RedblueBaseURL + "medico?APIKey=" + apikey;
        window.open(getPacientRedBlueURL, '_blank', 'toolbar=0,location=0,menubar=0');
      }
    })
      .catch(function (error) {
        console.log('Request failed', error)
        setPsicologoValidado(false);
        setVerificando(false);
        setAlertModalText("Error al contactarse con el servidor.");
        setAlertModalIsOpen(true);
        setEntrando(false);
        ReactGA.modalview("Server_error");
      })
    setVerificando(false);
  }

  return (
    <>
      <div className="content-container animated fadeIn slow">
        <div className="middle-center-content pt-3">
          <div className="row pb-2">
            <div className="col-12 text-center">
              <img
                src={imgLogo}
                width="34%"
                className="img-fluid"
                alt="RedBlu Logo"
              />
            </div>
          </div>
          <div className="login-photo text-center">
            <img src={home_photo} alt="Iniciar Sesión" height="160px" />
          </div>
          <div className="heading mt-4">
            <h1 className="paged-title">Iniciar Sesión como Psicólogo</h1>
          </div>
          {psicologoValidado ?
            (
              verificando ?
                <div className="fullh movile-app">
                  <Loading />
                </div>
                :
                <div className="fullh movile-app">¡La ventana emergente ya está lista para realizar la consulta!</div>
            )
            :
            <div className="content-container animated fadeIn slow">
              <div className="middle-center-content pt-3">
                <form onSubmit={onSubmit}>
                  <div className="bodycontent">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <label htmlFor="rut">Rut</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={isRutValid ? "" : "is-invalid"}
                            id="rut"
                            name="rut"
                            required
                            onChange={handleInputChange}
                            onBlur={handleRutChange}
                            placeholder="11120411-k"
                            value={inputValues.rut}
                          />
                          <div className="invalid-feedback">Rut invalido.</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 mb-1">
                        <label>Contraseña</label>
                        <input
                          name="password"
                          onChange={handleInputChange}
                          required
                          type="password"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-right">
                        <input
                          type="submit"
                          value={entrando ? "CARGANDO" : "INGRESAR"}
                          disabled={entrando}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <Modal
                  isOpen={alertModalIsOpen}
                  style={customModalStyles}
                  onRequestClose={() => setAlertModalIsOpen(false)}
                >
                  <div className="alertModal">
                    <h1>{alertModalText}</h1>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-submit btn-lg"
                        onClick={() => setAlertModalIsOpen(false)}
                      >
                        Aceptar
                      </button>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default ApikeyLogin;