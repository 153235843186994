import AccesoSistema from "./SolutionComponents/AccesoSistema";
import NavegadorIncompatible from "./SolutionComponents/NavegadorIncompatible";
import NotFound from "./SolutionComponents/NotFound";
import PermisoCamaraHabilitar from "./SolutionComponents/PermisoCamaraHabilitar";
import PermisoCamaraOmitido from "./SolutionComponents/PermisoCamaraOmitido";
import PermisoMicrofonoOmitido from "./SolutionComponents/PermisoMicrofonoOmitido";

//Diccionario de componentes
const TroubleShootingLoader = {

  // URL : Componente a renderizar

  'notFound': NotFound,
  'permiso-microfono-omitido': PermisoMicrofonoOmitido,
  'permiso-camara-omitido': PermisoCamaraOmitido,
  'permiso-camara-bloqueado': PermisoCamaraHabilitar,
  'permiso-microfono-bloqueado': PermisoCamaraHabilitar,
  'permiso-microfono-bloqueado-por-sistema': AccesoSistema,
  'permiso-camara-bloqueado-por-sistema': AccesoSistema,

  'navegador-incompatible': NavegadorIncompatible,
  'camara-no-accesible': 'no lectura',
  'microfono-no-accesible': 'no lectura'

}

export default TroubleShootingLoader;


