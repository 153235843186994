import React from "react";
import PropTypes from 'prop-types';
import imgIconoUsuario from './../../assets/images/iconos/icono-usuario.svg';
import { NavigateNext } from "@material-ui/icons";

const ResumeAtention = ({ 
  nameMedic,
  rutMedic, 
  namePatient, 
  rutPatient, 
  callDuration, 
  especialidad
}) => {

  const getDurationString = (duration) => {
    if (duration) {
        let allminutes = Math.floor(duration / 60);
        var sd = {
          hour: Math.floor(duration / 3600),
          min: Math.floor((duration % 3600) / 60),
          sec: Math.floor(duration - allminutes * 60),
        };
        if (1 >= sd.min) {
          return "1 minuto";
        } else {
          return allminutes + "" + " minutos";
        }
    } else {
      return "";
    }
  };

  const handleContinue = () => {
    localStorage.removeItem("enSalaEspera");
    window.location.pathname = '/paciente';
  }
  
  return (
    <div className="middle-center-content mt-1 pt-0">
      <div className="heading mt-4 text-center">
        <h1 className="paged-title">Resumen de su atención</h1>
      </div>

      <div className="box-border mb-3">
        <div className="box-heading">
          <img src={imgIconoUsuario} alt="Médico"/> Médico que atendío
        </div>
        <div className="box-body">
          <table>
            <tbody>
              <tr>
                <td width="35%">RUT Médico</td>
                <td><span className="font-weight-bold">{rutMedic}</span></td>
              </tr>
              <tr>
                <td>Nombre</td>
                <td><span className="font-weight-bold">{nameMedic}</span></td>
              </tr>
              <tr>
                <td>Especialidad</td>
                <td><span className="font-weight-bold">{especialidad}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="box-border mb-3">
        <div className="box-heading">
          <img src={imgIconoUsuario} alt="Paciente"/> Paciente
        </div>
        <div className="box-body">
          <table>
            <tbody>
              <tr>
                <td width="35%">RUT paciente</td>
                <td><span className="font-weight-bold">{rutPatient}</span></td>
              </tr>
              <tr>
                <td>Nombre</td>
                <td><span className="font-weight-bold">{namePatient}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="duracionAtencion mb-3">
        <div className="banner-bg">
          <span>Duración de la Teleconsulta: <strong>{getDurationString(callDuration)}</strong></span>
        </div>
      </div>

      <div className="col-12 text-center">
        <button 
          type="button" 
          className="btn btn-submit" 
          onClick={handleContinue}>Continuar <NavigateNext /></button>
      </div>
    </div>
  )
}

ResumeAtention.propTypes = {
  nameMedic: PropTypes.string,
  rutMedic: PropTypes.string,
  namePatient: PropTypes.string,
  rutPatient: PropTypes.string,
  callDuration: PropTypes.number,
};

ResumeAtention.defaultProps = {
  nameMedic: "",
  rutMedic: "",
  namePatient: "",
  rutPatient: "",
  callDuration: 0,
};

export default ResumeAtention;
