import React, { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import { IconButton, Collapse, } from '@material-ui/core';

export const AlertasExcepciones = ({
  exception,
  rol,
}) => {
  
  const [open, setOpen] = useState(false);
  const [rolState, setRolState] = useState(undefined);
  const [alertas, setAlertas] = useState(false);
  const [code, setCode] = useState(exception.code);
  const [msg, setMsg] = useState(exception.msg);

  useEffect(() => {

    if (rol === 'profesional') { setRolState('pro'); }
    if (rol === 'paciente') { setRolState('pac'); }
    
    let mensajes = [];
    
    mensajes.push(
      {
        code,
        msg
      }
    );

    setAlertas(mensajes);

    mensajes.length > 0 ? setOpen(true) : setOpen(false);

    return () => {
      mensajes = [];
    };

  }, [code, exception, msg, rol]);

  useEffect(() => {
  }, [alertas]);

  /**
   * Cerrar Alerta 
   */
  const cerrarAlerta = (i,cod) => {
    setAlertas(alertas.filter(item => item !== cod));
    if (alertas.length === 0) {
      setOpen(false);
    }
  }

  return (
      <>
      {
        (code && msg && open && alertas.length > 0) ? (
          <div id="alerts-teleconsulta">
              {
                alertas.map(({cod,ms}, i) => (
                  <Collapse in={alertas.length > 0}>
                  <Alert
                    severity="warning"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          cerrarAlerta(i,cod);
                        }}
                      >
                        x
                      </IconButton>
                    }
                    >
                      {cod ?
                        'Código  : ' + cod + ' ' +
                        'Mensaje : ' + ms
                        : null}
                  </Alert>
                </Collapse>
                ))
              }
            </div>
        ) : null
      }
    </>
  )
}
