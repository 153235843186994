import React, { useContext, useState } from 'react';
// FORMIK
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// COMPONENTS

// MATERIAL UI
import { Button, TextField, Divider, OutlinedInput, InputAdornment, IconButton, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// SERVICES
import authentication from '../../../../services/authentication';
import patientServices from '../../../../services/patients';

import Modal from 'react-modal';
import customModalStyles from '../hooks/customModalStyles';
// import { UserContext } from '../../../../context/UserContext';



const InfoAcceso = ({
  user,
}) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [guardando, setGuardando] = useState(false);

  // const { setUser } = useContext(UserContext);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // ======================================
  // Modals
  // ======================================

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");


  // ======================================
  // Edición de contraseña
  // ======================================


  return (

    <Formik
      initialValues={
        {
          rut: user.rut,
          nombre: user.firstname,
          apellido: user.lastname,
          email: user.email,
          emailConfirm: '',
          passwordOld: '',
          password1: '',
          password2: '',
          editandoEmail: false
        }}
      onSubmit={async (values: any, { setSubmitting, resetForm }) => {
        console.log(values.editandoEmail)
        if (  !values.editandoEmail 
              && values.passwordOld 
              && values.password1
              && values.password2
              && !values.emailConfirm
        ) {
               
          // console.log('actualizo SOLO contraseña')
          try {
            setGuardando(true);
            await authentication.editarPassword({
              rutPaciente: values.rut,
              oldPassword: values.passwordOld,
              newPassword: values.password1
            }).then(response => {

              setAlertModalIsOpen(true);
              setAlertModalText("Contraseña actualizada con éxito.");
              setGuardando(false);
            });
          } catch (error: any) {
            console.log('error : >>', error.response)
            if (error.response.status === 403) {
              setAlertModalText("La contraseña actual es incorrecta");
              setAlertModalIsOpen(true);
              setGuardando(false);
            }
          }
            
        }
        // Edición Correo
        if (  values.editandoEmail
              && values.email
              && values.emailConfirm
              && !values.passwordOld 
              && !values.password1   
              && !values.password2   
        ) {
            //  console.log('actualizo SOLO correo')
          setGuardando(true)
          await patientServices.patch(user._id, {
            firstname: values.nombre,
            lastname: values.apellido,
            rut: values.rut,
            phone: '',
            email: values.email
          }).then(response => {
            setAlertModalIsOpen(true);
            setAlertModalText("Correo actualizado con éxito.");
            resetForm();
            setGuardando(false);

          }).catch(error => {
            setAlertModalText(error.toString());
            setAlertModalIsOpen(true);
            setGuardando(false);
          })
        } else if (
          values.email
          && values.emailConfirm
          && values.passwordOld
          && values.password1
          && values.password2
        ) {
          // console.log('actualizo correo y contraseña')
          try {
            setGuardando(true);
            await authentication.editarPassword({
              rutPaciente: values.rut,
              oldPassword: values.passwordOld,
              newPassword: values.password1
            }).then(response => {

              // setAlertModalIsOpen(true);
              // setAlertModalText("Contraseña actualizada con éxito.");
              // setGuardando(false);
              
            });
          } catch (error: any) {
            console.log('error : >>', error.response)
            if (error.response.status === 403) {
              setAlertModalText("La contraseña actual es incorrecta");
              setAlertModalIsOpen(true);
              setGuardando(false);
              return
            }
          }
          await patientServices.patch(user._id, {
            firstname: values.nombre,
            lastname: values.apellido,
            rut: values.rut,
            phone: '',
            email: values.email
          }).then(response => {
            setAlertModalIsOpen(true);
            setAlertModalText("Correo y contraseña actualizadas con éxito.");
            resetForm();
            setGuardando(false);

          }).catch(error => {
            setAlertModalText(error.toString());
            setAlertModalIsOpen(true);
            setGuardando(false);
          })
        }
   
        resetForm();
        // setUser({
        //   _id: user._id,
        //   firstname: user.firstname,
        //   lastname: user.lastname,
        //   rut: user.rut,
        //   email: values.email,
        //   woocommerceId: user.woocommerceId,
        //   role: user.role
        // })
      }}
      validationSchema={
        Yup.object().shape({
          email: Yup.string()
            .trim()
            .email('Debe ser un correo válido.')
            .required('(*) campo de correo requerido'),
          emailConfirm: Yup.string()
            .trim()
            .email('Debe ser un correo válido.')
            .oneOf([Yup.ref('email'), null], 'Las correos no coinciden.'),
          // .required('(*) campo de correo requerido'),
          passwordOld: Yup.string()
            .trim()
            .min(5, "La contraseña debe tener al menos 5 caracteres.")
            .when('editandoEmail', {
              is: false,
              then: Yup.string().required("(*) debe ingresar la contraseña actual"),
            }),
          password1: Yup.string()
            .trim()
            .min(5, "La contraseña debe tener al menos 5 caracteres.")
            .when('editandoEmail', {
              is: false,
              then: Yup.string().required("(*) debe ingresar la nueva contraseña"),
            }),
          password2: Yup.string()
            .trim()
            .min(5, "La contraseña debe tener al menos 5 caracteres.")
            .oneOf([Yup.ref('password1'), null], 'Las contraseñas no coinciden.')
            .when('editandoEmail', {
              is: false,
              then: Yup.string().required("(*)  debe repetir la nueva contraseña"),
            }),
        })
      }
    >
      {
        ({
          values,
          errors,
          touched,
          handleChange,
          handleReset,
          handleSubmit,
          setFieldValue,
          handleBlur,
          setFieldError,
          setFieldTouched,
          isSubmitting,
          validateForm,
        }) => (
          <Form
            onFocus={
              (event: (React.FormEvent<HTMLFormElement>)) => {
                  console.log(touched);
                if (touched.emailConfirm || touched.email) {
                  setFieldValue("editandoEmail", true)
                }
              }
            }
          >

            <div className='info-section shadow-sm p-4 mt-2'>

              <span className='title-info-profesional'>Información de acceso</span>
              <span className='text-info-profesional'>
                Modifica la información de acceso de tu cuenta de redblu.cl
              </span>

              <div className='mt-3 row'>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Correo
                  </span>
                  <TextField
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    variant="outlined"
                    className={"w-100 mb-2"}
                    type='email'
                    size="small"
                  />
                  {touched.email && errors.email && <ErrorMessage name='email' component="span" className='text-danger small' />}

                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Repetir Correo
                  </span>
                  <TextField
                    name="emailConfirm"
                    value={values.emailConfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    className={"w-100 mb-2"}
                    type='email'
                    size="small"
                  />
                  {touched.emailConfirm && errors.emailConfirm && <ErrorMessage name='emailConfirm' component="span" className='text-danger small' />}

                </div>
              </div>

              <div className='mt-3 row'>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Contraseña actual
                  </span>

                  <OutlinedInput
                    id="passwordOld"
                    autoComplete="off"
                    aria-autocomplete="none"
                    name="passwordOld"
                    type={showOldPassword ? 'text' : 'password'}
                    value={values.passwordOld}
                    className={"w-100"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.passwordOld && errors.passwordOld && <ErrorMessage name='passwordOld' component="span" className='text-danger small' />}
                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Nueva contraseña
                  </span>

                  <OutlinedInput
                    id="password1"
                    name="password1"
                    type={showPassword1 ? 'text' : 'password'}
                    value={values.password1}
                    className={"w-100"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }

                  />
                  {touched.password1 && errors.password1 && <ErrorMessage name='password1' component="span" className='text-danger small' />}
                </div>
                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-12">
                  <span className='text-muted font-weight-bold'>
                    Repetir Nueva contraseña
                  </span>
                  <OutlinedInput
                    id="password2"
                    name="password2"
                    type={showPassword2 ? 'text' : 'password'}
                    value={values.password2}
                    className={"w-100"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }

                  />
                  {touched.password2 && errors.password2 && <ErrorMessage name='password2' component="span" className='text-danger small' />}
                </div>
              </div>

              < Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
                <div className="alertModal">
                  <h1>{alertModalText}</h1>
                  <div className="text-center">
                    <button type="button" className="btn btn-submit btn-lg" onClick={() => { setAlertModalIsOpen(false) }}>Aceptar</button>
                  </div>
                </div>
              </Modal>
              <Divider light className="mt-3" />
              <Button
                variant="contained"
                className="mt-4"
                color="primary"
                size="large"
                type={'submit'}
                disabled={guardando}
              >
                Guardar Cambios
              </Button>
            </div>
          </Form>
        )
      }
    </Formik>

  );
}

export default InfoAcceso;