import React from 'react';
import { setNewToken } from '../../services/api';
import authentication from '../../services/authentication';
import { useHistory } from 'react-router-dom';

export const MultipleSessionError = () => {
  const history = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    authentication
      .logout()
      .then((response) => {
        setNewToken(null);
        localStorage.clear();
        window.location.pathname = "/login"; //history.push('/paciente');
      })
      .catch((error) => {
        setNewToken(null);
        localStorage.clear();
        window.location.pathname = "/login"; //history.push('/paciente');
      });
  };


  const usaEstaTab = () => {
    let salaEspera = localStorage.getItem("salaEspera") || false;
    console.log('Redirecting..')

    return history.push({
      pathname: '/paciente',
      enSalaEspera: salaEspera
    })

  }
  return (
    <>
      <div className="movile-app animated fadeIn slow">
        <div className="Toastify__toast-container">
          <div className="Toastify__toast">
            <div className="row p-4">
              <div className="text-center toastBody">
                <small>Atención</small>
                <p>Haz abierto la plataforma en otra ventana al mismo tiempo.<br />Presiona el botón <strong>"Usar Aquí"</strong> para utilizar está instancia.</p>
                <div className='row mt-5'>
                  <div className='col-7'>
                    <button
                      type="button"
                      className="btn btn-submit btn-full-width"
                      onClick={usaEstaTab}
                    >
                      Usar aquí
                    </button>
                  </div>
                  <div className='col-5'>
                    <button
                      type="button"
                      className="btn btn-skip"
                      onClick={handleLogout}
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
