import React from 'react';
import moment from "moment-timezone";
import ReactEmoji from 'react-emoji';
import './Message.css';

const Message = ({ message: { message, user, sendDate }, name }) => {
  let isSentByCurrentUser = false;
  let localDate;
  
  localDate = moment.utc(sendDate).tz("America/Santiago");
  
  const trimmedName = name.trim().toLowerCase();
  const trimmedUser = user.trim().toLowerCase();
  isSentByCurrentUser = (trimmedUser === trimmedName);

  const isSameDay = moment(localDate).isSame(moment(), 'day');

  if(isSameDay){
    localDate = localDate.format('LT');
  }else{
    localDate = localDate.format('l');
  }

  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <div className="messageBox ownMessageSend">
            <p className="messageText colorWhite">{ReactEmoji.emojify(message)}</p>
            <small>{localDate}</small>
          </div>
        </div>
        )
      : (
        <div className="messageContainer justifyStart">
          <p className="sentText">{user}</p>
          <div className="messageBox MessageSend">
            <p className="messageText colorDark">{ReactEmoji.emojify(message)}</p>
            <small>{localDate}</small>
          </div>
        </div>
      )
  );
}

export default Message;