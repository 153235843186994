import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppTeleconsultaPaciente from "../../teleconsulta/AppTeleconsultaPaciente";
import queryString from "query-string";

const RejoinTeleconsulta = ({ isLoged, tryAutologin }) => {

   const history = useHistory();
   const [directJoin, setDirectJoin] = useState(false);

   useEffect(() => {
      const parsed = queryString.parse(window.location.search);
      if (parsed.directjoin) {
         setDirectJoin(true);
      }
   }, []);

   useEffect(() => {
      if (!isLoged && tryAutologin) {
         history.push("/paciente");
      }
   }, [history, isLoged, tryAutologin]);

   return <div className="fullh"><AppTeleconsultaPaciente autoJoin={true} /></div>;
};

export default RejoinTeleconsulta;
