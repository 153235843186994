// import axios from 'axios';
import API from './api';
import URLS from '../URLS';

const AgoraServices = {
	getRtcToken: function(query) {
		return API.axios({
			method: 'get',
			url: URLS.agoraDynamicKey + '/rtcToken',
			params: query
		});
	},

	testGetRtcToken: function(query) {
		return API.axios({
			method: 'get',
			url: URLS.agoraDynamicKey + '/testRtcToken',
			params: query
		});
	},

	getRtmToken: function(query) {
		return API.axios({
			method: 'get',
			url: URLS.agoraDynamicKey + '/rtmToken',
			params: query
		});
	}
};

export default AgoraServices;
