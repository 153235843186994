
import React from 'react';
import imgNext from './../../../assets/images/iconos/icono-white-next.svg';

const LecturaActividad = ({ url, subtipo }) => {

   return (
      <>
         {subtipo === 'pdf' ?
            <div className="ver-historia">
               <div className={url ? "actividadFondo text-center pb-1" : "actividadFondo pb-4 text-center"}>
                  <object data={url} type="application/pdf" width="100%" height="100vh">
                     <a
                        href={url}
                        className={url ? "btn btn-submit btn-anchor btn-with-icon " : "btn btn-secondary disabled"}
                        target="_blank"
                        rel="noopener noreferrer"
                     >
                        {url ? 'Ver documento' : 'No hay documento'}
                        {url ? <img src={imgNext} alt="Ir al enlace" /> : null}
                     </a>
                     <div className="mt-1">
                        {url ? <small>Presiona el botón para visitar el documento.</small> : null}
                     </div>
                  </object>
               </div>
            </div>
            :
            <>
               <div className={url ? "actividadFondo  pt-1 pb-1 text-center pb-1" : "actividadFondo p-4 text-center"}>
                  <h4>Tipo de Recurso de Lectura</h4>
                  <a
                     href={url}
                     className={url ? "btn btn-submit btn-anchor btn-with-icon " : "btn btn-secondary disabled"}
                     target="_blank"
                     rel="noopener noreferrer"
                  >
                     {url ? 'IR AL ENLACE' : 'NO HAY ENLACE DISPONIBLE'}
                     {url ? <img src={imgNext} alt="Ir al enlace" /> : null}
                  </a>
                  <div className="mt-1">
                     {url ? <small>Presiona el botón para visitar el documento.</small> : null}
                  </div>
               </div>
               {
                  url ? <iframe
                     type={"text"}
                     width={"100%"}
                     height={"315"}
                     src={url}
                     title={"lextura"}
                  ></iframe>
                     : null
               }
            </>
         }
      </>
   )
}

export default LecturaActividad
