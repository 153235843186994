import React, { useEffect, useState } from 'react';
import wpaccountServices from '../../services/wpaccount';
import Loading from '../../components/Loading';

/*
Demo URL:
https://app.redblu.cl/validaCuenta?rut=17120464-k&email=contacto@luisponce.cl&nombre=Luis&apellido=Ponce&telefono=950052059
*/


export const VerifyAccount = ({ history }) => {
  const query     = new URLSearchParams(window.location.search);
  const rut       = query.get('rut');
  const email     = query.get('email');
  const nombre    = query.get('nombre');
  const apellido  = query.get('apellido');
  const telefono  = query.get('telefono');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
 
    wpaccountServices.validarcuenta({ rut })
      .then((r) => {
        const isAccountExist = r.status === 200 ? true : false;

        if (isAccountExist) {
          setLoading(false);
          history.push("/login");        
        } else {
          setLoading(false);
          history.push({
          pathname: '/registro',
          search: `?rut=${rut}&${email}&${nombre}&${apellido}&${telefono}`,
          state: { rut,email,nombre,apellido,telefono }
          })
        }
      }).catch((err) => {
        console.log(err)
      });
  }, [])

  return (
    <>
      {loading ?
        <div className="valida-cuenta">
          <Loading />
            <h4 className="paged-title font-weight-bold animated fadeIn bucle">Estamos validando tus datos ...</h4>
         </div>
        : null 
      }
    </>
  )
}
