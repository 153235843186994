import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import ResumeAtention from "./ResumeAtention";
import vattentionApi from "../../services/vattentions";
import { UserContext } from "../../context/UserContext";
import imgLogo from './../../assets/images/redblu.png';
import Review from './Review/Review';
import Loading from "../../components/Loading";
import ReviewServices from "../../services/reviews";

const Index = () => {

  const { vattentionid } = useParams();
  const storage = JSON.parse(localStorage.getItem("enSalaEspera"));

  const [reviewEnabled, setReviewEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [attention, setAttention] = useState({
    patientId: {},
    score: {},
    tokenRedgesamData: {},
  });
  const { user } = useContext(UserContext);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const responseVattention = await vattentionApi.getVAttention(vattentionid);
      if (responseVattention.data && responseVattention.data.tokenRedgesamData) {
        if (!responseVattention.data.score) {
          responseVattention.data.score = {};
        }
        setAttention(responseVattention.data);
      } 
      
      const reviewResponse = await ReviewServices.getAppointmentReviews(storage.appointment);
      
      if(reviewResponse && 
        reviewResponse.status === 200 && 
        reviewResponse.data.length === 0){
        setReviewEnabled(true);
      }

    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  
  if(isLoading){
    return <div className="fullh movile-app"><Loading /></div>
  }

  return (
    <div className="fullh">
      {user.role === "PATIENT" &&
        <div className="fullh">
          <div className="hoy-component fullwidth middday-bg animated fadeIn slow fullh">
            <div className="header-redgesam">
              <div className="row pb-2 pt-2">
                <div className="col-12 text-center">
                  <img src={imgLogo} width="14%" className="img-fluid" alt="RedBlu Logo" />
                </div>
              </div>
            </div>
            <div className="resumeAtention">
              <div className="content-container">
                {reviewEnabled &&
                  <Review 
                    appointmentID={storage.appointment}
                    user={user}
                    profesionalID={attention.tokenRedgesamData.woocommerceId}
                    vattentionID={vattentionid}
                  />
                }  
                <ResumeAtention
                  nameMedic={attention.tokenRedgesamData.doctorName}
                  rutMedic={attention.tokenRedgesamData.doctorRut}
                  namePatient={attention.tokenRedgesamData.patientName}
                  rutPatient={attention.tokenRedgesamData.patientRut}
                  callDuration={attention.duration}
                  especialidad={attention.tokenRedgesamData.specialty}
                />
              </div>
            </div>
          </div>
        </div>
      }
      <div className="footerPush"></div>
    </div>
  );
};

export default Index;
