import { Link } from "react-router-dom";
import { ErrorCodes } from "./ErrorCodes";
import { NavigateNext } from '@material-ui/icons';

const ErrorItem = ({errorCode, index}) => {

  const { title, description, ref_url } = ErrorCodes[errorCode];
  console.log(index);

  return(
    <div className={`row align-items-center ${(index+1)%2===0 ? 'border-top' : ''} py-4`}>
      <div className={`${ref_url ? 'col-12 col-sm-7' : 'col-12'}`}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      {ref_url &&
        <div className="col-12 col-sm-5 text-sm-right mt-3 mt-sm-0">
          <Link to={ref_url} target="_blank" className="btn btn-submit">Ver solución <NavigateNext /></Link>
        </div> 
      }
    </div>
  )

};

export default ErrorItem;