import React, { useState } from 'react'
import moment from "moment-timezone";
import DatePicker from "react-datepicker";

const useFilterDates = ({
  diaIniH,
  diaFinH,
  state,
  setState,
}) => {

  const [stateObj, setStateObj] = useState({
    diaIniH: diaIniH,
    diaFinH: diaFinH,
  });

  return (
    <div className="row animated fadeIn">
      <div className="col-md-12 col-12">
        <div className="card little-shadow">
          <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
            <div className="col-6">
              <label>Fecha de inicio</label>
              <DatePicker
                selected={moment.utc(diaIniH).toDate()}
                name="fechaInicial"
                className="p-0 pl-2 bg-light border "
                dateFormat="dd/MM/yyyy"
                maxDate={moment.utc(diaFinH).subtract(1,"day").toDate()}
                onChange={(date) => {
                  setState({
                    diaIniH: moment.utc(date)
                  });
                  setStateObj({
                    diaIniH: moment.utc(date)
                  });
                }}
              />
            </div>
            <div className="col-6">
              <label>Fecha de término</label>
              <DatePicker
                selected={moment.utc(diaFinH).toDate()}
                name="fechaFinal"
                className="p-0 pl-2 bg-light border "
                dateFormat="dd/MM/yyyy"
                minDate={moment.utc(diaIniH).add(1,"day").toDate()}
                onChange={(date) => {
                  setState({
                    diaFinH: moment.utc(date)
                  });
                  setStateObj({
                    diaFinH: moment.utc(date)
                  });
                }}
              // minDate={moment(diaIniH).toDate()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default useFilterDates