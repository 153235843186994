import React, { useState } from "react";
import VerificarAudio from "./Audio/VerificarAudio";
import VerificarCamara from "./Camara/VerificarCamara";
import VerificarMicrofono from "./Microfono/VerificarMicrofono";
import VerificacionCompleta from "./VerificacionCompleta/VerificacionCompleta";
import VerificarPermisos from "./Permisos/VerificarPermisos";
import MensajeInicial from "./MensajeInicial/MensajeInicial";

const Wizzard = ({
  onFinishContent
}) => {
  const [step, setStep] = useState(0);
  const [globalDevices, setGlobalDevices] = useState({
    camera: '',
    microphone: ''
  })

  const onSelectedDevice = (typeOfDevice, deviceName) => {
    setGlobalDevices({
      ...globalDevices,
      [typeOfDevice]: deviceName
    })
  };

  return (
    <div className="check-devices p-3 animated fadeIn slow">
      {step === 0 && <MensajeInicial setStep={setStep} />}
      {step === 1 && <VerificarPermisos setStep={setStep} />}
      {step === 2 && <VerificarAudio setStep={setStep} />}
      {step === 3 &&
        <VerificarCamara
          setStep={setStep}
          onSelectedDevice={onSelectedDevice}
        />
      }
      {step === 4 &&
        <VerificarMicrofono
          setStep={setStep}
          onSelectedDevice={onSelectedDevice}
        />
      }
      {step === 5 &&
        <VerificacionCompleta
          camera={globalDevices.camera}
          microphone={globalDevices.microphone}
          onFinishContent={onFinishContent}
        />
      }
      <div className="footerPush"></div>
    </div>
  );
};

export default Wizzard;
