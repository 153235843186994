import React, { useEffect, useState } from "react";
import {
   BrowserRouter as Router,
   Switch,
   Route,
   useRouteMatch,
} from "react-router-dom";
import RecursoVista from "./RecursoVista";
import Explorar from "./Explorar";
import Loading from "../../components/Loading";
import CategoriaRecursos from "./CategoriaRecursos";

import ResourcesServices from "../../services/resources";
import ReactGA from 'react-ga';
import NotFound from "../../Pages/NotFound/NotFound";

const RouterRecursos = (pros) => {
   const match = useRouteMatch();

   console.log(match);

   const [recursos, setRecursos] = useState();
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      ResourcesServices.getResources()
         .then((res) => {
            setRecursos(res.data);
            setLoading(false);
            ReactGA.pageview(match.path);
         })
         .catch((error) => {
            console.log(error);
         });
   }, []);

   if (loading) {
      return (
         <div className="fullh movile-app">
            <Loading />
         </div>
      )
   } else {
      return (
        <div className="fullh">
            <Switch>
               <Route stric path={`${match.path}/cada-dia-mejor`}>
                  <CategoriaRecursos
                     recursos={recursos}
                     categoriaName={"Cada día mejor"}
                  />
               </Route>

               <Route stric path={`${match.path}/higiene-del-sueno`}>
                  <CategoriaRecursos
                     recursos={recursos}
                     categoriaName={"Higiene del Sueño"}
                  />
               </Route>

               <Route stric path={`${match.path}/relajacion`}>
                  <CategoriaRecursos
                     recursos={recursos}
                     categoriaName={"Relajación"}
                  />
               </Route>

               <Route stric path={`${match.path}/:id`}>
                  <RecursoVista recursos={recursos} />
               </Route>

               <Route stric path={`${match.path}`}>
                  <Explorar />
               </Route>

               <Route path={`${match.path}/*`} component={NotFound} />
            </Switch>
        </div>
      );
   }
};

export default RouterRecursos;
