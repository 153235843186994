import API from "./api";
import URLS from "../URLS";

const commentaries = {

    // Crea Comentarios
    createCommentaries: function (data) {
        return API.axios({
            method: 'post',
            url: URLS.baseURL + URLS.commentaries,
            data: data
        });
    },

    // Muestra Comentarios por Id
    getCommentaries: function (remminderId) {
        return API.axios({
            method: 'get',
            url: URLS.baseURL + URLS.commentaries + `?remminderId=${remminderId}`,
        });
    },



}
export default commentaries;
