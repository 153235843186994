import React, { Component } from "react";
// import { Link } from 'react-router-dom';
// Components
import Button from "../components/button/Button";
import { InfoRecurso } from "../components/actividadRecurso/InfoRecurso";
// Images
// import IconCheckDone from '../../assets/images/check-done.svg';
// Icons
import { FaPen, FaClipboardCheck } from "react-icons/fa";
// Services
import ResourceServices from "../../services/resources";
import patientsAPI from "../../services/patients";
import { getSocketClient } from "../../services/socket";

import { format } from "date-fns";
import moment from "moment-timezone";

// SLIDER
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

// Helpers
import { settingsSlider } from "../helpers/settingsSlider";
import { FetchgetRemindersByDates } from "../helpers/FetchgetRemindersByDates";

// Analytics
import ReactGA from 'react-ga';
import "./styles.css";
export default class AsignarActividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recursoActivo: null,
      loading: true,
      show: false,
      filtered: [],
      searchText: "",
      recursos: [],
      optionsCategory: [],
      fechaIniWeek: this.props.fechaIniWeek,
      fechaFinWeek: this.props.fechaFinWeek,
      diffDays: moment(this.props.fechaFinWeek).diff(moment(this.props.fechaIniWeek), 'days'),
      pagination: 30,
    };

    this.timebuscar = null;
    this.categoriasRepetidas = 0;
  }

  componentDidMount() {
    ResourceServices.getResources()
      .then(async (res) => {
        let recursoActivo = null;
        if (res.data[0] && res.data[0]._id) {
          recursoActivo = res.data[0]._id;
        }

        let arrayCategory = [];
        let dataCategory = res.data;
        let recursosActivos = []; // filtro de recursos data.active = true
        dataCategory.map(({ category }, i) => {
          // condicional de solo mostrar recursos y sus categorías activas
          if (dataCategory[i].active === true) {
            category.map(({ nameCategory }, i2) => {
              arrayCategory.indexOf(nameCategory) === -1
                ? arrayCategory.push(nameCategory)
                : this.categoriasRepetidas++;
            });

            recursosActivos.push(dataCategory[i]);
          }
          return null;
        });
         this.setState({
          loading: false,
          recursos: recursosActivos,
          recursoActivo: recursoActivo,
          optionsCategory: ["Todos", ...arrayCategory],
        });
      })
      .catch((err) => {
        this.setState({ recursos: [], arrayCategory: ["Todos"] });
      });
  }
  componentWillUnmount() {
    this.state = {};
    this.timebuscar = null;
    this.categoriasRepetidas = 0;
  }
  verRecurso = (recursoId, recursoName) => (e) => {
    e.preventDefault();
    this.setState({ recursoActivo: recursoId });
    ReactGA.event({category:"Ver Recurso" , action:recursoName})
  };

  getContent = () => {
    const recurso = this.state.recursos.find(
      (r) => r._id === this.state.recursoActivo
    );

    if (recurso) {
      return (
        <InfoRecurso
          {...recurso}
          key={recurso._id}
          button={this.state.show}
          show={this.state.show}
          patientId={this.props.idPaciente}
          medicUser={this.props.idMedico}
          render={this.props.render.saved}
          rutPaciente={this.props.rutPaciente}
        />
      );
    } else {
      return null;
    }
  };

  onClickButton = (data) => {
    const recurso = this.state.recursos.find(
      (r) => r._id === this.state.recursoActivo
    );

    // TODO: obtener id del paciente correcto
    const patientId = this.props.idPaciente;

    var notificationData = {
      medicUser: this.props.idMedico, // TODO: obtener id del medico logueado
      resource: recurso._id,
      notificationCategory: recurso.category,
      message: data.message,
      // "urlname": "/video/uihg1i23",
      startOfPeriod: format(data.startDate, "yyyy-MM-dd"),
      endOfPeriod: format(data.endDate, "yyyy-MM-dd"),
      timesInTheDay: data.timesInTheDay,
    };
    patientsAPI.addResources(patientId, notificationData);
    console.log("EMITIENDO MENSAJE");
    try {
      let socket = getSocketClient();
      // console.log(socket);
      socket.emit("assignment", { type: "asignment", value: "true" }, () =>
        console.log("mensajeEmit")
      );
    } catch (error) {
      console.log(error);
    }
  };

  handlerBuscarRecurso = (ev) => {
    // ev.preventDefault();
    ReactGA.event({category:"Asigna Recursos" , action:"Buscar Recursos"});
    const inputVal = ev.target.value;
    clearTimeout(this.timebuscar);
    this.timebuscar = setTimeout(() => this.buscarRecurso(inputVal), 500);
  };

  // Busca Recurso Search
  buscarRecurso = (inputValue) => {
    if (!inputValue || !inputValue.trim()) {
      return this.setState({ filtered: [], searchText: "" });
    }

    const filtered = this.state.recursos.filter((r) => {
      try {
        let regx = new RegExp(inputValue, "im");
        if (regx.test(r.name)) {
          return true;
        }
        if (regx.test(r.longDescription)) {
          return true;
        }
        if (regx.test(r.shortDescription)) {
          return true;
        }
        return null;
      } catch (error) {
        return false;
      }
    });
    this.setState({ filtered: filtered, searchText: inputValue });
  };

  handelCategoryFilter = (event) => {
    // console.log("handelCategoryFilter");
    event.preventDefault();

    let categoria = event.target.value;
    ReactGA.event({category:"Tipo Recurso" , action:categoria});
    if (categoria === "Todos") {
      return this.setState({ filtered: [], searchText: "" });
    }

    let strCategoria = [];
    const filtered = this.state.recursos.filter(({ category }, i) => {
      let cantidadCategorias = category.length - 1;
      // recorrer toddos los recursos que tienen mas de una categoria
      if (cantidadCategorias > 1) {
        for (const cantidadCategorias of category) {
          strCategoria.push(cantidadCategorias.nameCategory);
        }
      } else {
        strCategoria.push(category[0].nameCategory);
      }

      // retorno todas las categorias y la comparo con la seleccionada
      return strCategoria[i] === categoria;
    });

    this.setState({ filtered: filtered, searchText: categoria });
  };

  toggleShow = () =>{
    this.setState((currentState) => ({ show: !currentState.show }));
    if(this.state.show===true){
      ReactGA.event({category:"Asignar Actividades", action:"Click Asignar Recursos"});
    }else{
      ReactGA.event({category:"Asignar Actividades", action:"Click Asignar Actividades Personalizadas"});
    }
  }

  render() {
    const filterRecursos = this.state.searchText
      ? this.state.filtered
      : this.state.recursos;


    return (
      <div className="row mt-2">
        <div className="col-md-12">
          <div className="navigationCenter">
            <div className="row">
              <div className="col-12 navigationMenu">
                <button
                  onClick={this.toggleShow}
                  name="recursos"
                  disabled={!this.state.show}
                  className={
                    !this.state.show
                      ? "navigationMenuItem activate"
                      : "navigationMenuItem"
                  }
                >
                  <FaClipboardCheck />
                  <h3 className="col-md-12">Asigna recursos</h3>
                  <p className="col-md-12">
                    Prediseñados y validados
                    <br />
                    por clínicos
                  </p>
                </button>

                <button
                  onClick={this.toggleShow}
                  name="recurso_per"
                  disabled={this.state.show}
                  className={
                    this.state.show
                      ? "navigationMenuItem activate"
                      : "navigationMenuItem"
                  }
                >
                  <FaPen />
                  <h3 className="col-md-12">
                    Asigna actividades personalizadas
                  </h3>
                </button>
              </div>
            </div>
          </div>

          <div className="centro-recursos little-shadow mb-4">
            {/* Se oculta información de recursos prediseñados */}
            {!this.state.show && (
              <div className="centro-recursos-header pt-4 pb-2">
                <div className="col-12">
                  <div className="d-flex topfilters">
                    <div>
                      <input
                        type="text"
                        name="buscarRecursos"
                        onChange={this.handlerBuscarRecurso}
                        className="search-btn btn-radius btn-height mr-2"
                        placeholder="Buscar recursos..."
                      />
                    </div>
                    <div className="d-flex">
                      <p className="small pt-2 pl-1 pr-2">Tipo:</p>
                      <select onChange={this.handelCategoryFilter}>
                        {this.state.optionsCategory.map((cat, idx) => {
                          return (
                            <option key={idx} value={cat}>
                              {cat}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row pb-3 ">
              <div className="col-md-12 recursos_main-content">
                {/* Se oculta información de recursos prediseñados */}
                {!this.state.show && (
                  <div className="recurso_sidebar little-shadow-sm pl-4 pr-4 pb-4">
                    
                      <Slider className="recursos p-3" {...settingsSlider}>
                        {filterRecursos.length === 0 ? (
                          <p className="alert alert-warning">
                            No hay Resultados
                          </p>
                        ) : (
                          filterRecursos.map((recurso) => (
                            <div
                              className={`recurso_entrada pointer little-shadow hover_actividades `}
                              key={recurso._id}
                            >
                                <div id="recurso-body" className="recurso-body bg-light hover_actividades">
                                <Button
                                  key={recurso._id}
                                  name={recurso.name}
                                  onClick={this.verRecurso(recurso._id, recurso.name)}
                                  active={
                                    this.state.recursoActivo === recurso._id
                                  }
                                  show={this.state.show}
                                />
                              </div>
                            </div>
                          ))
                        )}
                      </Slider>
                  
                  </div>
                )}
                <main className="w-100">
                  {filterRecursos.length === 0 ? (
                    <div className="d-flex align-content-center">
                      <div className="text-black-50 d-flex align-items-center">
                        {" "}
                        No hay resultados
                      </div>
                    </div>
                  ) : (
                    <div className="item-detail">
                      {this.getContent()}
                    </div>
                  )}
                </main>
              </div>
            </div>

            <FetchgetRemindersByDates
              idPaciente={this.props.idPaciente}
              fechaFinWeek={this.state.fechaFinWeek}
              fechaIniWeek={this.state.fechaIniWeek}
              diffDays={this.state.diffDays}
              state={this.state}
              tipo={"ResumenDiarioRecursos"} // nombre de componente a renderizar
              renderSaved={this.props.render.renderSaved}
              render={this.props.render.saved}
            />
          </div>
        </div>
      </div>
    );
  }
}
