import React, { useEffect, useState, useRef } from 'react';
import {
   BrowserRouter as Router,
   useHistory,
} from "react-router-dom";
import ReactGA from 'react-ga';
import iconTimer from "../../../assets/images/iconos/icon-time-black.svg";
import iconRepeat from "../../../assets/images/iconos/icon-repeat-black.svg";

let totalTime = 0;
let diff;

//Componente para el renderizado de Videos en la exploración de los recursos del paciente.
const VideoLayout = ({ recurso }) => {
   const [videoStatus, setVideoStatus] = useState(-1);
   const player = useRef();
   let history = useHistory();
   let playTime;
   let stopTime;

   useEffect(() => {
      //TODO:
      //Separar renderizado según el tipo de media
      if (recurso.media.typeMedia === "YOUTUBE") {
         //Obtener ID desde url
         let video_id = recurso.media.url.substr(
            recurso.media.url.indexOf("=") + 1
         );

         var tag = document.createElement("script");
         tag.src = "https://www.youtube.com/iframe_api";
         var firstScriptTag = document.getElementsByTagName("script")[0];
         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

         if (typeof window.YT !== "undefined") {
            createPlayer();
         }

         window.onYouTubeIframeAPIReady = () => {
            createPlayer();
         };

         function createPlayer() {
            player.current = new window.YT.Player("videoiframe", {
               videoId: video_id,
               height: "100%",
               width: "100%",
               playerVars: {
                  controls: 0,
                  modestbranding: 0,
                  rel: 0,
                  showinfo: 0,
                  autoplay: 0,
               },
               events: {
                  onReady: onPlayerReady,
                  onStateChange: onPlayerStateChange,
               },
            });
         }

         // 4. The API will call this function when the video player is ready.
         function onPlayerReady(event) {
            // event.target.playVideo();
         }

         // 5. The API calls this function when the player's state changes.
         //    The function indicates that when playing a video (state=1),
         function onPlayerStateChange(event) {
            //console.log('player change :', event.data);
            setVideoStatus(event.data);
            //pausa = 2
            //play = 1
            //Loading = 3
            if (event.data === 1) {
               playTime = new Date();
               ReactGA.event({ category: "RecursosVista", action: "Play" });
            } else {
               if (event.data === 2) {
                  stopTime = new Date();
                  diff = Math.abs(stopTime - playTime);
                  if (totalTime === null || totalTime === 0) {
                     totalTime = diff;
                  } else {
                     totalTime = diff + totalTime;
                  }
                  ReactGA.event({ category: "RecursosVista", action: "Stop" });
                  ReactGA.timing({ category: "RecursosVista", variable: "ViewTimeVideo", value: diff });
                  ReactGA.event({ category: "RecursosVista", action: "TotalTime", value: totalTime });
               }
            }
         }
      }

   }, []);


   return (
      <>
         <div className="steped-component">
            <div className="header-short">
               <div className="stepped-nav">
                  <div className="backbutton">
                     <button onClick={() => history.goBack()}>
                        <svg
                           version="1.1"
                           id="Capa_1"
                           x="0px"
                           y="0px"
                           width="20px"
                           height="20px"
                           viewBox="0 0 24 24"
                        >
                           {" "}
                           <path
                              fill="#000000"
                              d="M21,11.25H4.811l7.72-7.72c0.293-0.293,0.293-0.768,0-1.062c-0.293-0.293-0.768-0.293-1.061,0l-9,9 c-0.005,0.005-0.006,0.012-0.011,0.017c-0.063,0.066-0.116,0.142-0.151,0.227c-0.038,0.091-0.057,0.187-0.057,0.283 c0,0.001-0.001,0.002-0.001,0.004c0,0.011,0.006,0.02,0.006,0.031c0.003,0.087,0.018,0.173,0.051,0.255 c0.038,0.093,0.094,0.176,0.164,0.246l8.999,8.999c0.146,0.146,0.339,0.22,0.53,0.22s0.384-0.073,0.531-0.219 c0.293-0.293,0.293-0.768,0-1.062l-7.72-7.72H21c0.414,0,0.75-0.336,0.75-0.75S21.414,11.25,21,11.25z"
                           />{" "}
                        </svg>
                     </button>
                  </div>
                  <div className="stepped-title">
                     <span>{recurso.name.substr(0, 27) + "..."}</span>
                  </div>
               </div>
            </div>
         </div>
         <div className="ver-historia">
            {/* <embed src={"/assets/recursos/cada-dia-mejor/la-sandia/file.pdf"} width="100%" /> */}

            <div className="play-video">
               <div id="videoiframe"></div>
            </div>
            <div className="recurso-view">
               <div className="view-title">
                  <h3>{recurso.name}</h3>
               </div>
               <div className="view-extraDetail d-flex">
                  <span className="pr-1">
                     <img
                        width="20"
                        src={iconTimer}
                        className="opacity-05"
                     />
                     {recurso.extraDetail.duration}
                  </span>
                  <span>
                     <img
                        width="20"
                        src={iconRepeat}
                        className="opacity-05"
                     />
                     {recurso.extraDetail.suggestFrecuency}
                  </span>
               </div>
               <div className="view-description">
                  <p>{recurso.longDescription}</p>
               </div>
            </div>
            <div className="end-page pt-5 pb-5"></div>
         </div>
      </>
   );
}

export default VideoLayout;