import React, {useState, useEffect} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { NotificationsActive } from '@material-ui/icons';
import vattentionServices from '../../../services/vattentions';
import './EnviarNotificacion.css';


const EnviarNotificacion = ({patientRut}) => {
    const[sending, setSending] = useState(false);
    const[sendCounter, setSendCounter] = useState(0);
    const[seconds, setSeconds] = useState(10);
    const[messageSend, setMessageSend] = useState({
        message: "",
        result : ""
    });

    const handleSendNotification = async () => {
    
        setSending(true);
        setSendCounter(sendCounter+1);
        
        let response = await vattentionServices.sendNewNotification(patientRut);
        if(response && response.data){
            setMessageSend({
                message: response.data.message,
                result: response.data.result
            });
        }else{
            setMessageSend({
                message:"Ha ocurrido un problema en el envío",
                result: "ERROR"
            });
        }
        setSending(false);
        setSeconds(10);
        setTimeout(clearNotificationStatus, 10000);
    }

    const clearNotificationStatus = async () => {
        setMessageSend({
            message:"",
            result:""
        });
        
    }

    useEffect(() => {
        if(seconds===0){
           setSeconds(null)
        }
    
        if (!seconds) return;
    
        const intervalId = setInterval(() => {
            setSeconds(seconds - 1);
        }, 1000);
        
        return () => clearInterval(intervalId);
      }, [seconds]);

    return(
        <div className="col-12">
            <div className="notification_square animated fadeIn fast">
                <div className="row align-items-center">
                    
                    {messageSend.message ? 
                        <>
                            <div className="col-12 pl-0 animated fadeIn fast">
                                <p className="sendResult">{messageSend.message}</p>
                                <small>Puedes volver a enviar una notificación en {seconds} segundos</small>
                            </div>
                        </>
                        :   
                        <>
                            <div className="col-5">
                                <button 
                                    onClick={() => handleSendNotification()} 
                                    disabled={messageSend.message}>Notificar al paciente 
                                    <NotificationsActive />
                                </button>
                            </div>
                            <div className="col-7 pl-0 animated fadeIn fast">
                                <small>Al presionar el botón enviarás una notificación al paciente para reiterar el aviso a conectarse</small>
                            </div>
                        </>
                        
                    }
                    
                    {sending && <LinearProgress />}
                    
                </div>
            </div>
        </div>        
    )
};

export default EnviarNotificacion;