import React, {useEffect} from 'react';

export const MensajePrevioPaciente = ({children}) => {

  return (
    <div className="p-4">
      <div className="align-center">
        <h1>Antes de comenzar...</h1>


        <div className="guiastep">
          <div className="number">1</div>
          <div className="number-line"></div>
          <div className="guiastep-content">
            <div className="mb-3">Revisa que tengas <strong>conectados tus audifonos</strong> y <strong>micrófono</strong> correctamente.</div>
            <p>Si los conectas despúes de ingresar tendrás que recargar la página para que sean reconocidos.</p>
          </div>
        </div>

        <div className="guiastep">
          <div className="number">2</div>
          <div className="number-line"></div>
          <div className="guiastep-content">
            <strong>Cierra los programas</strong> que puedan estar usando la cámara o el micrófono como zoom, teams o skype.
          </div>
        </div>

        <div className="guiastep">
          <div className="number">3</div>
          <div className="guiastep-content">
            Revisa que tengas batería <strong>sobre el 30%</strong> de tu equipo
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          {children}
        </div>
      </div>
    </div>
  )
}
