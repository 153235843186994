import { CheckCircle, Mic, Videocam } from "@material-ui/icons";
import React from "react";
import styles from './verificacion.module.css';

const VerificacionCompleta = ({
  camera,
  microphone,
  onFinishContent
}) => {

   return (
    <div>
      <div className="wizzard-heading">
        <span className="step-number"><CheckCircle className={styles.icon}/></span>
        <h2 className="step-title">Verificación completa</h2>
      </div>
      <div className="wizzard wizzard-audio-container mb-4">
        <div className="text-center">
          <p>La <b>cámara y micrófono</b> que has utilizado en<br/>esta prueba de teleconsulta es:</p>
        </div>
        <div className="row border-top pt-3 mt-4">
          <div className="col-6">
            <div className="text-center">
              <Videocam />
              <p>Cámara utilizada:</p>
              <h4 className="mt-2">{camera}</h4>
            </div>
          </div>
          <div className="col-6">
            <div className="text-center">
              <Mic />
              <p>Micrófono utilizado:</p>
              <h4 className="mt-2">{microphone}</h4>
            </div>
          </div>
        </div>
      </div>
      {onFinishContent}
    </div>
   );
};

export default VerificacionCompleta;
