import { useState } from 'react'

export const useStatesSelects = <T>(initialState: T) => {
  const [selectAniosExperiencia, setSelectAniosExperiencia] = useState(initialState);  
  const [selectTituloProfesional, setSelectTituloProfesional] = useState(initialState);
  const [selectCasaEstudio, setSelectCasaEstudio] = useState(initialState);
  const [selectEspecialidadesDestacadas, setSelectEspecialidadesDestacadas] = useState(initialState);
  const [selectEdadesAtencion, setSelectEdadesAtencion] = useState(initialState);
  const [selectOtrasEspecialidades, setSelectOtrasEspecialidades] = useState(initialState);
  const [selectTecnicaTerapia, setSelectTecnicaTerapia] = useState(initialState);

  const resetAllSelect = () => {
    setSelectAniosExperiencia(initialState)
    setSelectTituloProfesional(initialState)
    setSelectCasaEstudio(initialState)
    setSelectEspecialidadesDestacadas(initialState)
    setSelectEdadesAtencion(initialState)
    setSelectOtrasEspecialidades(initialState)
    setSelectTecnicaTerapia(initialState)
  }

  return {
    selectAniosExperiencia, setSelectAniosExperiencia,
    selectTituloProfesional, setSelectTituloProfesional,
    selectCasaEstudio, setSelectCasaEstudio,
    selectEspecialidadesDestacadas, setSelectEspecialidadesDestacadas,
    selectEdadesAtencion, setSelectEdadesAtencion,
    selectOtrasEspecialidades, setSelectOtrasEspecialidades,
    selectTecnicaTerapia, setSelectTecnicaTerapia,
    resetAllSelect
  }
  
}

