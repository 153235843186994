import { useState } from 'react'

export const useStatesAttributes = <T>(initialState: T) => {
  const [aniosExperiencia, setAniosExperiencia] = useState(initialState);  
  const [tituloProfesional, setTituloProfesional] = useState(initialState);
  const [casaEstudio, setCasaEstudio] = useState(initialState);
  const [especialidadesDestacadas, setEspecialidadesDestacadas] = useState(initialState);
  const [edadesAtencion, setEdadesAtencion] = useState(initialState);
  const [otrasEspecialidades, setOtrasEspecialidades] = useState(initialState);
  const [tecnicaTerapia, setTecnicaTerapia] = useState(initialState);
  const [numSuperintendencia, setNumSuperintendencia] = useState(initialState);

  const resetAllAttributes = () => {
    setAniosExperiencia(initialState);
    setTituloProfesional(initialState);
    setCasaEstudio(initialState);
    setEspecialidadesDestacadas(initialState);
    setEdadesAtencion(initialState);
    setOtrasEspecialidades(initialState);
    setTecnicaTerapia(initialState);
    setNumSuperintendencia(initialState);
  }

  return {
    aniosExperiencia, setAniosExperiencia,
    tituloProfesional, setTituloProfesional,
    casaEstudio, setCasaEstudio,
    especialidadesDestacadas, setEspecialidadesDestacadas,
    edadesAtencion, setEdadesAtencion,
    otrasEspecialidades, setOtrasEspecialidades,
    tecnicaTerapia, setTecnicaTerapia,
    numSuperintendencia, setNumSuperintendencia,
    resetAllAttributes
  }
  
}

