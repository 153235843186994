import React, { useState, useEffect } from 'react';
import queryString from "query-string";
import vattentionApi from "../../services/vattentions";
import agoraApi from "../../services/agora";

export default function useTeleconsultaMedico()
  :
  {
      obtenerUIUser: Function,
      verifyApikey: Function,
      getMedicActiveRoom: Function,
      getAgoraDynamicRtcToken: Function
}
    {
    function obtenerUIUser (rutUsuario: string) {
        
        let numRut = rutUsuario.substring(0, (rutUsuario.length - 2)) + '-' + (Math.random()* (10 - 1));
        return numRut;

    };

    async function verifyApikey (dispatch: any){
        /** 
         * Function verifyApikey: 
         * Extrae de la sesión local los datos del rut de paciente y nombre.
        */

        let sessionData : {
            rutPaciente: string,
            nombrePaciente: string,
        }
        
        sessionData = JSON.parse(localStorage.getItem("temporalData") || "{}");
        try {
            dispatch({
                type: "setPatientRut",
                value: sessionData.rutPaciente,
            });
            dispatch({
                type: "setPatientName",
                value: sessionData.nombrePaciente,
            });

        } catch (error) {
            //Helpers.enviarToastError(1001,'No se ha podido guardar los datos del médico.') 
            return { rutPaciente: null, nombrePaciente: null };
        }

        return sessionData;
    };
    
    async function getMedicActiveRoom (){
        /**
         * Function getMediActiveRoom
         */
        
        const parsed = queryString.parse(window.location.search);
        try {
          const activeRoomsResponse = await vattentionApi.getMedicActiveRoom(
            parsed.APIKey
          );
          if (Array.isArray(activeRoomsResponse.data)) {
            return activeRoomsResponse.data;
          } else {
            return [];
          }
        } catch (error) {
          return [];
        }
    }

    /**
     * @function getAgoraDynamicRtcToken obtiene el token, channel name para utilziarlos en el join de agora.
     * @param dispatch recibe el dispatch del reducer
     * @param codec recibe el codect a utilizar VP8
     * @param patientRut recibe el rut del paciente
     */
    async function getAgoraDynamicRtcToken(dispatch: any, codec: string, patientRut: string) {
    
        const parsed = queryString.parse(window.location.search);

        let data = {
            codec: codec,
            patientRut: patientRut,
            APYKey: parsed.APIKey,
        }
    
        //TODO: evaluar si es necesario guardar en el state appid, channel, token

        return agoraApi
        .getRtcToken(data)
        .then((res) => {
            if (res.data && res.data.key) {
                dispatch({
                    type: "setToken",
                    value: res.data.key,
                });

                dispatch({
                    type: "setChannel",
                    value: res.data.channelName,
                });

                dispatch({
                    type: "setidVAttention",
                    value: res.data.idVAttention,
                });

                dispatch({
                    type: "setEncryptionPassword",
                    value: res.data.encryptionPassword,
                });

                dispatch({
                    type: "setAppId",
                    value: res.data.appID,
                });

                let roomParams = {
                    channel: res.data.channelName,
                    token: res.data.key,
                    appid: res.data.appID
                };

                return roomParams;
            
            } else {
            
                //Helpers.enviarToastError(1004,'Error al generar comunicación.');
                return {
                    channel: null,
                    token: null,
                    appid: null
                };
            }
        })
        .catch((error) => {
            console.log(error);
            return {
            channel: null,
            token: null,
            appid: null
            };
        });
  };





  return {
    obtenerUIUser,
    verifyApikey,
    getMedicActiveRoom,
    getAgoraDynamicRtcToken
  };
}
