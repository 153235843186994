import React, { useEffect, useState } from "react";
import { IconButton, Snackbar, Grow, Collapse } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import useAlertMessages from "./../../hooks/useAlertMessage";
import alertaState from "./alertaState";

const Alertas = () => {
  const [tipoClass, setTipoClass] = useState();
  const [open, setOpen] = React.useState(false);
  const { mensaje, tipo } = alertaState.alerta.alerta;

  useEffect(() => {
    if (mensaje) {
      setOpen(true);
      setTipoClass(tipo);
    }
  }, [mensaje, tipo]);

  return (
    <Collapse in={open}>
      <Alert
        severity={tipo}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        {mensaje}
      </Alert>
    </Collapse>
  );
};

export default Alertas;
