import React, { useState, useEffect } from "react";
import Modal from "react-modal";

// Hooks
import { useFormFicha } from "../../hooks";
// Services
import patientAPI from '../../../services/patients';
// Components
import { Encabezado } from "./EncabezadoFicha";
import { customModalStyles } from "../../utils/Modal";

// Icons
// import IconPlus from "../../assets/images/icon4.svg";
// Styles
import "react-datepicker/dist/react-datepicker.css";


//Analytics
import ReactGA from 'react-ga';

export const AsignarFicha = (props) => {
  const [formValues, handleInputChange, reset, errors] = useFormFicha({
    titulo: "",
    texto: "",
  });

  const {
    texto,
  } = formValues;

  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [flagError, setFlagError] = useState(false);
  const [toogleButton, setToogleButton] = useState(false);
  const [guardado, setGuardado] = useState('');

  useEffect(() => {
    props.render(new Date())
  }, [guardado])


  const submitHandler = (e) => {
    e.preventDefault();

    if (isFormValid()) {
      try {
        patientAPI.updateFichaPatient(
          props.patientId,
          props.rutMedico,
          props.nombreMedico,
          texto)
          .then(async (res) => {
            setAlertModalText(`Se ha agregado la ficha correctamente`);
            setAlertModalIsOpen(true);
            setAlertSuccess(true);
            setFlagError(false);
            setGuardado(new Date());

            reset();
          })
      } catch (error) {
        console.log(error);
      }
      return;
    } else {
      setFlagError(true);
    }

  };

  // validación del formulario
  const isFormValid = () => {

    if (texto.trim().length === 0 ) {
      setFlagError(true);
      setAlertSuccess(false);
      setAlertModalText(errors.texto);
      setAlertModalIsOpen(true);
      return false;
    }

    return true;
  };
  if (toogleButton === 0) {
    ReactGA.event({ category: "Agregar nueva entrada en ficha paciente", action: "false" });
    setToogleButton("");
  }
  if (toogleButton === 1) {
    ReactGA.event({ category: "Agregar nueva entrada en ficha paciente", action: "true" });
  }

  return (
    <>
      <Encabezado />
      <form className="animated fadeIn" onSubmit={submitHandler}>
        <div className="row pr-0 mr-2 bg-white pt-2 ">
          <div className="col-12">

            <div className="pb-3">
              <label className="zoom_label">
                (*)Detalle de la entrada
               </label>
              <textarea
                rows="6"
                autoComplete="off"
                className={`zoom form-control-tele ${errors.texto && flagError && "border-danger"
                  }`}
                placeholder="Detalle de la entrada ( Ejemplo : El paciente se encuentra muy bien hoy)"
                type="text"
                name="texto"
                onChange={handleInputChange}
                value={texto}
              />
              {errors.texto && flagError && (
                <sup className="zoom_label text-danger">
                  {errors.texto}
                </sup>
              )}
            </div>
          </div>

      
        </div>
        <div className="col-12 text-xs-center text-right">
          <button className="btn btn-submit" type="submit">
            Guardar nueva entrada
          </button>
        </div>

        <Modal
          isOpen={alertModalIsOpen}
          style={customModalStyles}
          onRequestClose={() => setAlertModalIsOpen(false)}
        >
          <div className="alertModal">
            <h1 className={alertSuccess ? "text-tele" : "text-dark"}>
              {alertModalText}
            </h1>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-submit btn-lg"
                onClick={() => setAlertModalIsOpen(false)}
              >
                Aceptar
               </button>
            </div>
          </div>
        </Modal>
      </form>
    </>
  );
};
