import {
    Announcement
  } from '@material-ui/icons';
import {
  Container,
  Grid
} from '@material-ui/core';
import './EmptyRooms.css';

const EmptyRooms = ({tipoUsuario}) => {

    return(
      <>
        <Container className="h-100" maxWidth="lg" style={{padding:0}}>            
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
            className="fullChatWrapper h-100"
          >
            <Grid
              className="chatWrapper h-100 sin-salas scroll">

              {tipoUsuario === 'PATIENT' ?
                <div className="row align-items-center h-100  chat-guia-usuario">
                  <div className="col-12 text-center border-bottom">
                    <Announcement className="mb-4" />
                    <h4>No tienes salas de chat aún</h4>
                  </div>
                  <div className="col-12 pt-4 border-bottom">
                    <p>¿Qué hago si tengo una teleconsulta y no me aparece el chat con el profesional?</p>
                    <p><strong>Lo primero es no preocuparte</strong>, ya que esto se debe a que el registro de tu cita puede haber sido generado recientemente. Cuando el profesional vaya a iniciar tu atención se creará la sala de chat.</p>
                  </div>

                  <div className="col-12 pt-4 border-bottom">
                    <p>¿Para qué me sirve el chat?</p>
                    <p>Es un medio de comunicación directo con el profesional para que ambos puedan coordinar la atención ya sea por algún atraso o problema de conexión.</p>
                  </div>

                  <div className="col-12 pt-4 pb-5">
                    <p>¿El profesional recibirá mis mensajes si está desconectado?</p>
                    <p>Tanto para él como para ti, podrán visualizar mensajes que hayan sido enviados cuando no hayan estado conectados</p>
                  </div>
                  <div className="col-12 pt-4 pb-5"></div>
                </div>
              :
                <div className="row align-items-center h-100 sin-salas">
                  <div className="col-12 text-center">
                    <Announcement className="mb-4" />
                    <h4>No hay salas creadas</h4>
                    <p>Las salas de chat son creadas de forma automática al momento de que está ventana se abre. Nota: si no ves el chat creado, cierra esta ventana y vuelve a abrirla</p>
                  </div>
                </div>
              }

            </Grid>
          </Grid>
        </Container>
        
      </>
    )
  };
  
  export default EmptyRooms;