import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { formatRut, RutFormat, validateRut } from '@fdograph/rut-utilities';
import home_photo from '../../assets/images/introduccion/1.svg';
import imgLogo from '../../assets/images/redblu.png';
import authenticationApi from "../../services/authentication";

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const OlvidoContrasena = () => {

  const [inputValues, setInputValues] = useState({rut: ''});
  const [isRutValid, setIsRutValid] = useState(true);
  const [sending, setSending] = useState(false);
  const [isSendOk, setIsSendOk] = useState(false);
  const [noExist, setNoExist] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleRutChange = (event) => {
    let inputValue = event.target.value.toLowerCase();
    let formatValue = inputValue;
    const isValid = validateRut(inputValue);

    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      [event.target.name]: formatValue
    })
  }

  const handleRutBlur = (event) => {
    let inputValue = event.target.value.toLowerCase();
    let formatValue = inputValue;

    const isValid = validateRut(inputValue);

    if (isValid) {
     formatValue = formatRut(inputValue, RutFormat.DASH);
    }
    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      'rut': formatValue
    })
  }

  const onSubmitRut = () => {
    let formatValue = inputValues.rut;
    const isValid = validateRut(inputValues.rut);

    if (isValid) {
      formatValue = formatRut(inputValues.rut, RutFormat.DASH);
    }
    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      'rut': formatValue
    })
  }

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    setNoExist(false);
    setModalIsOpen(true)
    setSending(true);

    onSubmitRut();

    if (isRutValid) {
      authenticationApi.restorePasswordEmail(inputValues.rut, "1")
      .then(response => {

        setIsSendOk(true)
        setModalIsOpen(false)
        setSending(false);

      }).catch(error => {

        setNoExist(true)
        setModalIsOpen(false)
        setSending(false);
      })
    }
  }

  return (
    <div className="content-container animated fadeIn fullh">
      <div className="middle-center-content pt-3 ">
        <div className="row pb-2">
          <div className="col-12 text-center">
            <img src={imgLogo} width="34%" className="img-fluid" alt="Redblu Logo" />
          </div>
        </div>
        <div className="login-photo text-center">
          <img src={home_photo} alt="Iniciar Sesión" height="160px" />
        </div>
        <div className="heading mt-4">
          <h1 className="paged-title">¿Olvidó su contraseña?</h1>
        </div>

        <form onSubmit={onSubmit}>
          <div className="bodycontent">
            <div className="row mb-5">
              <div className="col-12">
                <label>Ingrese su RUT</label>
                <input
                  name="rut"
                  type="text"
                  className={isRutValid ? "" : "is-invalid"}
                  onChange={handleRutChange}
                  onBlur={handleRutBlur}
                  required
                  placeholder="12345678-5"
                  value={inputValues.rut}
                />
                <div className="invalid-feedback">
                  Rut invalido.
                </div>
              </div>
              {isSendOk &&
                <div className="col-12 mb-3 mt-3">
                  <div className="alert-message alert-success">
                    Se le ha enviado un <strong>e-mail</strong> con las instrucciones para reestablecer su contraseña
                  </div>
                </div>
              }
              {noExist &&
                <div className="col-12 mb-3 mt-3">
                  <div className="alert-message">
                    El Rut ingresado <strong>no tiene cuenta</strong>. Puedes <Link to="/registro">crear una cuenta aquí</Link>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="actioncontent">
            <div className="row">
              <div className="col-6 text-left create-account">
                <Link to="/login" className="pt-2"> Volver </Link>
              </div>
              <div className="col-6 text-right">
                <input type="submit" value={"ENVIAR"} disabled={sending || !isRutValid} />
              </div>
            </div>
          </div>
        </form>
        <Modal isOpen={modalIsOpen} style={customModalStyles} onRequestClose={() => setModalIsOpen(false)}>
          <div className="modalOlvidoContrasena">
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status"></div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default OlvidoContrasena;
