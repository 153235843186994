const getBase64 = (file) => {
  return new Promise((resolve, rejectede) => {

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result)
    };
    reader.onerror = function (error) {
      rejectede(error)
    };

  })
}

exports.getBase64 = getBase64;