import AgoraRTC from "agora-rtc-sdk-ng";
import { useEffect, useState } from "react";
import './VerificarPermisos.css';
import { CircularProgress} from '@material-ui/core';
import { 
  CheckCircle,
  Error,
  Replay,
} from '@material-ui/icons';
import ErrorList from "../Error/ErrorList";

const VerificarPermisos = ({ setStep }) => {

  const [ isValidationComplete, setValidationComplete] = useState(false);
  const [ stepValidation, setStepValidation ] = useState(0);
  const [ errors, setErrors ] = useState([]);
  const [ checking, setChecking ] = useState(
    [
      {
        id: '1',
        name:'Compatibilidad con el navegador',
        isValid: null,
        isChecking: false,
        validation: (step) => {browserCompatibility(step)},
      },
      {
        id: '2',
        name:'Compatibilidad de reproducción de video',
        isValid: null,
        isChecking: false,
        validation: (step) => {videoCodecCompatibility(step)}
      },
      {
        id: '3',
        name:'Compatibilidad de reproducción de audio',
        isValid: null,
        isChecking: false,
        validation: (step) => {audioCodecCompatibility(step)}
      },
      {
        id: '4',
        name:'Verificando el acceso al micrófono',
        isValid: null,
        isChecking: false,
        validation: (step) => {checkMicPermission(step)}
      },
      {
        id: '5',
        name:'Verificando el acceso a la Cámara',
        isValid: null,
        isChecking: false,
        validation: (step) => {checkCamPermission(step)}
      },
    ]
  )

  useEffect(() => {
    execValidationFunction(stepValidation);
  }, [stepValidation]);

  const execValidationFunction = async (step) => {
    checking[step].validation(step);
  }

  const timeOut = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const browserCompatibility = async (step) => {
    
    const checkingAux = [ ...checking ];
    checkingAux[step].isChecking = true;
    setChecking(checkingAux); 
   
    await timeOut(2000);
    const isCompatible = await AgoraRTC.checkSystemRequirements();
    
    checkingAux[step].isChecking = false;
    checkingAux[step].isValid = isCompatible;
    setChecking(checkingAux);

    if(!isCompatible){
      setErrors(error => [...error, 'PERM_BROWSER_INCOMPATIBLE'])
    }

    await timeOut(2000);
    setStepValidation(step+1);
  }

  const videoCodecCompatibility = async (step) => {

    const checkingAux = [ ...checking ];
    checkingAux[step].isChecking = true;
    setChecking(checkingAux);

    await timeOut(2000);
    const deviceCodec = await AgoraRTC.getSupportedCodec();
    const isVideoCodecSupported = deviceCodec.video.includes('VP8') || deviceCodec.video.includes('H264');

    checkingAux[step].isChecking = false;
    checkingAux[step].isValid = isVideoCodecSupported;
    setChecking(checkingAux);

    if(!isVideoCodecSupported){
      setErrors(error => [...error, 'PERM_BROWSER_VIDEO_CODEC'])
    }
    
    await timeOut(2000);
    setStepValidation(step+1);

  }

  const audioCodecCompatibility = async (step) => {

    const checkingAux = [ ...checking ];
    checkingAux[step].isChecking = true;
    setChecking(checkingAux);

    await timeOut(2000);
    const deviceCodec = await AgoraRTC.getSupportedCodec();
    const isAudioCodecSupported = deviceCodec.audio.includes('OPUS');

    checkingAux[step].isChecking = false;
    checkingAux[step].isValid = isAudioCodecSupported;
    setChecking(checkingAux);

    if(!isAudioCodecSupported){
      setErrors(error => [...error, 'PERM_BROWSER_AUDIO_CODEC'])
    }
    
    await timeOut(2000);
    setStepValidation(step+1);

  }

  const checkMicPermission = async (step) => {

    const checkingAux = [ ...checking ];
    checkingAux[step].isChecking = true;
    setChecking(checkingAux);

    await timeOut(2000);
    let isCamPermissionAccepted = false;

    try {
      const resolve = await navigator.mediaDevices.getUserMedia({ audio:true });
      isCamPermissionAccepted=true;

    } catch (error) {
      console.log(error);

      if(error.toString().includes('dismissed')){
        setErrors(error => [...error, 'PERM_MIC_PERMISSION_DISMISSED'])
      } else if(error.toString().includes('system')){
        setErrors(error => [...error, 'PERM_MIC_PERMISSION_DENIED_SYSTEM'])
      } else if(error.toString().includes('denied')){
        setErrors(error => [...error, 'PERM_MIC_PERMISSION_DENIED'])
      } else if(error.toString().includes('device not found')){
        setErrors(error => [...error, 'MIC_NOT_FOUND'])
      } else {
        setErrors(error => [...error, 'MIC_ACCESS_READABLE'])
      }
    }

    checkingAux[step].isChecking = false;
    checkingAux[step].isValid = isCamPermissionAccepted;
    setChecking(checkingAux);
    
    await timeOut(2000);
    setStepValidation(step+1);

  }

  const checkCamPermission = async (step) => {

    const checkingAux = [ ...checking ];
    checkingAux[step].isChecking = true;
    setChecking(checkingAux);

    await timeOut(2000);
    let isMicPermissionAccepted = false;
    
    try {
      const resolve = await navigator.mediaDevices.getUserMedia({ video:true });
      isMicPermissionAccepted=true;

    } catch (error) {
      console.log(error);

      if(error.toString().includes('dismissed')){
        setErrors(error => [...error, 'PERM_CAM_PERMISSION_DISMISSED'])
      } else if(error.toString().includes('system')){
        setErrors(error => [...error, 'PERM_CAM_PERMISSION_DENIED_SYSTEM'])
      } else if(error.toString().includes('denied')){
        setErrors(error => [...error, 'PERM_CAM_PERMISSION_DENIED'])
      } else if(error.toString().includes('device not found')){
        setErrors(error => [...error, 'CAM_NOT_FOUND'])
      } else {
        setErrors(error => [...error, 'CAM_ACCESS_READABLE'])
      }
    }

    checkingAux[step].isChecking = false;
    checkingAux[step].isValid = isMicPermissionAccepted;
    setChecking(checkingAux);
    
    await timeOut(2000);
    setValidationComplete(true);
  }

  useEffect(() => {

    if(isValidationComplete){
      if(errors && errors.length === 0){
        setStep( currentStep => currentStep+1);
      }
    }

  }, [isValidationComplete]);

  return(
    <>
      <div className="wizzard-heading">
        <span className="step-number">01</span>
        <h2 className="step-title">Permisos</h2>
      </div>

      <div className="wizzard-checking">
        <ul className="verify-devices-items">
          {checking.map(validation => 
            <li className={`verify-devices-item 
              ${validation.isChecking ? 'is-checking' : ''} 
              ${validation.isValid === null ? 'default-check' : 
              validation.isValid ? 'valid-state' : 'not-valid-state'}
            `} key={validation.id}>
              <div className="result-container">
                {validation.isChecking ? 
                  <span>
                    <CircularProgress
                      size="20px"
                      thickness={8}
                      className="spinner animated zoomIn fast"
                    />
                  </span>
                  :
                  <span className="result">
                    {validation.isValid === true &&
                      <CheckCircle 
                        className="success animated zoomIn fast"
                      /> 
                    }
                    {validation.isValid === false &&
                      <Error 
                        className="error animated zoomIn fast"
                      />
                    }
                  </span>                    
                }
              </div>
              <span className="validation-name">{validation.name}</span>
            </li>
          )}
        </ul>
      
        {errors && errors.length > 0 && 
          <div className="text-center mt-4">
            <button className="btn btn-submit" onClick={() => setStep(0)}>
              Volver a intentar <Replay />
            </button>
          </div>
        }
      </div>


      <ErrorList errors={errors} />
    </>
  )
};

export default VerificarPermisos;