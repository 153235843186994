import React, { useState, useEffect, useRef } from 'react';
import Loading from '../../components/Loading';
import patientsCitasService from '../../services/patientsCitas';
import datesService from '../../services/dates';
import '../../assets/css/citasproximas.css'
import { formatRelative } from 'date-fns';
import moment from 'moment';
import { es } from 'date-fns/locale';
import { CalendarToday, NavigateNext } from '@material-ui/icons';

const DisplayAppointments = ({ patient, onClickWaitingRoom }) => {

  const [citas, setCitas] = useState([]);
  const [citasHoy, setCitasHoy] = useState([]);
  const [citasProximas, setCitasProximas] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoadinng] = useState(true);
  const [msgCitas, setMsgCitas] = useState('');
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const fetchData = async () => {
    let fechaActual;

    try {
      const responseFecha = await datesService.getFechas();
      if(responseFecha && responseFecha.status === 200){
        fechaActual = moment(responseFecha.data.fechaActual).format('yyyy-MM-DD HH:mm');
      }else{
        fechaActual = new Date();
      }
    } catch (error) {
      fechaActual = new Date();
    }

    try {
      const responseCitas = await patientsCitasService.getCitas({ id: patient._id });

      if(!responseCitas && responseCitas.status !== 200){
        setMsgCitas('No hemos podido obtener tus citas, intenta nuevamente recargando la página y si el problema persiste contacta al personal de asistencia mediante el botón "ayuda".');
        setLoadinng(false);
        return;
      }

      if(responseCitas.data.length <= 0){
        setMsgCitas('No tienes citas agendadas');
        setLoadinng(false);
        return;
      }

      let citasHoy = [];
      let citasProximas = [];

      const citas = responseCitas.data
        .filter(registro => registro !== null && registro.hasOwnProperty('date'))
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .map((cita) => {
         
          const dateCita = moment(moment.unix(cita.date).utc().format("yyyy-MM-DD HH:mm"));
          const dateFechaActual = moment(fechaActual);
         
          let relativeDate = formatRelative(dateCita.toDate(), dateFechaActual.toDate(), { locale: es, weekStartsOn: 1 });

          let data = {
            name: cita.line_items[0].name || null,
            date: dateCita,
            speciality: cita.speciality || null,
            dateRelative: relativeDate,
            id:cita.id
          };

          if (dateFechaActual.isSame(dateCita, 'day')) {
            citasHoy.push(data);
          } else {
            citasProximas.push(data);
          }

          return {
            name: data.name,
            date: data.date,
            speciality: data.speciality,
            id:cita.id,
            dateRelative: data.dateRelative
          };
        });

      setCitasHoy(citasHoy);
      setCitasProximas(citasProximas);
      setCitas(citas);

    } catch (error) {
      console.log("err get citas woocommerce :>> ", error);
      setError(error);
      setMsgCitas('No hemos podido obtener tus citas, intenta nuevamente recargando la página y si el problema persiste contacta al personal de asistencia mediante el botón "ayuda"');
      setLoadinng(false);
    }

    setLoadinng(false);
  };

  useEffect(() => {
    fetchData();    
  }, [patient._id]);

  return (
    <div className="citas-proximas p-0 my-0">
      {!loading ? 
          <>
            {citasHoy.map((cita, id) => 
              <TodayAppointments 
                onClickWaitingRoom={onClickWaitingRoom} 
                key={id} 
                cita={cita} 
              />
            )}

            {citasProximas.length > 0 &&
              <div className="citasProximas">
                <div className="row littleTitle mb-2 mt-4">
                  <div className="col-12 text-center">
                    <h4>PRÓXIMAS CITAS</h4>
                  </div>
                </div>
              </div>
            }

            {citasProximas.map((cita, id) => 
              <NextAppointments 
                key={id} 
                cita={cita} />
            )}
          </>
        :
        <div className="card today-appointments mb-3">
          <div className="row">
            <div className="col-12 px-0 pt-4">
              <Loading/>
            </div>
            <div className="col-12 pt-3 text-center">
              <small>Cargando próximas citas</small>
            </div>
          </div>
        </div>
      }

      {error ?
        <div className="card next-appointments mb-2">
          <div className="text-center text-black-60">
            <b>Atención:</b> {msgCitas}
          </div>
        </div>
        : null
      }
    </div>
  );
}


const TodayAppointments = ({
  cita,
  onClickWaitingRoom
}) => {

  return(
    <div className="card today-appointments mb-3">
      <div className="row">
        <div className="col-12 border-bottom pb-1">
          <h2>Tienes una teleconsulta <b>{cita.dateRelative}</b> hrs.</h2>
        </div>
      </div>
      <div className="row pt-4">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0">
          <h3 className="mb-1">{cita.name}</h3>
          <h4 className="mb-0">{cita.speciality}</h4>
        </div>
        <div className="col-12 col-sm-6 text-sm-right text-left">
          <button 
            className="btn btn-submit" 
            onClick={() => onClickWaitingRoom(cita.id)}
            >Ingresar <NavigateNext />
          </button>
        </div>
      </div>
    </div>
  );
}

const NextAppointments = ({
  cita
}) => {
  return(
    <div className="card next-appointments mb-1">
      <div className="row align-items-center">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0">
          <h3 className="mb-1">{cita.name}</h3>
          <h4 className="mb-0">{cita.speciality}</h4>
        </div>
        <div className="col-12 col-sm-6 align-left">
          <div className="row align-items-center">
            <div className="pl-3">
              <CalendarToday className="color-primario" />
            </div>
            <div className="pl-3">
              <h3 className="mb-0">
                <b>{cita.date.format('DD [de] MMMM')}</b>
                {cita.date.format(' [a las] ')}
                <b>{cita.date.format('HH:mm ')}</b>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default DisplayAppointments;