import React, { Component } from 'react';
export default class Cronometer extends Component {
  state = {
    start: null,
    seconds: 0,
    minutes: 0,
    hours: 0
  };

  async componentDidMount() {
    this.start();
    if( this.props.running ){
      this.stop();
      this.start();
    }else{
      this.stop();
    }
  }

  componentWillUnmount() {
    this.stop();
  } 

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.running !== prevProps.running) {
      
      if( this.props.running ){
        this.stop();
        this.start();
      }else{
        this.stop();
      }

    }
  }


  //funcion que agrega tiempo al cronometro (1 segundo)
  add = () => {
    var seconds = this.state.seconds;
    var minutes = this.state.minutes;
    var hours = this.state.hours;
    seconds++;
    if (seconds >= 60) {
      seconds = 0;
      minutes++;
      if (minutes >= 60) {
        minutes = 0;
        hours++;
      }
    }

    this.setState({ seconds: seconds, minutes: minutes, hours: hours });

    this.timer();
  };

  t = null;

  timer = () => {
    this.t = setTimeout(this.add, 1000);
  };

  // Iniciar cronometro
  start = () => {
    this.timer();
  };

  //parar cronometro
  stop = () => {
    clearTimeout(this.t);
  };

  render() {
    return (
      <div>
        <h1 className="mb-0">
          <time>
            {this.state.hours.toString().padStart(2, '0')}:{this.state.minutes.toString().padStart(2, '0')}:{this.state.seconds.toString().padStart(2, '0')}
          </time>
        </h1>
      </div>
    );
  }
}
