import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import RedbluLogo from '../../../../assets/images/redblu-logo.svg';
import { 
  Button, 
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Popper,
} from '@material-ui/core';
import { 
  AssignmentInd,
  CalendarToday,
  Devices,
  ExitToApp,
  MailOutline,
  Person
} from '@material-ui/icons';
import { UserContext } from '../../../../context/UserContext';
import { setNewToken, setLogedPatient, setLogedPsicologo } from '../../../../services/api';
import authentication from '../../../../services/authentication';
import './Header.css';
const HeaderPruebas = ({psicologo}) => {
  const { setLogged } = useContext(UserContext);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  
  useEffect(( ) => {
  }, [psicologo]);

  const handleLogoutLocal = (e) => {
    e.preventDefault();

    authentication
      .logout()
      .then((response) => {
         setNewToken(null);
         setLogedPsicologo(null);
         setLogedPatient(null);
         setLogged(false);
         localStorage.clear();
         window.location.replace("/login"); //history.push('/paciente');
      })
       .catch((error) => {
        //  console.log(error);
         setNewToken(null);
          setLogedPatient(null);
          setLogged(false);
          localStorage.clear();
         window.location.replace("/login"); //history.push('/paciente');
      });
  }

   return(
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="header-redblu"
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            >
            <Grid className="header-redblu-logo">
              <img src={RedbluLogo} alt="Redblu" width={120} />
            </Grid>
            <Grid className="header-redblu-nav">
              <Button 
                className={`
                  pl-3 pr-3
                  ${window.location.pathname === '/listado-profesionales' ? 'active' : ''}
                `}
               onClick={() => window.location.pathname = '/listado-profesionales'}>
                <CalendarToday className="mr-2"/>
                Agenda
              </Button>

              <Button
                className={`
                  ml-2 pl-3 pr-3
                  ${history.location.pathname === '/listado-profesionales/probar-teleconsulta' ? 'active' : ''}
                `} 
               onClick={() => history.push('/listado-profesionales/probar-teleconsulta')}>
                <Devices className="mr-2"/>
                Prueba de teleconsulta
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className="pr-4 header-redblu-profile">
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={(event) => handleToggle(event)}
              className="profile-button"
            >
              {psicologo.firstname} <Person className="ml-2" />
            </Button>
            
            <Popper 
              open={open} 
              anchorEl={anchorRef.current} 
              role={undefined} 
              placement="bottom-end"
              transition 
              disablePortal>
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: 'right top' }}
                >
                  <Paper elevation={4}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <List 
                          // autoFocusItem={open} 
                          id="menu-list-grow" 
                          className="header-profile-nav"
                          onKeyDown={handleListKeyDown}
                          subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                              Mi Cuenta
                            </ListSubheader>
                          }
                        >
                          <ListItem className="text-secondary">
                            <AssignmentInd className="mr-2" /> {psicologo.rut}
                          </ListItem>

                          <ListItem className="text-secondary">
                            <MailOutline className="mr-2" /> {psicologo.email}
                          </ListItem>

                          <Divider light />
                          
                          <ListItem
                            button
                            onClick={handleLogoutLocal}
                            className="logout"
                          >
                            <ExitToApp color="secondary" className="mr-2" /> Cerrar Sesión
                          </ListItem>
                        </List>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
          </div>
        </Grid>
      </Grid>
   )
};

export default HeaderPruebas;