import React from 'react';
import GuiaAndroid from './android/index';
import GuiaIphone from './iphone/index';
import GuiaWindows from './windows/index';

const PermisoBloqueado = () => {
    return(
        <div>
            <div className="page-name mb-4">
                <h1>Oups!</h1>
                <p>Hemos detectado que tienes la cámara y/o el micrófono bloqueados</p>
            </div>
            <div className="homeRecomendations">
                <div className="row">
                    <div className="col-12">
                        <GuiaAndroid />
                        <GuiaIphone />
                        <GuiaWindows />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p>Luego de habilitar los permisos debes recargar esta página</p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PermisoBloqueado;