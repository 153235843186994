const CatalogoErrores = [
    {
        code: 'PERMISSION_DENIED',
        mensaje: 'Has bloqueado el permiso para la cámara o el micrófono. Es necesario que habilites su el permiso, recargues la página para que los cambies tomen efectos.'
    },
    {
        code: 'NOT_READABLE',
        mensaje: 'Tu cámara o micrófono no se ha logrado iniciar. Intenta presionado el botón Ajustes y cambia la cámara o el micrófono que no esté funcionando.'
    },
    {
        code:'CAN_NOT_GET_GATEWAY_SERVER',
        mensaje: 'La sala que está intentando acceder ya no es válida. Cierre la ventana e inicie una nueva'

    },
    {
        code:'INVALID_PARAMS',
        mensaje: 'La plataforma no puede iniciar la consulta debido a que no has habilitado los permisos de la cámara y micrófono'
    },
    {
        code:'NOT_READABLE',
        mensaje: 'El dispositivo seleccionado no se puede utilizar, puede estar siendo ocupado por otro programa'
    },
    {
        code:'INVALID_OPERATION',
        mensaje:'Has intentado iniciar más de una vez mientras se estaba tratando de efectuar la conexión. Recarga la página y vuelve a intentarlo.'
    }

]

export default CatalogoErrores;