import React from "react";
import PropTypes from "prop-types";

const getStars = ({ totalActividades, totalCumplidas }) => {
   let stars = [];
   let count = 0;

   let numeroEstrellas = 3;
   let cumplimiento = Math.round(
      (totalCumplidas * numeroEstrellas) / totalActividades
   );

   for (let i = 0; i < cumplimiento; i++) {
      stars.push(
         <span className="completed" key={count}>
            ★
         </span>
      );
      count++;
   }

   for (let i = 0; i < numeroEstrellas - cumplimiento; i++) {
      stars.push(
         <span className="uncompleted" key={count}>
            ☆
         </span>
      );
      count++;
   }

   return stars;
};

const ProgresoHoy = ({ totalActividades, totalCumplidas }) => {
   const width = (totalCumplidas * 100) / totalActividades;
 
   return (
      <div className="row text-center progresoHoy">
         {/* <div className="col-12 progresoEstrellas">
            {getStars({ totalActividades, totalCumplidas })}
         </div> */}
         <div className="col-12 progresoTitulo">
            <h4>Progreso de tus actividades</h4>
           
         </div>
         <div className="col-12 progresoObjetivo">
            <p>
               Has cumplido {totalCumplidas} de {totalActividades} objetivos
            </p>
       
         </div>
         <div className="col-12">
            <div className="progress">
               <div className="progress-bar progress-bar-striped progress-bar-animated"
                  style={{ width: width + "%" }}
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  aria-valuenow={width}
               >{width.toFixed() + "%"}
               </div>


            </div>
      
         </div>

               
      </div>
   );
};

ProgresoHoy.propTypes = {
   totalActividades: PropTypes.number,
   totalCumplidas: PropTypes.number,
};

ProgresoHoy.defaultProps = {
   totalActividades: 3,
   totalCumplidas: 2,
};

export default ProgresoHoy;
