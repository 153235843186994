import React, { useContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import Header from "./components/header/Header";
import Loading from "../components/Loading";
import ProfesionalService from "./services/ProfesionalService";
import TestDevice from "./Assistance/TestDevice/TestDevice";
import citasService from "./services/Citas";
import { EditarPerfil } from "./pages";
import { UserContext } from "../context/UserContext";
import { WebIndex } from ".";
import { handleLogout } from "./hooks/useLogout";
import { inicializaSocket } from "../services/socket";




const ProfesionalIndex = () => {

  const history                    = useHistory();
  const { user }                   = useContext(UserContext);
  const [ isLoading, setIsLoading] = useState(true);
  const [ citas, setCitas]         = useState([]);
  const [ consulta, setConsulta]   = useState(false);
  // Titulo profesional
  const [dataProfesional, setDataProfesional] = useState(false);
  const [isLoadingDataProfesional, setIsLoadingDataProfesional] = useState(true);
  

  useEffect(
    () => {
      let mounted = true;
      const initSocket = () => {
        if (mounted) {
          inicializaSocket({
            room: user.rut,
            rut: user.rut,
            name: `${user.firstname} ${user.lastname}`,
          });
        }
      };
      initSocket();

      return () => {
        mounted = false; // cleanup function
      };
    },

    [user.firstname, user.lastname, user.rut],

  )

  useEffect(() => {
    document.body.classList.add('vista-profesional');
  }, []);


  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    const getAppointments = async () => {
      try {
        const response = await citasService.getCitas({ id: user._id })
        if (response && mounted) {
          setCitas(response)
      
        };

      } catch (error) {
        setCitas([]);
        setIsLoading(false);
      }
    };
    getAppointments();
    setIsLoading(false);


    return () => {
      mounted = false; // cleanup function
    };
  }, [user._id]);


  useEffect(() => {
  
    let mounted = true;

    const getTituloProfesional = async () => {
    try {
      const response = await ProfesionalService.getTituloImagenProfesional(user.woocommerceId)
      if (response && mounted) {
        setIsLoadingDataProfesional(true);
        setDataProfesional({
          nombre: response.nombre,
          titulo: response.titulo,
          imagen: response.imagen,
        })
      }
      setIsLoadingDataProfesional(false);
    } catch (error) {
      setIsLoadingDataProfesional(false);
    }
  };
    mounted && getTituloProfesional();
  return () => {
    mounted = false; // cleanup function
  }
}, [user.woocommerceId])



  return (
    <>
      {isLoading  ? (
        <div className="fullh movile-app">
          <Loading />
        </div>
      ) : (
        <>
            {user &&
              <Header
              dataProfesional={dataProfesional}
                isLoading={isLoadingDataProfesional}
                // isLoadingEdit={isLoadingDataProfesional}
                psicologo={user}
                // profesionalImagen={'profesionalImagen'}
                setConsulta={setConsulta}
              />
            }

          <Switch>
            <Route
              exact
              path={"/profesional/probar-teleconsulta"}>
              <TestDevice />
            </Route>


            <Route
              exact
              path={"/profesional/editar-perfil"}>
               <EditarPerfil
                  user={user}
                  setDataProfesionaTemp={setDataProfesional}
                  dataProfesionalTemp={dataProfesional}
                /> 
                
            </Route>

            <Route
              exact
              path={"/profesional"}
            >
              <WebIndex
                citas={citas}
                consulta={consulta}
                dummyDataCalendar={citas}
                handleLogout={handleLogout}
                history={history}
                isLoadingServiceIndex={isLoading}
                psicologo={user}
                setConsulta={setConsulta}
              />
            </Route>
          </Switch>
        </>
      )}
    </>
  );
};

export default ProfesionalIndex;
