import { useState, useEffect } from 'react';
import AgoraRTC, {
  IAgoraRTCClient, IAgoraRTCRemoteUser, MicrophoneAudioTrackInitConfig, CameraVideoTrackInitConfig, IMicrophoneAudioTrack, ICameraVideoTrack, ILocalVideoTrack, ILocalAudioTrack, ScreenVideoTrackInitConfig } from 'agora-rtc-sdk-ng';
import CatalogoErrores from './../utils/CatalogoErrores';
import alertaState from './../components/Alertas/alertaState';

export default function useAgora(client: IAgoraRTCClient)
  :
   {
      localAudioTrack: ILocalAudioTrack | undefined,
      localVideoTrack: ILocalVideoTrack | undefined,
      joinState: boolean,
      leave: Function,
      join: Function,
      remoteUsers: IAgoraRTCRemoteUser[],
      error: string,
      localCameras: MediaDeviceInfo[],
      localMicrophones: MediaDeviceInfo[],
      switchCamera: Function,
      switchMicrophone: Function,
      audioLevel: object | undefined,
      localSharingScreen?: ILocalVideoTrack | undefined,
      isSharingScreen?: boolean,
      setIsSharingScreen?: Function,
    }
    {
  const [localVideoTrack, setLocalVideoTrack] = useState<ILocalVideoTrack | undefined>(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<ILocalAudioTrack | undefined>(undefined);
  const [ error , setError] = useState("");
  const [ localCameras, setLocalCameras] = useState<MediaDeviceInfo[]>([]);
  const [ localMicrophones, setLocalMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [audioLevel, setAudioLevel] = useState<object | undefined>(undefined);
  // Sharing Scren
  const [localSharingScreen, setLocalSharingScreen] = useState<ILocalVideoTrack>();
  const [isSharingScreen, setIsSharingScreen] = useState(false);
  const [oldLocalVideoTrack, setOldLocalVideoTrack] = useState<ILocalVideoTrack | undefined>(undefined);
  /**
   * @function join utilizado para generar el ingreso a la teleconsulta.
   * @param appid recibe el APPID de Agora en .env de backend
   * @param channel recibe el canal al cúal el usuario ingresará
   * @param token token generado desde backend dynamicamente.
   * @param uid String único con el rut del usuario.
   */
  async function join(appid: string, channel: string, token?: string, uid?: string | number | null) {
    if (!client) return;

    try {
      let audioConfig = { AEC: true, ANS: true };
      const [microphoneTrack, cameraTrack] = await createLocalTracks(audioConfig);

      // let response = 
      await client.join(appid, channel, token || null, uid);

      if(microphoneTrack && cameraTrack){
        await client.publish([microphoneTrack, cameraTrack]);
      }else{
        if(microphoneTrack){
          await client.publish([microphoneTrack]);
        }else{
          await client.publish([cameraTrack]);
        }
      }

      (window as any).client = client;
      (window as any).videoTrack = cameraTrack;

      setJoinState(true);

    } catch (er:any | Error) {

      alertaState.alerta.setAlerta({
        mensaje:(displayErrorMessage(er.code, er.message)),
        tipo:'error'
      });

    }
  }

  async function leave() {
    try {
      setRemoteUsers([]);
      setJoinState(false);
      if(client){
        await client.unpublish(localAudioTrack);
        await client.unpublish(localVideoTrack);
        await client.leave();
      }else{
        return null;
      }
      if (localAudioTrack) {
        await localAudioTrack.setEnabled(false);
        localAudioTrack.stop();
        localAudioTrack.close();
      }
      if (localVideoTrack) {
        await localVideoTrack.setEnabled(false);
        localVideoTrack.stop();
        localVideoTrack.close();
      }
    } catch (error) {
      console.log("leave failed", error);
    }
  }




  async function createLocalTracks(audioConfig?: MicrophoneAudioTrackInitConfig, videoConfig?: CameraVideoTrackInitConfig)
  : Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    let microphoneTrack;
    let cameraTrack;

    try{
       //Lectura de los Micrófonos, si no hay permisos o no están accesibles no se creará el track.
      const microfonosDetectados = await AgoraRTC.getMicrophones();
      if(microfonosDetectados){
        //Actualizo el Select con los dispositivos disponibles.
        setLocalMicrophones(microfonosDetectados);
      }

      microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig);
      setLocalAudioTrack(microphoneTrack);
    } catch (er: any | Error ){

      alertaState.alerta.setAlerta({
        mensaje:(displayErrorMessage(er.code, er.message)),
        tipo:'error'
      });
    }

    try{
      //Lectura de las Cámaras, si no hay permisos o no están accesibles no se creará el track.
      const camarasDetectadas = await AgoraRTC.getCameras();
      if(camarasDetectadas){
        //Actualizo el Select con los dispositivos disponibles.
        setLocalCameras(camarasDetectadas);
      }

      cameraTrack = await AgoraRTC.createCameraVideoTrack(videoConfig);
      setLocalVideoTrack(cameraTrack);

    } catch (er: any | Error ){

      alertaState.alerta.setAlerta({
        mensaje:(displayErrorMessage(er.code, er.message)),
        tipo:'error'
      });

    }

    return [microphoneTrack, cameraTrack];
  }

  async function switchCamera(newCameraTrackID: string){
    try{
      alertaState.alerta.setAlerta({
        mensaje:"Cargando cámara seleccionada",
        tipo:'info'
      });

      if(localVideoTrack){
        //Se detiene el track si ya existe.
        localVideoTrack.stop();
        await client.unpublish(localVideoTrack);
      }

      const cameraTrack = await AgoraRTC.createCameraVideoTrack({cameraId: newCameraTrackID});
      setLocalVideoTrack(cameraTrack);
      setOldLocalVideoTrack(cameraTrack);
      await client.publish([cameraTrack]);

      alertaState.alerta.setAlerta({
        mensaje:"Se ha cambiado correctamente la cámara",
        tipo:'success'
      });

    } catch (er:any | Error) {

      alertaState.alerta.setAlerta({
        mensaje:(displayErrorMessage(er.code, er.message)),
        tipo:'error'
      });

    }
  }

  async function switchMicrophone(newMicrophoneID: string){
    try{

      alertaState.alerta.setAlerta({
        mensaje:"Cargando micrófono seleccionado",
        tipo:'info'
      });

      if (localAudioTrack) {
        console.log('volumeLevel : ',localAudioTrack.getVolumeLevel());
        //Se detiene el track si ya existe.
        localAudioTrack.stop();
        await client.unpublish(localAudioTrack);
      }

      const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack({microphoneId: newMicrophoneID, AEC: true, ANS: true});
      setLocalAudioTrack(microphoneTrack);
      await client.publish([microphoneTrack]);

      alertaState.alerta.setAlerta({
        mensaje:"Se ha cambiado correctamente el micrófono",
        tipo:'success'
      });

    } catch (er:any | Error) {

      alertaState.alerta.setAlerta({
        mensaje:(displayErrorMessage(er.code, er.message)),
        tipo:'error'
      });

    }
  }

  function displayErrorMessage(code: string, message: string){
    const objError = CatalogoErrores.find( exception => exception.code === code );

    if(objError){
      return objError.mensaje;
    }else{
      return message;
    }
  }

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
    }
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
      alertaState.alerta.setAlerta({
        mensaje: `El usuario se ha conectado a la teleconsulta`,
        tipo: 'success'
      });
    }
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers));
      alertaState.alerta.setAlerta({
        mensaje: `El usuario se ha desconectado de la teleconsulta`,
        tipo: 'error'
      });
    }

    const netWorkQuality = (stats) => {
      //Todo: traspasar a un indicador visual de la calidad de conexión
      //Todo: Omitir para una mejora.

      /* console.log("%c netWorkQuality", "color: yellow; font-style: italic; background-color: blue;padding: 2px");
      const remoteNetworkQuality = client.getRemoteNetworkQuality();
      console.log(remoteNetworkQuality);
      const clientStats = client.getRTCStats();
      console.log(clientStats); */

  /*     Duration: 414
      OutgoingAvailableBandwidth: 432.706
      RTT: 142
      RecvBitrate: 1079920
      RecvBytes: 33350602
      SendBitrate: 191304
      SendBytes: 10856974
      UserCount: 2 */

    }


    client.enableAudioVolumeIndicator();
    const enableAudioVolumeIndicator = (volumes): void => {
        volumes.forEach((volume, index) => {
          //  console.log(`volume-indicator : ${index} UID ${volume.uid} Level ${volume.level}`);
          setAudioLevel(volume);
        });
    }

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    client.on('network-quality', netWorkQuality);
    client.on('volume-indicator', enableAudioVolumeIndicator);

    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
      client.off('network-quality', netWorkQuality);
	    client.off('volume-indicator', enableAudioVolumeIndicator);
    };
  }, [client]);

// Sharing Screen
  useEffect(() => {
    let config: ScreenVideoTrackInitConfig = {
      optimizationMode: "detail",
      encoderConfig: "1080p_3",
     };
    createLocalTracks(config);

    async function createLocalTracks(config: ScreenVideoTrackInitConfig) {
      try {
        let videoTrack = localVideoTrack;
      
  
        
        if (isSharingScreen) {
          // Create a screen track for screen sharing.
          // config.optimizationMode = "detail"
          // config.encoderConfig = "1080p_3"
          console.log('config', config)
          let track = await AgoraRTC.createScreenVideoTrack(config, "disable")
          
          // Función que permite hacer que se cierre al presionar dejar de compartir
          track.on('track-ended', () => {
            console.log('stopScreenSharing')
          })
          if (videoTrack && track) {

            // Stop playing the local video track.
            videoTrack.stop();

            // Unpublish the local video track.
            await client.unpublish(videoTrack);

            // Publish the screen track.
            await client.publish(track);

            // Play the screen track on local container.
            track.setEnabled(true)

            setLocalVideoTrack(track);
          }

          setLocalSharingScreen(track);
          
        } else {
          if (videoTrack && !isSharingScreen && localSharingScreen) {

            //Stop playing the screen track.
            localSharingScreen.stop();

            // Unpublish the screen track.
            await client.unpublish(localSharingScreen);

            // Publish the screen track.
            await client.publish(videoTrack)

            // Play the screen track on local container.
            videoTrack.setEnabled(true)
            
    
            setLocalVideoTrack(videoTrack)     

          }
          setLocalSharingScreen(localSharingScreen);

        }

      } catch (er: any | Error) {

        alertaState.alerta.setAlerta({
          mensaje: (displayErrorMessage(er.code, er.message)),
          tipo: 'error'
        });

      }
    };
   

  }, [isSharingScreen])

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    remoteUsers,
    error,
    localCameras,
    localMicrophones,
    switchCamera,
    switchMicrophone,
    audioLevel,
    localSharingScreen,
    isSharingScreen,
    setIsSharingScreen,
  };
}