import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Wizzard from '../../Pages/TroubleShooting/VerificacionDispositivos/Wizzard';

const PruebaTeleconsulta = () => {

  return(
    <div className="fullh">
      <div className="steped-component">
        <div className="header-short">
          <div className="stepped-nav row align-items-center">
            <div className="col-2">
                <Link to="/paciente" className="color-primario">
                  <NavigateBefore fontSize="32px" />
                </Link>
            </div>
            <div className="col-8 text-center">
              <span><b>Prueba de teleconsulta</b></span>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      <Wizzard 
        onFinishContent={
          <div className="text-center">
            <Link to="/paciente" className="btn btn-submit px-4 pt-2">
              Continuar
              <NavigateNext />
            </Link>
          </div>
        }
      />
    </div>
  );
};

export default PruebaTeleconsulta;