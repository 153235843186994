import React, { useState, useEffect } from 'react';
import { useReactPWAInstall } from "react-pwa-install";
import GetAppIcon from '@material-ui/icons/GetApp';
import {pwa} from 'platform-detect';
import myLogo from "./../assets/images/redblu-icon-192.png";
import authentication from '../services/authentication';
import { setNewToken, setLogedPatient, setLogedPsicologo  } from '../services/api';


const InstallPWA = () => {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();
  const [isPwa, setIsPwa] = useState(false);
  const [isPwaText, setIsPwaText] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    pwaInstall({
      title: "Instalación de RedBlu",
      logo: myLogo,
      // TODO: se puede agregar carcteristicas
      // features: (
      //   <ul>
      //     <li>Cool feature 1</li>
      //     <li>Cool feature 2</li>
      //     <li>Even cooler feature</li>
      //     <li>Works offline</li>
      //   </ul>
      // ),
      // TODO: se puede agregar una descripción
       description: "",
    })
      .then(() => {
        setIsPwa(true);
        localStorage.setItem('isPwa', true);
          // window.location.reload();
          authentication
            .logout()
            .then((response) => {
              // console.log(response)
              setNewToken(null);
              setLogedPsicologo(null);
              setLogedPatient(null);
              localStorage.clear();
              window.location.replace("/login"); // history.push('/paciente');

            })
            .catch((error) => {
              setNewToken(null);
              setLogedPsicologo(null);
              setLogedPatient(null);
              localStorage.clear();
              window.location.replace("/login"); // history.push('/paciente');
            });
      })
      .catch(() => {
        localStorage.setItem('isPwa', false);
        setIsPwa(false);
      });
  };
  useEffect(() => {

    if (!isPwa && !pwa && supported() && !isInstalled()) {
      setIsPwaText('pwa');
    } else {
      setIsPwaText('');
    }
  }, [isInstalled, isPwa, isPwaText, supported]);

  return (
    <>
        { supported() && !isInstalled() ? (
          <div className="test-teleconsulta">
            <div className="row align-items-center">
              <div className="col-sm-7 col-12">
                <h3>Instalar Aplicación</h3>
                <p className="mb-2">Mejora tu experiencia y facilita el uso agregando está aplicación a tu equipo.</p>
              </div>
              <div className="col-sm-5 col-12">
                <button type="button" data-tour={isPwaText} className="btn btn-submit pl-3 pr-3 full-width" onClick={(e) => handleClick(e)}>
                  Instalar 
                  <GetAppIcon />
                </button>
              </div>
            </div>
          </div>
      ) : null
    }
      </>
  );
};

export default InstallPWA;
