import React from 'react';

interface Props {
  title: string;
  subtitle?: string;
  customMainClass?: string;
  customTitleClass?: string;
  customSubtitleClass?: string;
}

export const TitleComponent = ({
  title,
  subtitle,
  customMainClass,
  customTitleClass,
  customSubtitleClass,
}: Props) => {
  return (
    <div className={` ${customMainClass ? customMainClass : ''} `}>
      {/* Title */}
      <span className={`${customTitleClass ? customMainClass : 'title-info-profesional '}`}>
        {title}
      </span>
      <span className={`${customSubtitleClass ? customSubtitleClass : 'text-info-profesional '}`}>
          {subtitle}
      </span>
    </div>
  )
}

export default TitleComponent;