import React, { useState, useEffect } from 'react';
import { AsignarFicha } from './AsignarFicha';
import { optionsPeriodicity } from '../../helpers/OptionSelect';
import moment from "moment-timezone";

const fechaActual = moment().hour(0).minute(0).seconds(0);
const fechaFin = fechaActual.clone().hour(23).minute(59).seconds(59);

export const InfoFicha = (props) => {
  const [startDate, setStartDate] = useState(fechaActual.toDate());
  const [endDate, setEndDate] = useState(fechaFin.toDate());

  useEffect(() => {
  }, [props.filterFichas, props.renderSaved])

  return (
    <div>
      {props.button ? 
        <AsignarFicha
          key={props._id}
          {...props}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          optionsPeriodicity={optionsPeriodicity}
          render={props.render}
          rutPaciente={props.rutPaciente}
          rutMedico={props.rutMedico}
          nombreMedico={props.nombreMedico}
        />
      :
        <>
          {
            props.filterFichas.length > 0 ?
              <div className="animated fadeIn border card little-shadow p-3 item-ficha-clinica-detalle">
                <div className="row">
                  <div className="col-md-4">
                    <span className="label">Profesional</span>
                    <h1>{props.nombreMedico}</h1>
                  </div>
                  <div className="col-md-4">
                    <span className="label">RUT</span>
                    <div className="info">{props.rutMedico}</div>
                  </div>
                  <div className="col-md-4">
                    <span className="label">Fecha</span>
                    <div className="info">{props.fecha}</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 descripcion-recurso-ficha">
                    <hr />
                    <h6 className="font-weight-bold">Observaciones: </h6>
                    <pre width="20">{props.texto}</pre>
                  </div>
                </div>
              </div>
              : null
          }
        </>
      }
    </div>
  );
};
