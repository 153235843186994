import URLS from "../URLS"
import { gatewayServiceAuth } from "./gateway";

const ReviewServices = {
  
  createReview : function (idProfesional, review) {
    return gatewayServiceAuth.axios({
      method: 'post',
      url: `${URLS.APIgateWay}/woocommerce/profesional/${idProfesional}/review`,
      data: review,
    })
  },

  getAppointmentReviews : function (idAppointment) {
    return gatewayServiceAuth.axios({
      method: 'get',
      url: `${URLS.APIgateWay}/redblu/review?appointmenWC=${idAppointment}`,
    })
  }

}

export default ReviewServices;