import StarRating from "../../Assignments/StarRating";
import React, { useState } from 'react';
import ReviewServices from "../../../services/reviews";
import Loading from "../../../components/Loading";
import './Review.css';
import { CheckCircle, ErrorOutline } from "@material-ui/icons";

const Review = ({
  profesionalID,
  appointmentID,
  vattentionID,
  user
}) => {

  const [loadState, setLoadState] = useState({
    isSubmiting: false,
    isSendOk: false,
    isSendError: false
  });
  
  const [reviewData, setReviewData] = useState({
    review:'',
    reviewer:`${user.firstname} ${user.lastname}`,
    reviewer_email:user.email,
    rating:null,
    appointmenWC: appointmentID,
    vattentionId: vattentionID
  });
  const [errors, setErrors] = useState({
    rating:false,
    review:false
  })
  
  const handleReviewData = (target, value) => {
    setReviewData({
      ...reviewData,
      [target]: value
    });
    setErrors({
      ...errors,
      [target]:false
    })
  }

  const onSubmitReview = async (event) => {
    setLoadState({
      ...loadState,
      isSubmiting:true
    });

    if (event) {
      event.preventDefault();
    }
    
    if(areFieldsValid()){
      try {
        const response = await ReviewServices.createReview(profesionalID, reviewData);
        if(response && response.status === 200){
          setLoadState({
            isSendOk:true,
            isSubmiting:false,
            isSendError:false
          });
        }
      } catch (error) {
        setLoadState({
          isSendOk:false,
          isSubmiting:false,
          isSendError:true
        });
      }
    }
  };

  const areFieldsValid = () => {
    let ratingError = false;
    let reviewError = false; 

    ratingError = reviewData.rating === null ? true : false;
    reviewError = reviewData.review.trim().length === 0 ? true : false;

    setErrors({
      rating: ratingError,
      review: reviewError
    });

    return (!ratingError && !reviewError) ? true : false;
  };

  return(
    <form onSubmit={onSubmitReview}>
      <div className="col-12">
        <div className="review mt-4">
          
          {loadState.isSubmiting &&
            <ReviewLoading />
          }

          { !loadState.isSubmiting &&
            !loadState.isSendOk &&
            !loadState.isSendError &&
            <div className="row">
              <div className="col-12 text-center mb-4">
                <h1 className="mb-2"><b>Califique</b> su experiencia</h1>
                <p>Tu opinión es importante para mejorar nuestra calidad de servicio</p>
              </div>

              <div className="col-12 mb-4 text-center">
                <h4 className="review-title mb-0">Seleccione su valoración:</h4>
                <StarRating 
                  onSetRating={(rating) => handleReviewData("rating", rating)} 
                  value={reviewData.rating}
                />
                {errors.rating &&
                  <div>
                    <svg className="MuiSvgIcon-root error animated zoomIn fast" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>
                    <span className="error pl-1">Debes presionar las estrellas para valorar</span>
                  </div>
                }
              </div>

              <div className="col-12 mb-3">
                <h4 className="review-title text-center">Describa la atención del profesional:</h4>
                <textarea
                  name="review"
                  onChange={(event) => handleReviewData(event.target.name, event.target.value)}
                  placeholder="El profesional me parecio..."
                  rows={3}
                  className={errors.review ? 'has-error' : ''}
                ></textarea>
                {errors.review &&
                  <div className="pt-2">
                    <svg className="MuiSvgIcon-root error animated zoomIn fast" width={24} height={24} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg>
                    <span className="error pl-1">Debes incluír un comentario en tu valoración.</span>
                  </div>
                  }
              </div>
              <div className="col-12 mb-3">
                <div className="user-note">
                  <p><b>Importante:</b><br/>
                    Esta es una valoración de la <b>calidad de atención del profesional</b> y será visible para otros usuarios al momento de agendar una hora. Además <b>No debes considerar</b> aspectos relacionados al uso de la plataforma.</p>
                </div>
              </div>
              <div className="col-12 text-center">
                <input className="rounded" type="submit" value="Enviar calificación" />
              </div>
            </div>
          }

          {loadState.isSendOk &&
           !loadState.isSubmiting &&
            <MessageSuccess />
          }

          {loadState.isSendError &&
           !loadState.isSubmiting &&
            <MessageError />
          }

        </div>
      </div>
    </form>
  );
};

const ReviewLoading = () => {
  return(
    <div>
      <div className="fullh loading-review"><Loading /></div>
    </div>
  )
}

const MessageError = () => {
  return(
    <div className="text-center px-4">
      <ErrorOutline style={{ fontSize:'40px',color:'#ff6666'}} />
      <h1>Tenemos un problema</h1>
      <p>Tú valoración no ha podido ser enviada, puedes volver a intentar presionando el siguiente botón.</p>
      <button 
        type="button"
        onClick={() => window.location.reload()}
        className="btn btn-submit">Volver a intentar <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z"></path></svg></button>
    </div>
  )
};

const MessageSuccess = () => {
  return(
    <div className="text-center px-4">
      <CheckCircle style={{ fontSize:'40px',color:'#10bf65'}} />
      <h1 className="mt-4">Gracias por tu valoración</h1>
      <p>Es importante para nosotros que puedas calificar siempre que puedas, tu opinión es muy importante y nos ayudará a mejorar nuestra calidad de servicio.</p>
    </div>
  )
};


export default Review;