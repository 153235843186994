import { NavigateNext } from '@material-ui/icons';
import React from 'react';
import './mensajeinicial.css';

const MensajeInicial = ({setStep}) => {

  const onInitTest = () => {
    setStep( step => step + 1 );
  };

  return(
    <div className="row mensaje-inicial">
      <div className="col-sm-2 col-12"></div>
      <div className="col-sm-8 col-12">
        <div className="wizzard-heading text-center">
          <h2 className="step-title mb-4">Prueba de teleconsulta</h2>
          <button
            className="btn btn-submit px-4 rounded mb-4"
            onClick={onInitTest}
          >
            Iniciar Prueba
            <NavigateNext />
          </button>
          <p>Hemos creado esta sección para que puedas corroborar si tu equipo está listo para tener una teleconsulta exitósa.</p>
        </div>
        <div>
          <p><strong>La prueba consiste en:</strong></p>
          <ul>
            <li>Verificar que el dispositivo sea <b>compatible</b> con las carácteristicas de la plataforma.</li>
            <li>Revisión de los <b>permisos y accesos</b> a la cámara y micrófonos.</li>
            <li>Revisar el funcionamiento de la <b>cámara</b>.</li>
            <li>Revisar el funcionamiento del <b>micrófono</b>.</li>
            <li>Revisar el funcionamiento de los <b>parlantes</b>.</li>
          </ul>
        </div>
      </div>
      <div className="col-sm-2 col-12"></div>
    </div>
  );
};

export default MensajeInicial;