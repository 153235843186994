import React, { useState } from "react";
import { Link } from "react-router-dom";
import { formatRut, RutFormat, validateRut } from '@fdograph/rut-utilities';
import subscriptionsServices from '../../services/subscriptions';
import imgLogo from '../../assets/images/redblu.png';

const Unsubscribe = () => {
   const[isRutValid, setIsRutValid]     = useState(false);
   const[isSendOk, setIsSendOk]         = useState(false);
   const[sending,setSending]            = useState(false);
   const[inputValues, setInputValues]   = useState({rut: ''});
   const[showError, setShowError]       = useState(false);
   const[errorMessage, setErrorMessage] = useState('');

   const onSubmit = (event) => {
      if (event) {
         event.preventDefault();
      }
      setShowError(false);
      setSending(true);
      onSubmitRut();
   
      if (isRutValid) {
         subscriptionsServices.unsubscribeEmailNotification({
            rutPaciente: inputValues.rut
         }).then(response => {
            if (response && response.data && response.data.message == 'OK') {
               setIsSendOk(true);
               setInputValues({
                 "rut": ""
               })
             }
         }).catch(error => {
            setShowError(true);
            if(error.response && error.response.data && error.response.data.message == 'RUT_SIN_CUENTA'){
               setErrorMessage('El Rut ingresado no tiene cuenta. ¿Estás seguro de haber ingresado tu rut correctamente?');
            }
            if(error.response && error.response.data && error.response.data.message == 'RUT_INVALIDO'){
               setErrorMessage('No se ha enviado el rut necesario para realizar la operación.');
            }
         });
         
         console.log("%c Rut válido ",'background-color:black;color:white;');
         setSending(false);

      } else {
         setShowError(true);
         console.log("%c Rut inválido ",'background-color:red;color:white;');
      }
      
   };

   const onSubmitRut = () => {
      let formatValue = inputValues.rut;
      const isValid = validateRut(inputValues.rut);
  
      if (isValid) {
        formatValue = formatRut(inputValues.rut, RutFormat.DASH);
      }

      setIsRutValid(isValid);
      setInputValues({
        ...inputValues,
        ['rut']: formatValue
      })
   }

   const handleRutChange = (event) => {
      let inputValue = event.target.value.toLowerCase();
      let formatValue = inputValue;

      const isValid = validateRut(inputValue);

      setIsRutValid(isValid);
      setInputValues({
         ...inputValues,
         [event.target.name]: formatValue
      })
   };

   const handleRutBlur = (event) => {
      let inputValue = event.target.value.toLowerCase();
      let formatValue = inputValue;

      const isValid = validateRut(inputValue);

      if (isValid) {
         formatValue = formatRut(inputValue, RutFormat.DASH);
      }
      setIsRutValid(isValid);
      setInputValues({
         ...inputValues,
         'rut': formatValue
      })
   };

   return (
      <div className="content-container animated fadeIn fullh">
         <div className="middle-center-content pt-3">
            <div className="row pb-2">
               <div className="col-12 text-center">
                  <img 
                     src={imgLogo} 
                     width="30%" 
                     className="img-fluid" 
                     alt="Redblu Logo" 
                  />
               </div>
            </div>
            <div className="heading mt-5 mb-4">
               <h1 className="paged-title">Darse de baja</h1>
               <p>Si deseas dejar de recibir notificaciones ingresa tu rut para darte de baja.</p>
            </div>
            <form onSubmit={onSubmit}>
               <div className="bodycontent">
                  <div className="row mb-5">
                     <div className="col-12">
                        <label>Ingresa tu RUT</label>
                        <input
                           name="rut"
                           type="text"
                           className={isRutValid ? "" : "is-invalid"}
                           onChange={handleRutChange}
                           onBlur={handleRutBlur}
                           required
                           placeholder="12345678-5"
                           value={inputValues.rut}
                        />
                        <div className="invalid-feedback">Rut invalido.</div>
                     </div>
                     
                     {isSendOk &&
                        <div className="col-12 mb-3 mt-3">
                           <div className="alert-message alert-success">
                              Te has dado de baja correctamente.
                           </div>
                        </div>
                     }

                     {showError && errorMessage &&
                        <div className="col-12 mb-3 mt-3">
                           <div className="alert-message">
                              {errorMessage}
                           </div>
                        </div>
                     }

                  </div>
               </div>
               <div className="actioncontent">
                  <div className="row">
                     <div className="col-6 text-left create-account">
                        <Link to="/login" className="pt-2">Volver</Link>
                     </div>
                     <div className="col-6 text-right">
                        <input type="submit" value={"ENVIAR"} disabled={sending} />
                     </div>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
}

export default Unsubscribe;