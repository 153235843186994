const RoutesDefinition = {

  PublicRoutes : [
    '/login',
    '/registro',
    '/registro-completado',
    '/dar-de-baja',
    '/restablecer-contrasena',
    '/olvido-contrasena',
    '/solucion'
  ],

  isAccesingPublicRoutes : () => isAccesingPublicRoutes,
  isAccesingPrivateRoutes : () => !isAccesingPublicRoutes

}

const isAccesingPublicRoutes = (routeToCheck, routes) => {

  let hasCoincidence = false;

  for (let index = 0; index < routes.length; index++) {
    const route = routes[index];
    if(route === routeToCheck){
      hasCoincidence = true;
      break;
    }
  }

  let multipleSubRutes = false;
  if(routeToCheck.includes("/solucion")){
    multipleSubRutes = true;
  }
  
  return (hasCoincidence || multipleSubRutes ) ? true : false;
}

export default RoutesDefinition;