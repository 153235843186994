import API from "./api";
import URLS from "../URLS";

const medicsService = {

  getMedicsAll: function () {
    return API.axios({
      method: "get",
      url: URLS.medics + "/all",
    });
  }
}

export default medicsService;
