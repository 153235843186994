import React, { useState, useEffect } from "react";
import { TablePagination } from 'react-pagination-table';
// import moment from "moment-timezone";
//import '../../assets/plugins/TablePagination.css';
import PropTypes from 'prop-types';

export const ResumenDiarioRecursos = ({
  notificationsWeek, reminderId, renderSaved, updated

}) => {
  // TODO: verificar diferencia de componente con teleconsulta 21-09-21
  const [notificacions, setNotifications] = useState([]);

  // Configuración de Tabla
  const defaultHeader = ["Nombre de Actividad", "Fecha de Asignación", "Fecha de Término", "Instrucción", "Acción"];
  const ItemPorPagina = 4;

  useEffect(() => {

    setNotifications(notificationsWeek);
  
  }, [notificationsWeek, renderSaved, updated]);
  const App = ({ Header, data }) =>
    <div>
      {/* Renderizado de tabla */}

      <div id="tableResumen" className="p-0" >
        <TablePagination
          className="table table-striped"
          title=""
          subTitle="Resumen Diario de Recursos"
          headers={Header}
          data={data}
          columns="activityName.assignmentDate.endOfPeriod.pacientInstructions.actions"
          perPageItemCount={ItemPorPagina}
          partialPageCount={data.length}
          totalCount={data.length}
          arrayOption={[["size", 'all', ', ']]}
          nextPageText="Siguiente"
          prePageText="Anterior"

        />
      </div>


    </div>;
  App.propTypes = {
    Header: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  };
  return (

    <>
      <App Header={defaultHeader} data={notificationsWeek} />

    </>
  )

};
