import { useState } from "react";

export default function useAlertMessage() {
  const [alerta, setAlerta] = useState({});

  return {
    alerta,
    setAlerta,
  };
}
