import React, { useEffect, useState, useRef } from "react";

const CaptivateActividad = ({ url }) => {


  useEffect(() => {

  }, [url]);

  return (
    <div className="actividadFondo">
      <div className="col">
      <h3 className="p-2">Recurso Captivate</h3>

      </div>
          <iframe
            src={url}
            width="100%"
            height="480"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            title={"recurso-captivate"}
          ></iframe>
         
    </div>
  );
};

export default CaptivateActividad;
