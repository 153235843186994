import ErrorItem from "./ErrorItem";

const ErrorList = ({errors}) => {

  if(errors && errors.length === 0){
    return <></>
  }

  return(
    <div className="wizzard-errors">
    <h2>Hemos encontrado <b>problemas</b></h2>

    <div className="wizzard-error py-0">
      {errors.map((error, index) => {
        return <ErrorItem errorCode={error} key={index} index={index} />
      })}
    </div>
  </div>
  )
};

export default ErrorList;