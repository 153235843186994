import React from 'react';
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom';


export const PublicRoutes = ({

    isAuthenticated,
    component: Component,
    user,
    ...rest
    
}) => {



    const handleRedirection = () => {
      if(!isAuthenticated){
        window.location.pathname = '/login';
      } else {
        if(user.role === 'PATIENT'){
          window.location.pathname = '/paciente';
        }

        if(user.role === 'PROFESIONAL'){
          window.location.pathname = '/profesional';
        }
      }
    };


    return (
        <Route
            {...rest}
            component={(props) => (
                (!isAuthenticated)
                    ? (<Component {...props} />) // si no está autenticado
                    : (handleRedirection()) // si está autenticado lo enviamos aL INICIO
            )}
        />
    )
}

PublicRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}

