import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { useHistory } from "react-router-dom";
import { formatRut, RutFormat, validateRut } from '@fdograph/rut-utilities';
import patiensService from '../../services/patients';
import authentication from '../../services/authentication';
import ReactGA from 'react-ga';

const customModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const NewAccount = ({ setStep }) => {
  
  const history = useHistory();
  const [isRutValid, setIsRutValid] = useState(true);
  const [guardando, setGuardando] = useState(false);
  const [alertModalIsOpen, setAlertModalIsOpen] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");
  const [patientState, setPatientState] = useState("");
  const [inputValues, setInputValues] = useState({
    nombre: "",
    apellido:"",
    rut: "",
    email: "",
    telefono:""
  });
  

useEffect(() => {
  if (!history.location.state) {
    setPatientState(false);
  } else {
    setPatientState(true);
    inputValues.nombre = history.location.state.nombre;
    inputValues.apellido = history.location.state.apellido;
    inputValues.rut = history.location.state.rut;
    inputValues.telefono = history.location.state.telefono;
    inputValues.email = history.location.state.email;
  }
}, [])

console.log(patientState)
  const handleInputChange = (event) => {

    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value
    })
    ReactGA.event({category:"Registro", action:"handleInputChange",label:event.target.name, value:event.target.value});
  }

  const handleRutChange = (event) => {
    let inputValue = event.target.value.toLowerCase();
    let formatValue = inputValue;
    const isValid = validateRut(inputValue);
    
    ReactGA.event({category:"Registro", action:"handleRutChange",label:event.target.name, value:formatValue});

    if (isValid) {
     formatValue = formatRut(inputValue, RutFormat.DASH);
    }

    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      [event.target.name]: formatValue
    })

  }

  const handleChangeInputText= e => {
    const { name, value } = e.target;
    let regex = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*$");

    if (regex.test(value)) {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.value
      })    
    } else {
      setAlertModalText("Ingresar solo letras.");
      setAlertModalIsOpen(true);
    }
  }

  const handlePhoneNumber = e => {
    const { name, value } = e.target;
    let regex = new RegExp("^[0-9]{0,9}$");

    if (regex.test(value)) {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.value
      })    
    }
  };

  const onSubmitRut = () => {
    let formatValue = inputValues.rut;
    const isValid = validateRut(inputValues.rut);

    if (isValid) {
      formatValue = formatRut(inputValues.rut, RutFormat.DASH);
    }
    setIsRutValid(isValid);
    setInputValues({
      ...inputValues,
      'rut': formatValue
    })
  }

  const onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    ReactGA.event({category:"Registro", action:"OnSubmit"});
    onSubmitRut();

    if (!isRutValid) {
      setAlertModalText("Rut inválido.");
      setAlertModalIsOpen(true);
      return;
    }

    if (inputValues.email != inputValues.emailConfirm) {
      setAlertModalText("Los emails no coinciden.");
      setAlertModalIsOpen(true);
      return;
    }

    if (inputValues.password1.length < 5) {
      setAlertModalText("La contraseña debe tener al menos 5 caracteres.");
      setAlertModalIsOpen(true);
      return;
    }

    if (inputValues.password1 != inputValues.password2) {
      setAlertModalText("Las contraseñas no coinciden.");
      setAlertModalIsOpen(true);
      return;
    }

    setGuardando(true);
    patiensService.post({
      firstname: inputValues.nombre,
      lastname: inputValues.apellido,
      rut: inputValues.rut,
      phone: inputValues.telefono,
      email: inputValues.email,
      password: inputValues.password1
    }).then(response => {

      if (response.data && response.data.patient) {
        sendWelcomeEmail(inputValues.rut);
        autoLogin(inputValues.rut, inputValues.password1)
        ReactGA.event({category:"Registro", action:"AutoLogin"});
        
      } else {
        setAlertModalText(response.data.message);
        setAlertModalIsOpen(true);
      }

      setGuardando(false);

    }).catch(error => {
      setAlertModalText(error.toString());
      setAlertModalIsOpen(true);
      setGuardando(false);
    })
  }

  const autoLogin = (user, pass) => {

    authentication.login(
      {
        "username": user,
        "password": pass
      }
    ).then(response => {
      setTimeout(() => {
        window.location.reload();
      }, 100)
      history.push("/registro-completado");
    })

  }

  const sendWelcomeEmail = (patientRut)=>{
    authentication.restorePasswordEmail(patientRut, "2");
  }

  return (
    <div className="content-container fullh">
      <div className="middle-center-content">
        <div className="heading">
          <h1 className="paged-title">Creación de cuenta</h1>
          <p>¡Completa estos sencillos campos y estarás listo para utilizar la aplicación!</p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="bodycontent">
            <div className="accountform">

              <div className="row">
                <div className="col-6 mb-3">
                  <label>Nombre</label>
                  <input name="nombre" onChange={handleChangeInputText} required type="text" value={inputValues.nombre}/>
                </div>
                <div className="col-6 mb-3">
                  <label>Apellido</label>
                  < input name="apellido" onChange={handleChangeInputText} required type="text" value={inputValues.apellido}/>
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="rut">Rut</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={isRutValid ? "" : "is-invalid"}
                      id="rut"
                      name="rut"
                      required
                      onChange={handleInputChange}
                      onBlur={handleRutChange}
                      placeholder="11120411-k"
                      value={inputValues.rut}
                    />
                    <div className="invalid-feedback">
                      Rut inválido.
                            </div>
                  </div>

                </div>
                <div className="col-6 mb-3">
                  <label>Teléfono <small>(ej: 9 dígitos)</small></label>
                  <input name="telefono" onChange={handlePhoneNumber} required type="tel" value={inputValues.telefono}/>
                </div>

                <div className="col-12 mb-3">
                  <label>Email</label>
                  <input name="email" onChange={handleInputChange} required type="email" placeholder="tucorreo@gmail.com" value={inputValues.email} />
                </div>
                <div className="col-12 mb-3">
                  <label>Repetir email</label>
                  <input name="emailConfirm" onChange={handleInputChange} required type="email" placeholder="" />
                </div>

                <div className="col-12 mb-3">
                  <label>Nueva contraseña</label>
                  <input name="password1" onChange={handleInputChange} required type="password" />
                </div>
                <div className="col-12 mb-5">
                  <label>Repetir contraseña</label>
                  <input name="password2" onChange={handleInputChange} required type="password" />
                </div>
              </div>
            </div>
          </div>
          <div className="actioncontent">
            <div className="row">
              <div className="col-12 text-right">
                <input type="submit" value={guardando ? "GUARDANDO" : "CONTINUAR"} disabled={guardando} />
              </div>
            </div>
          </div>
        </form>
      </div>
      <Modal isOpen={alertModalIsOpen} style={customModalStyles} onRequestClose={() => setAlertModalIsOpen(false)}>
        <div className="alertModal">
          <h1>{alertModalText}</h1>
          <div className="text-center">
            <button type="button" className="btn btn-submit btn-lg" onClick={() => setAlertModalIsOpen(false)}>Aceptar</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NewAccount;