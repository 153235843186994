
export const getStringFromHtml = (value: any) => {
  
    const data = value.replace(/<[^>]*>?/gm, "");

    const newData = data.replace('&nbsp;', '');

    return newData;

}




export default getStringFromHtml;
