/* global Modernizr */
import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customModalStyles = {
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
   },
};

const DetectGetUserMediaFeature = ({ activo, setAccesoACamara }) => {
   const [alertModalIsOpen, setAlertModalIsOpen] = useState(activo);

   const handlerModalCloseBtn = () => {
      setAlertModalIsOpen(false);
      setAccesoACamara(true);
   };

   return (
      <Modal
         isOpen={alertModalIsOpen}
         style={customModalStyles}
         onRequestClose={() => setAlertModalIsOpen(false)}
      >
         <div className="UASnifferAlertModal">
            <h1>Su navegador no tiene acceso al micrófo/cámara</h1>
            <div>
               Considere utilizar los siguientes navegadores:
               <ul>
                  <li>IPhone/Mac: Safari.</li>
                  <li>Windows/Android: Google Chrome.</li>
                  <li>Ubuntu: Mozilla Firefox.</li>
               </ul>
            </div>
            <div className="text-center">
               <button
                  type="button"
                  className="btn btn-submit btn-lg"
                  onClick={handlerModalCloseBtn}
               >
                  Aceptar
               </button>
            </div>
         </div>
      </Modal>
   );
};

export default DetectGetUserMediaFeature;
