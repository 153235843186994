
import moment from "moment-timezone";

export const obtieneDias = (
    diaInicial,
    diaFinal,
    arrayDias,
    arrayDaysAux,
    arrayDaysLabel,
    daysLabels,
    arrayCompletadas,
    arrayNoCompletadas,
    arrayTotales,
    totalRealizadas,
    totalPorRealizar,
    total,
    diffDays,
) => {

    const currentMonthDates = new Array(moment().daysInMonth())
        .fill()
        .map((x, i) =>
            moment(diaInicial).startOf().add(i, 'days')
        );
    // inserto fechas

    arrayDaysLabel.push(...currentMonthDates);

    arrayDaysLabel.map(( labelDay , i) => {
        // console.log(diffDays)
        // Cantidad de días que deseo mostrar 
        if (i <= diffDays ) {
            arrayDaysAux.push(moment(labelDay).hour(0).minute(0).second(0).format("DD-MM-YYYY"));
            daysLabels.push(moment(labelDay).format("MMM D"))
        }
    });
 
    arrayDaysAux.forEach((day, indexAux) => {

        let totalNoCompletadas = 0;
        let totalCompletadas = 0;
        let totales = 0;
        let fechaArr = "";
        let dayFormat = moment(day);
     
        arrayDias.forEach((data, index) => {
            // console.log(data)
            fechaArr = moment(data.dateTime).format('DD-MM-YYYY');

            
            if (fechaArr === day && data.completed) {
                totalCompletadas++;
                totales++;
            } else if (fechaArr === day && !data.completed) {
                totalNoCompletadas++;
                totales++;
            } else {

                totalNoCompletadas=0;

            }
           
        });

    
        // console.log(arrayDias.length)
        arrayCompletadas.push(totalCompletadas);
        arrayNoCompletadas.push(totalNoCompletadas);
        arrayTotales.push(totales);
    });


    // // guardo resultados en arreglos
    // Guardo información de totales de actividades
    // totalRealizadas = arrayCompletadas.reduce((a, b) => a + b, 0);
    // totalPorRealizar = arrayNoCompletadas.reduce((a, b) => a + b, 0);
    // total = arrayTotales.reduce((a, b) => a + b, 0);

}