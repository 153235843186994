import React, { useState, useEffect, useContext } from "react";
import patiensService from './../../services/patients';
import {getLogedPatient} from "./../../services/api";
//Contenidos de ayuda:
import PermisoBloqueado from './PermisoBloqueado';
import PermisoSinAcceso from './PermisoSinAcceso';
import PermisoSinLectura from './PermisoSinLectura';
import SolicitaPermiso from './SolicitaPermiso';
import { UserContext } from "../../context/UserContext";

const AudioVideoTest = ({handlePermisos}) => {
  const { user } = useContext(UserContext);
  const [audioStream, setAudioStream] = useState(false);
  const [videoStream, setVideoStream] = useState(false);
  const [errorCode, setErrorCode] = useState("solicitaPermiso");

   useEffect(() => {
      return () => {
         if (audioStream) {
            audioStream.getTracks().forEach(function (track) {
               track.stop();
            });
         }
      };
   }, [audioStream]);

   useEffect(() => {
      return () => {
         if (videoStream) {
            videoStream.getTracks().forEach(function (track) {
               track.stop();
            });
         }
      };
   }, [videoStream]);


   useEffect(() => {

      // TypeError: undefined is not an object (evaluating 'navigator.permissions.query')
      // File "teleconsulta/verificacionPermisos/index.jsx", line 39, in AudioVideoTest
      
      // TypeError: Cannot read property 'query' of undefined
      // File "teleconsulta/verificacionPermisos/index.jsx", line 39, in AudioVideoTest

      if( !navigator.permissions || !navigator.permissions.query ){
         return;
      }

      Promise.all([
         navigator.permissions.query({ name: 'microphone' }),
         navigator.permissions.query({ name: 'camera' })
      ]).then(([mic, cam]) => {
         console.log('PermissionStatus :>> ', {mic, cam});
         if( mic.state === "granted" && cam.state === "granted" ){
            handlePermisos();
         }
      }).catch( (err) => {
         console.log('navigator.permissions.query :>> ', err);
      })

   }, []);

   // unión entre runMicTest y runVideoTest
   const runMicVideoTest = () => {
      patiensService.setLogActivityUser(user.rut, 'Está configurando sus dispositivos (cámara/micrófono)');

      // sección comun entre runMicTest y runVideoTest
      if (navigator.mediaDevices === undefined) {
         navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
         navigator.mediaDevices.getUserMedia = function (constraints) {
            // First get ahold of the legacy getUserMedia, if present
            var getUserMedia =
               navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            // Some browsers just don't implement it - return a rejected promise with an error
            // to keep a consistent interface
            if (!getUserMedia) {
               patiensService.setLogActivityUser(user.rut, 'El dispositivo del paciente no es compatible');

               return Promise.reject(
                  new Error("getUserMedia is not implemented in this browser")
               );
            }
            return getUserMedia(constraints);
         };
      }

      navigator.mediaDevices
         .getUserMedia({ audio: true, video: true })
         .then(function (audioStream, videoStream) {
            setVideoStream(videoStream);
            handlePermisos();
         })
         .catch(function (error) {
            if (error.name === "NotAllowedError") {
               //Usuario deniega acceso:
               patiensService.setLogActivityUser(user.rut, 'El paciente tiene bloqueado el permiso de cámara/mic');
               setErrorCode('permisoBloqueado');
            } else if (error.name === "NotReadableError") {
               //No se puedee leer el hardware de mic/cam
               patiensService.setLogActivityUser(user.rut, 'No se puede utilizar la cam/mic del paciente.');
               setErrorCode('errordeLectura');
            } else {
               //No se puede acceder a cámara o mic.
               setErrorCode('sinAccceso');
            }
         });
   };

   return (
      <div className="default-test p-2">
         {errorCode === "solicitaPermiso" ? (
            <SolicitaPermiso runMicVideoTest={runMicVideoTest} />
         ): null }

         {errorCode === "permisoBloqueado" ? (
            <PermisoBloqueado />
         ):null}

         {errorCode === "sinAccceso" ? (
            <PermisoSinAcceso />
         ):null}

         {errorCode === "errordeLectura" ? (
            <PermisoSinLectura />
         ):null}

      </div>
   );
};

export default AudioVideoTest;
